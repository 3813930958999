import axios from "axios";

// export const baseURL = "http://localhost:3300";
// export const baseURLSocket = "http://localhost:4004";
// export const baseURLWhatsApp = "http://localhost:3232";

export const baseURL = process.env.REACT_APP_BACKEND_URL;
export const baseURLERP = process.env.REACT_APP_ERP_URL;
export const baseURLSocket = process.env.REACT_APP_SOCKET_URL;
export const baseURLWhatsApp = process.env.REACT_APP_WHATSAPP_URL;
export const baseURLTemporaryWebhook = process.env.REACT_APP_TEMPORARY_WEBHOOK_URL;
export const integrationKeyTemporaryWebhook = "u64lCdzlA8U45PmdiWi3D8eLrAbA1j5hDeVRyskB4xHtiKlRKysEfA6wwb6KMu67U3aFaTfKcxNNW5EzOFiwvPAMAD2geIAe9EIGQBrRB96JuZ93TF7r3UxPDdLGuA8BxfVRom4DF5uX8SijndKupugAWjj9ebs5gHLQhfiXoJZLvu29UO9bJjs26TZTZzPWZCk9WpCK66AM09oeoDyUf665AB5KjwzzOo4QNti2QKMfJK4cxGrdjc2FIPkAqywU";

export const apiERP = axios.create({
    baseURL: baseURLERP,
});

const api = axios.create({
    baseURL,
});
const apiWhatsApp = axios.create({ baseURL: baseURLWhatsApp });

apiWhatsApp.defaults.headers.common['Authorization'] = "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg=";
apiWhatsApp.defaults.headers.common['Content-Type'] = 'application/json';

export const setTokenUser = token =>
{
    api.defaults.headers.common['Authorization'] = "Bearer " + token;
    api.defaults.headers.common['Content-Type'] = 'application/json';
}

api.interceptors.response.use(
    (response) => 
    {
        return response;
    },
    async function (error) 
    {
        if (error.response.status === 401) 
        {
            const token = await refreshToken();
            const user = JSON.parse(sessionStorage.getItem("USER_CRM_SEMPNET"));
            sessionStorage.setItem("USER_CRM_SEMPNET", JSON.stringify({
                ...user,
                token: token
            }));

            setTokenUser(token);
            error.config.headers.Authorization = `Bearer ${token}`;
            return api(error.config);

        } 
        else 
        {
            return Promise.reject(error);
        }
    }
);

async function refreshToken() 
{
    return new Promise((resolve, reject) => {
        try {
            const { PER_ID } = JSON.parse(sessionStorage.getItem("USER_CRM_SEMPNET"));
            const parameters = {
                method: "POST"
            };
            const body = { PER_ID };
            axios
                .post(
                    baseURL + "/user/refresh_token",
                    body,
                    parameters
                )
                .then(async (res) => {
                    return resolve(res.data.token.TOKEN);
                })
                .catch((err) => {
                    return reject(err);
                });
        } catch (err) {
            return reject(err);
        }
    });
};

export default api;
export { apiWhatsApp }