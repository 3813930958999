import React, { Component, Fragment } from "react";
import "./DetailsEnvelope.css"

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import 
{ 
    formatedDateTime,
    formatedOnlyNumber,
    formatedPhoneAndCellphoneWithSpace,
} from "../../auxiliary/generalFunctions";

import Timer from "../general/Timer";
import Check from "../general/Check";
import Button from "../general/Button";
import InputGeneral from "../general/InputGeneral";
import SelectMulti from "../general/SelectMulti";
import MessageAttention from "../general/MessageAttention";
import MessageConfirmation from "../general/MessageConfirmation";
import Accordion from "react-bootstrap/Accordion";

import ServiceController from "../../controllers/ServiceController";
import BusinessController from "../../controllers/BusinessController";

import { setModalMessage } from "../../store/actions/general";

import axios from "axios";

const initialState = {
    preLoading: false,
    userId: null,
    clientName: "",
    creatorName: "",
    guarantorName: "",
    procuratorName: "",
    serviceId: null,
    clientId: null,
    officeId: null,
    procuratorId: null,
    guarantorId: null,
    hasEnvelope: false,
    hasInstallments: false,
    checkGuarantor: false,
    checkProcurator: false,

    timer: 0,
    
    bondsType: 1,
    envelopesData: [],
    envelopesAmount: 0,
    businessClientId: null,
    attachmentsEnvelopesData: [],
    allowContainerEnvelope: false,
    allowContainerEnvelopeMessage: <></>,

    clientCellPhone1: "",
    procuratorCellPhone1: "",
    guarantorCellPhone1: "",

    /* START STATES OF MODALS */
    message_envelope_confirmation: "",
    message_envelope_type_functions: "",
    bodyModalConfirmationOfEnvelope: <></>,
    message_envelope_show_confirmation: false,
    message_envelope_category_confirmation: null,
    message_envelope_type_confirmation: "warning",

    messageAttentionText: "",
    messageAttentionType: "",
    messageAttentionTitle: "",
    messageAttentionShow: false,

    message_show_confirmation: false,
    message_type_confirmation: "",
    message_confirmation: "Insira o código de validação",
    /* END STATES OF MODALS */

    optionsExtraService: [],
    choosenExtraService:  [],
    isDisabledButtonAddServices: false,
    showAttachmentsDetailsEnvelope1: false,
    showAttachmentsDetailsEnvelope2: false,
    classHandleToggleContainerAttachments: "",

    signCompletedCategory1: false,
    signCompletedCategory2: false,
    
    maxAddServices1: false,
    maxAddServices2: false,
    
    signaturesEnvelopesData: [],
    releaseSignToCreator1: false,
    releaseSignToCreator2: false,
    showSignaturesDetailsEnvelope1: false,
    showSignaturesDetailsEnvelope2: false,
    classHandleToggleContainerSignatures: "",

    clientHasBeenSigned1: false,
    clientHasBeenSigned2: false,

    witnesses: [],
    tableSignatures: null,
    availableWitnesses: [],
    witnessNumberOne: null,
    witnessNumberTwo: null,
    hasEnvelopeContract: false,
    onClickValidationContactOnWitness: false,

    disableWitness: false,
    disableCreateEnvelope: false,
    messageDisableCreateEnvelope: "",

    validatedContactClientIcon: "fa fa-exclamation-triangle",
    validatedContactGuarantorIcon: "fa fa-exclamation-triangle",
    validatedContactProcuratorIcon: "fa fa-exclamation-triangle",

    validatedContactClient: false,
    validatedContactClientContactRegional: false,
    validatedContactGuarantor: false,
    validatedContactGuarantorContactRegional: false,
    validatedContactProcurator: false,
    validatedContactProcuratorContactRegional: false,

    phoneId: null,
    showValidationCodeField: false,
    isDisabledButton: false,
    showValidationButton: true,
    statusValidationCode: 1,
    validationCodeField: "",
    validationCode: "",

    notice: [],
    buttons: [],
    allowContainerRelationships: {},
    categorySelected: false,
    thisClientHavePendencies: false,
    continueWithHavePendencies: false,

    loading: false
}

const REACT_APP_ERP_API_URL = process.env.REACT_APP_ERP_API_URL;

const serviceController = new ServiceController();
const businessController = new BusinessController();

class DetailsEnvelope extends Component {
    state = { ...initialState }

    componentDidMount = async () => 
    {
        this.setState({ preLoading: true });
        
        await this.setState({ 
            serviceId: this.props.data.serviceId,
            userId: this.props.data.userId,
            clientName: this.props.data.clientName,
            creatorName: this.props.data.creatorName,
            guarantorName: this.props.data.guarantorName,
            procuratorName: this.props.data.procuratorName,
            officeId: this.props.data.officeId,
            businessClientId: this.props.data.businessClientId,
            clientId: this.props.data.clientId,
            witnessNumberOne: this.props.data.witnessNumberOne,
            witnessNumberTwo: this.props.data.witnessNumberTwo,
            procuratorId: this.props.data.procuratorId,
            // hasInstallments: this.props.data.hasInstallments,
            checkGuarantor: this.props.data.checkGuarantor,
            checkProcurator: this.props.data.checkProcurator,
            clientCellPhone1: this.props.data.clientCellPhone1,
            procuratorCellPhone1: this.props.data.procuratorCellPhone1,
            guarantorCellPhone1: this.props.data.guarantorCellPhone1,
            guarantorId: this.props.data.guarantorId,
            buttons: [{
                category: null,
                buttonCreateEnvelope: false,
                buttonSendSign: false,
                buttonTimer: false,
                buttonDeleteEnvelope: false,
                buttonSignaturesEnvelope: false,
                buttonAttachmentsEnvelope: false 
            }]
        });
        
        if (this.state.serviceId === null) { return; }

        await this.hasInstallments();
        await this.queryEnvelopesService();

        this.setState({ callFunctionsDidMount: true });

        await this.validationAnotherServiceWithOpenEnvelope();
        this.setState({ bondsType: 1 });
        await this.queryPhoneWhatsapp();

        if (this.state.checkProcurator)
        {
            this.setState({ bondsType: 2 });
            await this.queryPhoneWhatsapp();
            this.setState({ bondsType: null });
        }

        if (this.state.checkGuarantor)
        {
            this.setState({ bondsType: 3 });
            await this.queryPhoneWhatsapp();
            this.setState({ bondsType: null });
        }

        await this.getService();
        await this.getWitnesses();
        await this.attachmentsEnvelope(1);
        await this.handleToggleContainerAttachments(1);
        await this.attachmentsEnvelope(2);
        await this.handleToggleContainerAttachments(2);

        await this.signaturesEnvelope(1);
        await this.signaturesEnvelope(2);

        await this.validation();

        this.setState({ preLoading: false, callFunctionsDidMount: false });
    };

    componentDidUpdate = async (prevProps) =>
    {
        if (prevProps.data.serviceId !== this.props.data.serviceId)
        {
            await this.setState({ 
                serviceId: this.props.data.serviceId,
                userId: this.props.data.userId,
                clientName: this.props.data.clientName,
                creatorName: this.props.data.creatorName,
                guarantorName: this.props.data.guarantorName,
                procuratorName: this.props.data.procuratorName,
                officeId: this.props.data.officeId,
                businessClientId: this.props.data.businessClientId,
                clientId: this.props.data.clientId,
                witnessNumberOne: this.props.data.witnessNumberOne,
                witnessNumberTwo: this.props.data.witnessNumberTwo,
                procuratorId: this.props.data.procuratorId,
                // hasInstallments: this.props.data.hasInstallments,
                checkGuarantor: this.props.data.checkGuarantor,
                checkProcurator: this.props.data.checkProcurator,
                clientCellPhone1: this.props.data.clientCellPhone1,
                procuratorCellPhone1: this.props.data.procuratorCellPhone1,
                guarantorCellPhone1: this.props.data.guarantorCellPhone1,
                guarantorId: this.props.data.guarantorId,
            });
            
            if (this.state.serviceId === null) { return; }
            await this.hasInstallments();
            await this.queryEnvelopesService();
            await this.validationAnotherServiceWithOpenEnvelope();
            this.setState({ bondsType: 1 });
            await this.queryPhoneWhatsapp();

            if (this.state.checkProcurator)
            {
                this.setState({ bondsType: 2 });
                await this.queryPhoneWhatsapp();
                this.setState({ bondsType: null });
            }
    
            if (this.state.checkGuarantor)
            {
                this.setState({ bondsType: 3 });
                await this.queryPhoneWhatsapp();
                this.setState({ bondsType: null });
            }

            await this.getService();
            await this.getWitnesses();
            await this.attachmentsEnvelope(1);
            await this.attachmentsEnvelope(2);
        }

        if (this.props.data.checkGuarantor !== this.state.checkGuarantor || this.props.data.checkProcurator !== this.state.checkProcurator)
        {
            await this.setState({ 
                guarantorId: this.props.data.guarantorId,
                procuratorId: this.props.data.procuratorId,
                guarantorName: this.props.data.guarantorName,
                procuratorName: this.props.data.procuratorName,
                checkGuarantor: this.props.data.checkGuarantor,
                checkProcurator: this.props.data.checkProcurator,
                guarantorCellPhone1: this.props.data.guarantorCellPhone1,
                procuratorCellPhone1: this.props.data.procuratorCellPhone1,
            });
            await this.createTableSignatures();
        }
    }

    /* Faz uma requisição POST para consultar envelopes de serviço via webhook. */
    queryEnvelopesService = async () =>
    {
        if (this.state.callFunctionsDidMount) { return; }
        if (this.state.serviceId === null) { return; }
        const url = `${REACT_APP_ERP_API_URL}/ajax/consultarEnvelopesServicoWebhook.php`;

        const config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg="
            },
            data: JSON.stringify({ 
                serviceId: this.state.serviceId,
                userId: this.state.userId,
            })
        };

        try 
        {
            const response = await axios.request(config);
            await this.setState({ envelopesData: response.data.dados.categorias, timer: parseInt(response.data.temporizador) });
            await this.validation();
        } 
        catch (error)
        {
            console.error(
                "Erro ao consultar envelopes:",
                error.response?.data || error.message
            );
            throw error;
        }
    };

    /* Faz uma requisição POST para consultar envelopes de serviço via webhook. */
    validationAnotherServiceWithOpenEnvelope = async () =>
    {
        if (this.state.clientId === null) { return; }
        const url = `${REACT_APP_ERP_API_URL}/ajax/envelopeAbertoCliente.php`;

        const config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg="
            },
            data: JSON.stringify({ 
                clientId: this.state.clientId,
                categoryId: 1,
            })
        };

        try 
        {
            const response = await axios.request(config);
            if (response.data.status === "200")
            {
                let count = 0
                this.state.envelopesData.forEach((envelope) => {
                    if (envelope.temEnvelope)
                    {
                        count++
                    }
                })

                await this.setState({ hasAnotherServiceWithOpenEnvelope: (parseInt(response.data.envelopeServiceId) !== this.state.serviceId && count === 0 && response.data.envelopeServiceId !== null && response.data.envelopeServiceId !== "") ? true : false });
                await this.setState({ idAnotherServiceWithOpenEnvelope: this.state.hasAnotherServiceWithOpenEnvelope ? response.data.envelopeServiceId : null });
            }
        } 
        catch (error)
        {
            console.error(
                "Erro ao consultar envelopes:",
                error.response?.data || error.message
            );
            throw error;
        }
    };

    /* Função para criar envelope */
    createEnvelope =  async (category) => 
    {
        await this.queryEnvelopesService();

        try
        {
            const data = {
                type: 1,
                envelopeServiceId: 0,
                annexOnly: 1,
                category: category,
                userId: this.state.userId,
                serviceId: this.state.serviceId
            }
            
            const result = await serviceController.createEnvelope(data);
            if (!result.status) { return; }
            if (result.data.status === "400")
            {
                return this.message("error", result.data.message);
            }
    
            await this.queryEnvelopesService();
            await this.attachmentsEnvelope(category);
            await this.handleToggleContainerAttachments(category);
        }
        catch (error)
        {
            console.error(
                "Erro ao criar envelope: ",
                error.response?.data || error.message
            );
            throw error;
        }
    }

    /* Função do modal de confirmação para deletar envelope */
    confirmationDeleteEnvelope = async (category) =>
    {
        if (!this.state.hasEnvelope) { return; }
        const body =
            <div className="d-flex flex-column mb-3">
                <span><strong>Serviço:</strong> {this.state.serviceId }</span>
                <span><strong>Cliente:</strong> {this.state.clientName }</span>
            </div>
        ;

        this.setState({
            message_envelope_category_confirmation: category,
            message_envelope_confirmation: `Tem certeza que quer excluir o envelope abaixo?`,
            message_envelope_show_confirmation: true,
            message_envelope_type_functions: "delete",
            bodyModalConfirmationOfEnvelope: body
        });
    }

    resetSomeThings = async () =>
    {
        await this.setState({
            showAttachmentsDetailsEnvelope1: false,
            showAttachmentsDetailsEnvelope2: false,
            showSignaturesDetailsEnvelope1: false,
            showSignaturesDetailsEnvelope2: false,
            notice: []
        })
    }

    /* Função para deletar envelope */
    deleteEnvelope = async (category) => 
    {
        await this.queryEnvelopesService();

        try
        {
            if (!this.state.hasEnvelope) { return; }
            const data = {
                token: this.state.envelopesData[category - 1].token
            }
            
            const result = await serviceController.deleteEnvelope(data);
            if (!result.status) { return; }
            this.setState({ message_envelope_show_confirmation: false, message_envelope_type_functions: "", hasEnvelopeContract: false, bodyModalConfirmationOfEnvelope: <></>});
            await this.resetSomeThings();
            await this.queryEnvelopesService();
        }
        catch (error)
        {
            console.error(
                "Erro ao excluir envelope: ",
                error.response?.data || error.message
            );
            throw error;
        }

    }

    hasInstallments = async () => 
    {
        let result = await serviceController.hasInstallments(this.state.serviceId);
        
        if (!result.status)
        {
            await this.setState({hasInstallments: false });
            return;
        }

        if ( result.data.dividas !== undefined && result.data.dividas.length > 0 )
        {
            const installmentsResults = result.data.dividas;
            installmentsResults.forEach(installment => 
            {      
                this.setState({hasInstallments: true })
            })
        }
    }

    /* Função para chamar modal com efeito para chamar mais atenção */
    messageAttention = (type, message, title) =>
    {
        this.setState({ messageAttentionType: type, messageAttentionText: message, messageAttentionTitle: title, messageAttentionShow: true })
    }

    /* Função para chamar modal com mensagem simples */
    message = (type, message, status) =>
    {
        this.props.setModalMessage({show: status === false ? false : true, type, message});
    }
    
    /* Função para checar contratos, negócio análises e assinaturas do cliente atual */
    checkAllbusinessAndServicesOfClient = async () =>
    {
        if (!this.state.hasEnvelope) { return; }
        this.setState({ thisClientHavePendencies: false})

        const checksContractsByClient = this.state.optionsExtraService;
        const checksContractsWithoutAnalysis = await businessController.checkBusinessContractData(this.state.businessClientId, "withoutAnalysis");
        const checksContractsWithAnalysisNoService = await businessController.checkBusinessContractData(this.state.businessClientId, "withAnalysisNoService");

        let countChecksContractsByClient = 0;
        let countChecksContractsWithoutAnalysis = 0;
        let countChecksContractsWithAnalysisNoService = 0;

        if (checksContractsByClient)
        {
            countChecksContractsByClient = checksContractsByClient.length;
        }

        if (checksContractsWithoutAnalysis.data)
        {
            countChecksContractsWithoutAnalysis = checksContractsWithoutAnalysis.data.data[0].businessesCount;
        }

        if (checksContractsWithAnalysisNoService.data)
        {
            countChecksContractsWithAnalysisNoService = checksContractsWithAnalysisNoService.data.data[0].businessesCount;
        }

        if (countChecksContractsByClient !== 0 || countChecksContractsWithoutAnalysis !== 0 || countChecksContractsWithAnalysisNoService !== 0)
        {
            let messages = [];

            if (countChecksContractsByClient !== 0 && !this.state[`maxAddServices${this.state.categorySelected}`])
            {
                messages.push(`<b>[${countChecksContractsByClient}]</b> SERVIÇO(S) DESTE MESMO CLIENTE (SEM ENVELOPE)`);
            }
            
            if (countChecksContractsWithoutAnalysis !== 0)
            {
                messages.push(`<b>[${countChecksContractsWithoutAnalysis}]</b> NEGÓCIO(S) DESTE MESMO CLIENTE (SEM ANÁLISE).`);
            }
            
            if (countChecksContractsWithAnalysisNoService !== 0)
            {
                messages.push(`<b>[${countChecksContractsWithAnalysisNoService}]</b> NEGÓCIO(S) DESTE MESMO CLIENTE (COM ANÁLISE E SEM SERVIÇO).`);
            }

            let message = `<b>ENCONTRAMOS NO SISTEMA</b>:
            
            ${messages.join("\n")}
            
            ANTES DE CONTINUAR, INCLUA NESTE ENVELOPE <b>TODOS OS SERVIÇOS</b> DO MESMO CLIENTE
            (VOCÊ PODE ADICIONAR ATÉ <b>05 SERVIÇOS</b> AO ENVELOPE).
            
            *** NEGÓCIO <b>PERDIDO</b> DEVE SER TRATADO ANTES DE PROSSEGUIR.
            `;

            this.setState({ thisClientHavePendencies: true });

            return this.messageAttention("warning", message, "ATENÇÃO")
        }
        else
        {
            this.setState({ thisClientHavePendencies: false });
        }
    }

    /* Função que envia envelope para assinatura */
    sendSignEnvelope = async (category) =>
    {
        await this.queryEnvelopesService();
        await this.attachmentsEnvelope(category);

        if (this.state.attachmentsFail) { return; }

        try
        {
            this.setState({ categorySelected: category });
            if (this.state.continueWithHavePendencies === false && !this.state[`maxAddServices${this.state.categorySelected}`])
            {
                await this.checkAllbusinessAndServicesOfClient();
            }
    
            if (this.state.thisClientHavePendencies) { return; }
    
            const data = {
                token: this.state.envelopesData[category - 1].token
            }
    
            const result = await serviceController.sendSignEnvelope(data);
            if (!result.status) { return; }

            await this.queryEnvelopesService();
        }
        catch (error)
        {
            console.error(
                "Erro ao enviar envelope para assinatura: ",
                error.response?.data || error.message
            );
            throw error;
        }
    }

    /* Função para adicionar ou remover com efeito de fadeIn, a tabela de anexos do envelope */
    handleToggleContainerAttachments = async (category) => {
        this.setState({ [`showAttachmentsDetailsEnvelope${category}`]: true });
        // if (!this.state.hasEnvelope) { return; }
        // if (this.state.envelopesData[category - 1].token === "") { return; }

        // this.setState({ classHandleToggleContainerAttachments: "" });
    
        // const currentState = this.state[`showAttachmentsDetailsEnvelope${category}`];
        // const otherCategory = category === 1 ? 2 : 1;
    
        // if (currentState) {
        //     this.setState({ classHandleToggleContainerAttachments: "fadeOutDetailsEnvelope" });
        //     setTimeout(() => {
        //         this.setState({ [`showAttachmentsDetailsEnvelope${category}`]: false });
        //     }, 560);
        // } else {
        //     this.setState({ 
        //         [`showAttachmentsDetailsEnvelope${category}`]: true, 
        //         [`showAttachmentsDetailsEnvelope${otherCategory}`]: false,
        //         classHandleToggleContainerAttachments: "fadeOutDetailsEnvelope"
        //     });
        //     setTimeout(() => {
        //         this.setState({ classHandleToggleContainerAttachments: "fadeInDetailsEnvelope" });
        //     }, 560);
        // }
    };
    

    handleToggleContainerSignatures = async (category) => {
        if (!this.state.hasEnvelope) { return; }
        this.setState({ classHandleToggleContainerSignatures: "" });
    
        const currentState = this.state[`showSignaturesDetailsEnvelope${category}`];
        const otherCategory = category === 1 ? 2 : 1;
    
        if (currentState) {
            this.setState({ classHandleToggleContainerSignatures: "fadeOutDetailsEnvelope" });
            setTimeout(() => {
                this.setState({ [`showSignaturesDetailsEnvelope${category}`]: false });
            }, 560);
        } else {
            this.setState({ 
                [`showSignaturesDetailsEnvelope${category}`]: true, 
                [`showSignaturesDetailsEnvelope${otherCategory}`]: false,
                classHandleToggleContainerSignatures: "fadeOutDetailsEnvelope"
            });
            setTimeout(() => {
                this.setState({ classHandleToggleContainerSignatures: "fadeInDetailsEnvelope" });
            }, 560);
        }
    };
    
    /* Função que consulta os anexos do envelope */
    attachmentsEnvelope = async (category) =>
    {
        await this.queryEnvelopesService();

        if (this.state.envelopesData[category - 1]?.token === "") { return; }

        const url = `${process.env.REACT_APP_ERP_API_URL}/ajax/detalhesEnvelopeWebhook.php`;

        const config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg="
            },
            data: JSON.stringify({ 
                token: this.state.envelopesData[category - 1]?.token
            })
        };

        try 
        {
            if (!this.state.hasEnvelope) { return; }
            
            const response = await axios.request(config);
            if (response.data.dados?.anexos === undefined || response.data.dados?.anexos.length < 0) { return; }

            const data = {
                category: category,
                attachments: response.data.dados.anexos,
                extraServices: response.data.dados.servicosExtra
            }

            await this.setState({ [`attachmentsEnvelopesData${category}`]: data, optionsExtraService: [] });

            if (this.state[`attachmentsEnvelopesData${category}`].extraServices.length > 0)
            {
                let optionsExtraService = [];
                this.state[`attachmentsEnvelopesData${category}`].extraServices.forEach(servico => {
                    optionsExtraService.push({
                        value: servico.id,
                        name: servico.nome
                    })
                });

                this.setState({ optionsExtraService });
            }

            await this.queryEnvelopesService();
            await this.signaturesEnvelope(category);
            await this.validation();
        } 
        catch (error)
        {
            this.setState({ [`showAttachmentsDetailsEnvelope${category}`]: false });
            console.error(
                "Erro ao consultar anexos do envelopes: ",
                error.response?.data || error.message
            );
            throw error;
        }
    }
    
    /* Função que consulta os signatários do envelope */
    signaturesEnvelope = async (category) =>
    {
        await this.queryEnvelopesService();

        if (this.state.envelopesData[category - 1]?.token === "") { return; }

        const url = `${process.env.REACT_APP_ERP_API_URL}/ajax/statusSignatariosWebhook.php`;

        const config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg="
            },
            data: JSON.stringify({ 
                token: this.state.envelopesData[category - 1]?.token,
                userId: this.state.userId
            })
        };

        try 
        {
            const response = await axios.request(config);
            if (response.data.assinantes === undefined || response.data.assinantes.length <= 0) { return; }

            const signers = response.data.assinantes;

            let counter = 0;
            let counterSigners = 0;

            await signers.forEach(signature => 
            {
                if (signature.qualification !== "EMPRESA")
                {
                    if (signature.statusAss === "signed")
                    {
                        counter++;
                    }
                }
    
                switch (signature.statusAss)
                {
                    case "new":
                        signature.nameStatusAss = "NÃO ASSINADO";
                        signature.classStatusAss = "classStatusAssNoSign"; 
                        break;
    
                    case "link-opened":
                        signature.nameStatusAss = "LINK ABERTO";
                        signature.classStatusAss = "classStatusAssLinkOpened";
                        break;
    
                    case "signed":
                        signature.nameStatusAss = "ASSINADO";
                        signature.classStatusAss = "classStatusAssSign";
                        break;
    
                    default:
                        break;
                }

                if (signature.statusAss === "signed")
                {
                    counterSigners++
                }

                if (signature.qualification === "CLIENTE" && signature.statusAss === "signed")
                {
                    this.setState({ [`clientHasBeenSigned${category}`]: true})
                }
            });
    
            if (counter === signers.length - 1)
            {
                this.setState({ [`releaseSignToCreator${category}`]: true });
            }
            
            if (counterSigners !== 0 && counterSigners === signers.length)
            {
                this.setState({ [`signCompletedCategory${category}`]: true })
            }
            else
            {
                this.setState({ [`signCompletedCategory${category}`]: false })
            }

            const data = {
                category: category,
                signers: signers
            }

            await this.setState({ signaturesEnvelopesData: data.signers });

            await this.queryEnvelopesService();
            await this.validation();
        } 
        catch (error)
        {
            console.error(
                "Erro ao consultar signatários do envelope: ",
                error.response?.data || error.message
            );
            throw error;
        }
    }

    /* Função para enviar link da assinatura ao Cliente */
    sendLinkSignatureToClient = async (signUrl) =>
    {
        const data = {
            userId: this.state.userId,
            serviceId: this.state.serviceId,
            clientId: this.state.clientId,
            url: signUrl,
        };

        const result = await serviceController.resendSubscription(data);
        if (!result.status) {
            return;
        }

        this.message(
            "success",
            "Mensagem enviada com sucesso!"
        );
    }

    /* Função para anexar um serviço em um envelope */
    sendAttachmentToEnvelope = async (category) => 
    {
        await this.queryEnvelopesService();

        if (this.state.choosenExtraService !== null)
        {
            const servicesIds = this.state.choosenExtraService.map(service => service.value).join(',');
            const data = {
                userId: this.state.userId,
                type: 2,
                serviceId: servicesIds,
                envelopeServiceId: this.state.serviceId,
                token: this.state.envelopesData[category - 1].token,
                annexOnly: 2,
                category: category
            }
    
            const result = await serviceController.addServicesEnvelope(data);
            
            if (!result.status) { return; }

            if (result.data.data.status === "401")
            {
                this.message("warning", result.data.data.message)
            }
            
            await this.queryEnvelopesService();
            await this.validation();
            await this.attachmentsEnvelope(category);
        }
    }

    onSubmitFormService = async() =>
    {
        const data = {...this.state};
        const dataService = {
            id: data.serviceId,
            witness1: data.witnesses[0] ? data.witnesses[0].userId : 0,
            witness2: data.witnesses[1] ? data.witnesses[1].userId : 0,
        }

        let result = null;

        if (this.state.serviceId)
        {
            result = await serviceController.updateWitness(dataService);

            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", result.message);
            }
        }

        this.queryEnvelopesService();
    }
    
    getService = async() =>
    {
        const result = await serviceController.getById(this.state.serviceId);

        if (!result.status) { return; }

        this.setState({
            witnessNumberOne: result.data.fromDB.service.Testemunha1,
            witnessNumberTwo: result.data.fromDB.service.Testemunha2
        })
    }

    queryValidationCode = async () => 
    {
        let data = {};
        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "clientID": this.state.clientId,
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                }
                break;

            case 2:
                data = {
                    "clientID": this.state.procuratorId,
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                }
                break;
                
            case 3:
                data = {
                    "clientID": this.state.guarantorId,
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                }
                break;

            default:
                break;
        }

        const result = await serviceController.queryValidationCode(data);
        if (!result.status || !result?.data) { return; }

        if (result.data.message === "Registro não encontrado")
        {
            return false;
        }
        else
        {
            return result.data.data;
        }
    }
    
    validatePhone = async (resend = 0) => 
    {
        let data = {};

        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.clientId,
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                    "resend": resend,
                }
                break;

            case 2:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.procuratorId,
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                    "resend": resend,
                }
                break;
                
            case 3:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.guarantorId,
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                    "resend": resend,
                }
                break;

            default:
                break;
        }

        let result = await serviceController.createValidationCode(data);

        if (result.data.data.status === "400")
        {
            this.message("warning", result.data.data.message);
        }

        this.setState({
            phoneId: result.data.data.idTelefone,
            statusValidationCode: 1,
            showValidationButton: false,
            showValidationCodeField: true,
            statusConfirmation: true,
            message_confirmation: "",
            message_show_confirmation: true,
            message_type_confirmation: ""
        });

        this.queryPhoneWhatsapp();
    }

    queryPhoneWhatsapp = async () => 
    {
        let data = {};
        await this.setState({showValidationButton: true });
        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                }
                break;

            case 2:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                }
                break;
                
            case 3:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                }
                break;

            default:
                break;
        }

        let result = await serviceController.queryPhoneWhatsapp(data);

        if (result.data.data.status === "400")
        {
            switch (this.state.bondsType)
            {
                case 1:
                    await this.setState({validatedContactClientIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactClientStatus: null,
                        validatedContactClient: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
    
                case 2:
                    await this.setState({validatedContactProcuratorIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactProcuratorStatus: null,
                        validatedContactProcurator: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
                    
                case 3:
                    await this.setState({validatedContactGuarantorIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactGuarantorStatus: null,
                        validatedContactGuarantor: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
                    
                default:
                    break;
            }

            await this.setState({showValidationCodeField: false, showValidationButton: true })
        }
        else if (result.data.data.status === "200")
        {
            await this.setState({showValidationCodeField: true, showValidationButton: false })

            switch (this.state.bondsType)
            {
                case 1:
                    let validatedContactClientIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactClientIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactClientIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactClientIcon = "fa fa-exclamation-triangle"
                    }

                    await this.setState({
                        validatedContactClientIcon: validatedContactClientIcon,
                        validatedContactClientStatus: result.data.data.data[0].verificado,
                        validatedContactClient: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        validatedContactClientContactRegional: result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
    
                case 2:
                    let validatedContactProcuratorIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactProcuratorIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactProcuratorIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactProcuratorIcon = "fa fa-exclamation-triangle"
                    }

                    await this.setState({
                        validatedContactProcuratorIcon: validatedContactProcuratorIcon,
                        validatedContactProcuratorStatus: result.data.data.data[0].verificado,
                        validatedContactProcurator: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        validatedContactProcuratorContactRegional: result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
                    
                case 3:
                    let validatedContactGuarantorIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactGuarantorIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactGuarantorIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactGuarantorIcon = "fa fa-exclamation-triangle"
                    }
                    
                    await this.setState({
                        validatedContactGuarantorIcon: validatedContactGuarantorIcon,
                        validatedContactGuarantorStatus: result.data.data.data[0].verificado,
                        validatedContactGuarantor: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        validatedContactGuarantorContactRegional: result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
    
                default:
                    break;
            }
        }

        if (this.state.onClickValidationContactOnWitness === true)
        {
            this.queryEnvelopesService();
            this.createTableSignatures();
        }
    }

    createTableSignatures = () =>
    {
        let signatures = [
            {
                show: true,
                typeName: "CLIENTE",
                type: 1,
                index: null,
                contact: formatedOnlyNumber(this.state.clientCellPhone1),
                name: this.state.clientName,
            },
            {
                show: this.state.checkProcurator,
                typeName: "PROCURADOR",
                type: 4,
                index: null,
                contact: formatedOnlyNumber(this.state.procuratorCellPhone1),
                name: this.state.procuratorName,
            },
            {
                show: this.state.checkGuarantor,
                typeName: "RESPONSÁVEL FINANCEIRO",
                type: 5,
                index: null,
                contact: formatedOnlyNumber(this.state.guarantorCellPhone1),
                name: this.state.guarantorName,
            },
            ...this.state.witnesses.map((item, index) => ({
                show: true,
                typeName: `TESTEMUNHA ${index + 1}`,
                type: 2,
                index: index,
                name: item.simpleName,
            })),
            {
                show: true,
                typeName: "EMPRESA",
                type: 3,
                index: null,
                name: this.state.creatorName,
            },
        ];
        
        let tableSignatures = (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th className="text-left">Tipo</th>
                        <th className="text-left">Nome</th>
                        <th className="text-center">Contato</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {signatures.map((signature, index) => {
                        if (!signature.show) return null;
    
                        let validatedStatus =
                            signature.type === 1
                                ? this.state.validatedContactClientStatus
                                : signature.type === 4
                                ? this.state.validatedContactProcuratorStatus
                                : this.state.validatedContactGuarantorStatus;

                        let validatedIcon;
                        if (signature.type === 1)
                        {
                            validatedIcon = this.state.validatedContactClientIcon;
                        }
                        
                        if (signature.type === 4)
                        {
                            validatedIcon = this.state.validatedContactProcuratorIcon;
                        }
                        
                        if (signature.type === 5)
                        {
                            validatedIcon = this.state.validatedContactGuarantorIcon;
                        }
    
                        let bondsType =
                            signature.type === 1 ? 1 : signature.type === 4 ? 2 : 3;
    
                        return (
                            <tr key={index}>
                                <td className="text-left">{signature.typeName}</td>
                                <td className="text-left">{signature.name}</td>
                                <td className="text-center">{signature.contact ? formatedPhoneAndCellphoneWithSpace(signature.contact) : ' - '}</td>
                                <td className="d-flex justify-content-end">
                                    {signature.type === 2 ? (
                                        <Button
                                            classaditional="buttonRemoveWitnessDetailsEnvelope"
                                            icon="fa fa-trash"
                                            onClick={() =>
                                                this.selectWitness(signature.index, 1)
                                            }
                                        />
                                    ) : signature.type === 1 ||
                                        signature.type === 4 ||
                                        signature.type === 5 ? (
                                        <Button
                                            classaditional="buttonValidationWitnessDetailsEnvelope"
                                            name={validatedStatus === "1" ? "" : "VERIFICAR CONTATO"}
                                            style={{
                                                color:
                                                    validatedStatus === "1"
                                                        ? "#21af41"
                                                        : "#ff7800",
                                            }}
                                            icon={validatedIcon}
                                            onClick={async () => {
                                                this.setState({
                                                    onClickValidationContactOnWitness: true,
                                                    validationCodeField: "",
                                                    bondsType,
                                                });
                                                await this.queryPhoneWhatsapp();
    
                                                if (validatedStatus === "1") return;
    
                                                this.setState({
                                                    message_confirmation: "",
                                                    message_show_confirmation: true,
                                                    message_type_confirmation: "",
                                                });
                                            }}
                                        />
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
        
        this.setState({ tableSignatures });
    };
    
    getWitnesses = async () =>
    {
        const result = await serviceController.getWitnesses(this.state.officeId, this.state.serviceId);
    
        if (!result.status || result.data.data.length === 0)
        {
            return;
        }
    
        const availableWitnesses = result.data.data.filter(item => item.userId !== this.state.userId);
    
        const witnesses = [];
        if (this.state.witnessNumberOne)
        {
            const indexOne = availableWitnesses.findIndex(witness => witness.userId === this.state.witnessNumberOne);
            if (indexOne !== -1)
            {
                witnesses.push(availableWitnesses[indexOne]);
                availableWitnesses.splice(indexOne, 1);
            }
        }
    
        if (this.state.witnessNumberTwo)
        {
            const indexTwo = availableWitnesses.findIndex(witness => witness.userId === this.state.witnessNumberTwo);
            if (indexTwo !== -1)
            {
                witnesses.push(availableWitnesses[indexTwo]);
                availableWitnesses.splice(indexTwo, 1);
            }
        }
    
        await this.setState({ availableWitnesses, witnesses });
    
        this.createTableSignatures();
    };
    
    selectWitness = async(index, selectType) =>
    {
        let witnesses = this.state.witnesses;
        let availableWitnesses = this.state.availableWitnesses;
        let currentWitness = null;

        switch (selectType)
        {
            case 0:
                    if (witnesses.length >= 2) { return; }

                    currentWitness = this.state.availableWitnesses[index];
                    witnesses.push(currentWitness);
                    availableWitnesses = availableWitnesses.filter((_, i) => i !== index);
                break;

            case 1:
                    currentWitness = this.state.witnesses[index];
                    availableWitnesses.push(currentWitness);
                    witnesses = witnesses.filter((_, i) => i !== index);
                break;

            default:
                break;
        }

        await this.setState({ availableWitnesses, witnesses });

        await this.onSubmitFormService();

        if (this.state.witnesses[1])
        {
            await this.getService();
            await document.querySelector(".accordion-button").click();
        }

        this.createTableSignatures();
    }

    allowContainerEnvelope = () =>
    {
        let status = true;
        let messages = [];
        let types = [];
    
        let whatsappValidationCount = 0;
        let whatsappValidationContactRegionalCount = 0;
        let whatsappValidation = {
            validatedContactClient: this.state.validatedContactClient === true,
        };
    
        if (this.state.checkGuarantor)
        {
            whatsappValidation.validatedContactGuarantor = this.state.validatedContactGuarantor === true;
        }
    
        if (this.state.checkProcurator)
        {
            whatsappValidation.validatedContactProcurator = this.state.validatedContactProcurator === true;
        }

        let whatsappValidationContactRegional = {
            validatedContactClient: this.state.validatedContactClientContactRegional === true,
        };
    
        if (this.state.checkGuarantor)
        {
            whatsappValidationContactRegional.validatedContactGuarantor = this.state.validatedContactGuarantorContactRegional === true;
        }
    
        if (this.state.checkProcurator)
        {
            whatsappValidationContactRegional.validatedContactProcurator = this.state.validatedContactProcuratorContactRegional === true;
        }
    
        Object.values(whatsappValidation).forEach((prop) => {
            if (!prop)
            {
                whatsappValidationCount = 1;
            }
        });

        Object.values(whatsappValidationContactRegional).forEach((prop) => {
            if (prop)
            {
                whatsappValidationContactRegionalCount = 1;
            }
        });

        if (whatsappValidationCount >= 1 || whatsappValidationContactRegionalCount >= 1)
        {
            status = false;
            messages.push(`Verificação pendente do WhatsApp${whatsappValidationContactRegionalCount >= 1 ? ", favor contactar a Regional;" : ""}`);
            types.push("Verificação dos números do cliente (Procurador, Cliente e Responsável Financeiro) vinculados.");
        }
        
        if (!this.state.hasInstallments)
        {
            status = false;
            messages.push("Salve as parcelas;");
            types.push("Verificação das parcelas salvas.");
        }
    
        if (this.state.witnesses.length !== 2)
        {
            status = false;
            messages.push("Adicione testemunhas;");
            types.push("Verificação de quantidade de testemunhas.");
        }
    
        return {
            type: types.length > 0 ? types.join(" | ") : "",
            status: status,
            message: messages.length > 0 
                ? `<ul class="listMessagesDetailsEnvelope">` + 
                  messages.map(msg => `<li>${msg}</li>`).join("") + 
                  `</ul>` 
                : "",
        };
    };

    verifyRegistratrionAttachments = (attachments) =>
    {
        let countProcurator = 0;
        let countNote = 0;
    
        attachments.forEach(item => {
            if (item.doc.tipo === "7")
            {
                countProcurator++;
            }
            else if (item.doc.tipo === "9")
            {
                countNote++;
            }
        });
    
        if (countProcurator > 0 && countNote > 0 && countProcurator === countNote)
        {
            return true;
        }
    
        return false;
    }

    /* Função para criar validações e definir exibições */
    validation = async () =>
    {
        const allow = await this.allowContainerEnvelope();
        if (allow.status === false)
        { 
            this.setState({ allowContainerEnvelope: false, allowContainerEnvelopeMessage: allow.message });
        }
        else
        {
            this.setState({ allowContainerEnvelope: true, allowContainerEnvelopeMessage: "" })
        }

        try
        {
            let notice = [];
            let envelopesAmount = 0;
            let envelopesWithToken = 0;
            this.state.envelopesData.forEach(envelope => {
                if (envelope.token !== "")
                {
                    envelopesWithToken++
                }
                envelopesAmount++

                const serviceId = this.state.serviceId;
                const addedServices = envelope.labels.servicosAdicionados;

                /* Condição para aviso de que o atual serviço não é o principal */
                if (addedServices !== "")
                {
                    const foundService = addedServices.find(s => s.servico === serviceId);
    
                    if (foundService) {
                        if (foundService.principal !== 1) {
                            const primaryService = addedServices.find(s => s.principal === 1);
                            notice.push({
                                show: true,
                                primaryService: primaryService.servico,
                                serviceIsAttachedToOtherEnvelope: `ESSE SERVIÇO ESTÁ ANEXADO AO ENVELOPE DO SERVIÇO ${primaryService.servico}.`,
                                category: envelope.categoria
                            })
                        }
                    }

                    this.setState({ [`maxAddServices${envelope.categoria}`]: (addedServices.length === 5) ? true : false });
                }
            });

            if (notice.length > 0)
            {
                this.setState({ notice: notice });
            }

            if (envelopesWithToken > 0)
            {
                this.setState({ hasEnvelope: true, allowContainerEnvelope: true});
            }
            else
            {
                this.setState({ hasEnvelope: false });
            }

            this.setState({ envelopesAmount });

            let storageButton = [];
            let allowContainerRelationships = {
                hasButtonCreateEnvelope: false,
            }

            this.state.envelopesData.forEach(envelope => {
                let buttons = {
                    category: envelope.categoria,
                    buttonCreateEnvelope: false,
                    buttonSendSign: false,
                    buttonTimer: false,
                    buttonDeleteEnvelope: false,
                    buttonSignaturesEnvelope: false,
                    buttonAttachmentsEnvelope: false
                };

                let clientHasBeenSigned = this.state[`clientHasBeenSigned${envelope.categoria}`];
            
                /* Condição para mostrar botão de criar envelope */
                if (envelope.labels.servicosAdicionados === "") {
                    buttons.buttonCreateEnvelope = true;

                    if (this.state.notice.some(item => item.show === true))
                    {
                        let noticeSelectedPrimaryService = this.state.notice.find(item => item.show === true);
                        noticeSelectedPrimaryService = noticeSelectedPrimaryService.primaryService;

                        
                        this.setState({ disableCreateEnvelope: true, disableWitness: true, messageDisableCreateEnvelope: `Crie o envelope a partir do serviço principal do Anexo (${noticeSelectedPrimaryService})`});
                    }
                    else
                    {
                        this.setState({ disableCreateEnvelope: false, disableWitness: false });
                    }

                    if (envelope.categoria === 1)
                    {
                        allowContainerRelationships.hasButtonCreateEnvelope = true;
                    }
                }

                if(envelope.categoria === 2)
                {
                    const attach = this.state.attachmentsEnvelopesData2;
                    if (attach)
                    {
                        if (!this.verifyRegistratrionAttachments(attach.attachments))
                        {
                            this.setState({ disableButtonSendSign: true, attachmentsFail: true,  messageDisableButtonSendSign: `Remova o envelope (falha na geração dos anexos)`});
                        }
                        else
                        {
                            this.setState({ disableButtonSendSign: this.state.clickButtonSendSign ? true : false, attachmentsFail: false,  messageDisableButtonSendSign: ``});
                        }
                    }
                }
            
                /* Condição para mostrar botões de status dos signatários e o botão de anexos do envelope */
                if (envelope.labels.servicosAdicionados !== "") {
                    buttons.buttonDeleteEnvelope = false;
                    buttons.buttonSignaturesEnvelope = true;
                    buttons.buttonAttachmentsEnvelope = false;
                }
            
                /* Condição para mostrar botão de enviar para assinatura */
                if (envelope.notifica === 0) {
                    buttons.buttonSendSign = true;
                }
            
                /* Condição para mostrar temporizador */
                if (envelope.notifica === 1 && envelope.assinatura !== "signed" && !clientHasBeenSigned) {
                    buttons.buttonTimer = true;
                }
            
                storageButton.push(buttons);
            });

            this.setState({ allowContainerRelationships })

            if (JSON.stringify(this.state.buttons) !== JSON.stringify(storageButton)) {
                this.setState({ buttons: storageButton });
                await this.reloadEnvelope();
            }
        }
        catch (error)
        {
            console.error(
                "Erro ao criar validação: ",
                error.response?.data || error.message
            );
            throw error;
        }
    }

    /* Função para fazer uma suavização ao atualizar o que está sendo exibido */
    reloadEnvelope = () =>
    {
        this.setState({ loading: true });
        
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);
    }

    render() {
        return (
            <Fragment>
                <MessageAttention
                    messageAttentionType={this.state.messageAttentionType}
                    messageAttentionText={this.state.messageAttentionText}
                    title={this.state.messageAttentionTitle}
                    onClose={() => this.setState({ messageAttentionShow: false })}
                    onConfirm={ async () => {
                        await this.setState({ isDisabledButtonCreateEnvelope: true });
                        this.setState({ messageAttentionShow: false, continueWithHavePendencies: true, thisClientHavePendencies: false }, async () => {
                            await this.setState({ loading: true });
                            await this.sendSignEnvelope(this.state.categorySelected);
                            await this.setState({ loading: false });
                        })
                        await this.setState({ isDisabledButtonCreateEnvelope: false });
                    }}
                    show={this.state.messageAttentionShow}
                />

                <MessageConfirmation
                    message_type={this.state.message_envelope_type_confirmation}
                    message={this.state.message_envelope_confirmation}
                    onClose={() => this.setState({message_envelope_show_confirmation: false})}
                    show={this.state.message_envelope_show_confirmation}
                    onClickYes={ async () => 
                    {
                        switch (this.state.message_envelope_type_functions)
                        {
                            case "delete":
                                    await this.deleteEnvelope(this.state.message_envelope_category_confirmation);
                                break;
                        
                            default:
                                break;
                        }
                    }}
                    onClickNo={() => this.setState({message_envelope_show_confirmation: false}) }
                >
                    {
                        this.state.bodyModalConfirmationOfEnvelope
                    }
                </MessageConfirmation>

                {
                    this.state.bondsType === 1 &&
                    <MessageConfirmation
                        message_type={this.state.message_type_confirmation}
                        message={this.state.message_confirmation}
                        onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                        show={this.state.message_show_confirmation}
                    >
                    {
                        this.state.showValidationButton &&
                        <div>
                            <Button
                                style={{fontSize: 14, height: 32}}
                                name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                onClick={async (e) => {
                                    this.setState({ isDisabledButton: true });
                                    await this.validatePhone();
                                    this.setState({ isDisabledButton: false });
                                }}
                                disabled={this.state.isDisabledButton}
                            >
                            </Button>
                            <br />
                        </div>
                    }
                    {
                        this.state.showValidationCodeField &&
                        <div>
                            <div className="mb-3" style={{display: "flex"}}>
                                <div style={{width: 165}}>
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral" 
                                        disabled={this.state.checkVerifyWhatsapp}
                                        label="Código de confirmação"
                                        value={this.state.validationCodeField}
                                        onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                    />
                                </div>
                                <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        classaditional={`buttonDetailsEnvelope`}
                                        onClick={async () => 
                                        {
                                            await this.setState({ isDisabledButton: true });
                                            const data = {
                                                "token": this.state.validationCodeField,
                                                "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1)
                                            }
                                    
                                            let result = await serviceController.verifyTokenValidationCode(data);
                                            await this.setState({ isDisabledButton: false });

                                            if (result.data.data.status === "200")
                                            {
                                                await this.setState({
                                                    message_confirmation: "",
                                                    message_show_confirmation: false,
                                                    message_type_confirmation: ""
                                                });
                                                await this.queryValidationCode();
                                                await this.queryPhoneWhatsapp();
                                                await this.message("success", result.data.data.message);
                                            }

                                            if (result.data.data.status === "400")
                                            {
                                                this.message("error", result.data.data.message);
                                            }

                                            this.queryPhoneWhatsapp();
                                        }}
                                        disabled={this.state.isDisabledButton}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                    >
                                    </Button>
                                </div>
                                <div className="d-flex align-items-end" style={{width: 165}}>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        classaditional={`buttonDetailsEnvelope`}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                        onClick={async (e) => {
                                            this.setState({ isDisabledButton: true });
                                            await this.validatePhone(1);
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    >
                                    </Button>
                                </div>
                            </div>

                            <div className="mb-3">
                                <Check 
                                    name="Impossivel verificar whatsapp" 
                                    onClick={() => 
                                    {
                                        this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                        
                                        const data = {
                                            phoneId: this.state.phoneId,
                                            verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                        }

                                        serviceController.releaseContractWithoutWhatsapp(data);
                                        this.queryPhoneWhatsapp();
                                    }} 
                                    checked={this.state.checkVerifyWhatsapp}
                                />
                            </div>
                        </div>
                    }
                    </MessageConfirmation>
                }

                {
                    this.state.bondsType === 2 &&
                    <MessageConfirmation
                        message_type={this.state.message_type_confirmation}
                        message={this.state.message_confirmation}
                        onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                        show={this.state.message_show_confirmation}
                    >
                        {
                            this.state.showValidationButton &&
                            <div>
                                <Button
                                    style={{fontSize: 14, height: 32}}
                                    name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                    onClick={async (e) => {
                                        this.setState({ isDisabledButton: true });
                                        await this.validatePhone();
                                        this.setState({ isDisabledButton: false });
                                    }}
                                    disabled={this.state.isDisabledButton}
                                >
                                </Button>
                                <br />
                            </div>
                        }
                        {
                            this.state.showValidationCodeField &&
                            <div>
                                <div className="mb-3" style={{display: "flex"}}>
                                    <div style={{width: 165}}>
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral" 
                                            disabled={this.state.checkVerifyWhatsapp}
                                            label="Código de confirmação"
                                            value={this.state.validationCodeField}
                                            onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                        />
                                    </div>
                                    <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            onClick={async () => 
                                            {
                                                this.setState({ isDisabledButton: true });
                                                const data = {
                                                    "token": this.state.validationCodeField,
                                                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1)
                                                }
                                        
                                                let result = await serviceController.verifyTokenValidationCode(data);
                                                this.setState({ isDisabledButton: false });

                                                if (result.data.data.status === "200")
                                                {
                                                    await this.setState({
                                                        message_confirmation: "",
                                                        message_show_confirmation: false,
                                                        message_type_confirmation: ""
                                                    });

                                                    await this.message("success", result.data.data.message);
                                                }

                                                if (result.data.data.status === "400")
                                                {
                                                    this.message("error", result.data.data.message);
                                                }

                                                this.queryPhoneWhatsapp();
                                            }}
                                            disabled={this.state.isDisabledButton}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                        >
                                        </Button>
                                    </div>
                                    <div className="d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                            onClick={async () => {
                                                this.setState({ isDisabledButton: true });
                                                await this.validatePhone(1);
                                                this.setState({ isDisabledButton: false });
                                            }}
                                            disabled={this.state.isDisabledButton}
                                        >
                                        </Button>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <Check 
                                        name="Impossivel verificar whatsapp" 
                                        onClick={() => 
                                        {
                                            this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                            
                                            const data = {
                                                phoneId: this.state.phoneId,
                                                verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                            }

                                            serviceController.releaseContractWithoutWhatsapp(data);
                                            this.queryPhoneWhatsapp();
                                        }} 
                                        checked={this.state.checkVerifyWhatsapp}
                                    />
                                </div>

                            </div>
                        }
                    </MessageConfirmation>
                }

                {
                    this.state.bondsType === 3 &&
                    <MessageConfirmation
                        message_type={this.state.message_type_confirmation}
                        message={this.state.message_confirmation}
                        onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                        show={this.state.message_show_confirmation}
                    >
                        {
                            this.state.showValidationButton &&
                            <div>
                                <Button
                                    style={{fontSize: 14, height: 32}}
                                    name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                    onClick={async (e) => {
                                        this.setState({ isDisabledButton: true });
                                        await this.validatePhone();
                                        this.setState({ isDisabledButton: false });
                                    }}
                                    disabled={this.state.isDisabledButton}
                                >
                                </Button>
                                <br />
                            </div>
                        }
                        {
                            this.state.showValidationCodeField &&
                            <div>
                                <div className="mb-3" style={{display: "flex"}}>
                                    <div style={{width: 165}}>
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral" 
                                            disabled={this.state.checkVerifyWhatsapp}
                                            label="Código de confirmação"
                                            value={this.state.validationCodeField}
                                            onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                        />
                                    </div>
                                    <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            onClick={async () => 
                                            {
                                                this.setState({ isDisabledButton: true });
                                                const data = {
                                                    "token": this.state.validationCodeField,
                                                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1)
                                                }
                                        
                                                let result = await serviceController.verifyTokenValidationCode(data);
                                                this.setState({ isDisabledButton: false });

                                                if (result.data.data.status === "200")
                                                {
                                                    await this.setState({
                                                        message_confirmation: "",
                                                        message_show_confirmation: false,
                                                        message_type_confirmation: ""
                                                    });

                                                    await this.message("success", result.data.data.message);
                                                }

                                                if (result.data.data.status === "400")
                                                {
                                                    this.message("error", result.data.data.message);
                                                }

                                                this.queryPhoneWhatsapp();
                                            }}
                                            disabled={this.state.isDisabledButton}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                        >
                                        </Button>
                                    </div>
                                    <div className="d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                            onClick={async (e) => {
                                                this.setState({ isDisabledButton: true });
                                                await this.validatePhone(1);
                                                this.setState({ isDisabledButton: false });
                                            }}
                                            disabled={this.state.isDisabledButton}
                                        >
                                        </Button>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <Check 
                                        name="Impossivel verificar whatsapp" 
                                        onClick={() => 
                                        {
                                            this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                            
                                            const data = {
                                                phoneId: this.state.phoneId,
                                                verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                            }

                                            serviceController.releaseContractWithoutWhatsapp(data);
                                            this.queryPhoneWhatsapp();
                                        }} 
                                        checked={this.state.checkVerifyWhatsapp}
                                    />
                                </div>
                            </div>
                        }
                    </MessageConfirmation>
                }

                {
                    (!this.state.preLoading) ? (
                        <Fragment>
                            {
                                (!this.state.hasAnotherServiceWithOpenEnvelope) ? (
                                    <Fragment>
                                        {
                                            (this.state.allowContainerRelationships.hasButtonCreateEnvelope && !this.state.disableWitness) &&
                                            <section className="containerRelationshipsDetailsEnvelope">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <strong>Signatários</strong>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    {this.state.tableSignatures}
                                                </div>
                                                <div className="mb-3">
                                                    <Accordion defaultActiveKey="0" alwaysOpen>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Selecione testemunhas logo abaixo</Accordion.Header>
                                                            <Accordion.Body>
                                                            {this.state.availableWitnesses.length > 0 ? (
                                                                <ul className="list-group witnessTableDetailsEnvelope">
                                                                    <li
                                                                        className="witnessTableHeaderDetailsEnvelope list-group-item d-flex justify-content-between"
                                                                        style={{ border: "0px" }}
                                                                    >
                                                                        <span style={{ width: "16em", textAlign: "left" }}>Nome</span>
                                                                        <div className="w-50 d-flex justify-content-between">
                                                                            <span style={{ width: "16em", textAlign: "center" }}>Status</span>
                                                                            <span style={{ width: "16em", textAlign: "center" }}>
                                                                                Observação
                                                                            </span>
                                                                            <span style={{ width: "18em", textAlign: "center" }}>
                                                                                Último Login
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                    {
                                                                        this.state.availableWitnesses.map((item, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className="witnessDetailsEnvelope list-group-item d-flex justify-content-between mt-2"
                                                                                onClick={() => this.selectWitness(index, 0)}
                                                                            >
                                                                                <span
                                                                                    className="witnessTableNameDetailsEnvelope flex-grow-1 text-start"
                                                                                    style={{ width: "16em", textAlign: "left" }}
                                                                                >
                                                                                    {item.simpleName}
                                                                                </span>
                                                                                <div className="w-50 d-flex justify-content-between witnessTableDetailsDetailsEnvelope">
                                                                                    <span
                                                                                        className="witnessTableStatusDetailsEnvelope"
                                                                                        style={{
                                                                                            width: "16em",
                                                                                            textAlign: "center",
                                                                                            color: item.online ? "green" : "red",
                                                                                        }}
                                                                                    >
                                                                                        {item.online ? "Online" : "Offline"}
                                                                                    </span>
                                                                                    <span style={{ width: "4em", textAlign: "center" }}>
                                                                                        {item.obs}
                                                                                    </span>
                                                                                    <span
                                                                                        className="witnessTableTimestampDetailsEnvelope text-center"
                                                                                        style={{ width: "18em" }}
                                                                                    >
                                                                                        {item.login ? formatedDateTime(item.login) : "Nunca logou"}
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            ) : (
                                                                <p>Não há testemunhas disponíveis.</p>
                                                            )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </section>
                                        }
                    
                                        {
                                            (this.state.allowContainerEnvelope) ? (
                                                <section className="containerDetailsEnvelope">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <strong>Envelopes</strong>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.loading ? (
                                                            [...Array(this.state.envelopesAmount !== 0 ? this.state.envelopesAmount : 1)].map((_, index) => (
                                                                <div key={index} className="containerEnvelopeDetailsEnvelope fadeElementDetailsEnvelope skeletonLoaderDetailsEnvelope">
                                                                    <div className="skeletonBoxDetailsEnvelope containerLabelsDetailsEnvelope">
                                                                        <h4 className="skeletonBoxDetailsEnvelope" style={{ width: "100px", height: "20px" }} aria-hidden="true"></h4>
                                                                        <span className="d-flex flex-column">
                                                                            <span className="skeletonBoxDetailsEnvelope" style={{ width: "200px", height: "15px" }}></span>
                                                                            <p className="skeletonBoxDetailsEnvelope" style={{ width: "100px", height: "15px" }}></p>
                                                                        </span>
                                                                    </div>
                                
                                                                    <div className="containerButtonsDetailsEnvelope">
                                                                        {[...Array(3)].map((_, i) => (
                                                                            <div key={i} className="skeletonBoxDetailsEnvelope" style={{ width: "180px", height: "46px", marginRight: "6px" }}></div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            this.state.envelopesData.map((item, index) => (
                                                                <Fragment>
                                                                    {
                                                                        this.state.notice.some(notice => notice.show === true) && (
                                                                            this.state.notice.map((notice, index) => (
                                                                                (notice.category === item.categoria) && (
                                                                                    <div className={`containerNoticeDetailsEnvelope  marginContract${item.categoria}DetailsEnvelope`}>
                                                                                        <div className="alert alert-warning alertDetailsEnvelope" role="alert">
                                                                                            <h5 className="alert-heading alertHeadingDetailsEnvelope">
                                                                                                <i className="fa fa-exclamation-triangle" /> 
                                                                                                AVISO
                                                                                            </h5>
                                                                                            <span className=""> | {notice.serviceIsAttachedToOtherEnvelope}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            ))
                                                                        )
                                                                    }
                                                                    <div key={index} className={`containerEnvelopeDetailsEnvelope containerEnvelopeCategory${item.categoria}DetailsEnvelope fadeElementDetailsEnvelope`}>
                                                                        <div className="containerLabelsDetailsEnvelope">
                                                                            <h4>
                                                                                { item.categoria === 1 ? "CONTRATO" : "ANEXOS"}
                                                                            </h4>
                                                                            <span className="d-flex flex-column">
                                                                                <span>
                                                                                    {
                                                                                        item.labels.servicosAdicionados !== "" && (
                                                                                            <b>
                                                                                                SERVIÇOS:{" "}
                                                                                                {item.labels.servicosAdicionados.map((labelService, index) => (
                                                                                                    <span key={index} 
                                                                                                        className={`
                                                                                                            ${labelService.principal === 1 ? "labelPrincipalServicesDetailsEnvelope" : ""}
                                                                                                            ${labelService.principal === 1 && labelService.servico !== this.state.serviceId ? "labelSecundaryServicesDetailsEnvelope" : ""}
                                                                                                        `}
                                                                                                    >
                                                                                                        {labelService.servico}{index < item.labels.servicosAdicionados.length - 1 ? ", " : ""}
                                                                                                    </span>
                                                                                                ))}
                                                                                            </b>
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                                <p className="dateDetailsEnvelope">{ item.labels.dataCriacao }</p>
                                                                                {
                                                                                    (item.temEnvelope) && (
                                                                                        (this.state[`signCompletedCategory${item.categoria}`]) ? (
                                                                                            <p className="signCompletedDetailsEnvelope">Todas assinaturas neste envelope foram concluídas.</p>
                                                                                        ) : (
                                                                                            <p className="unsignCompletedDetailsEnvelope">Existem pêndencias de assinatura neste envelope.</p>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </span>
                                                                        </div>
                                
                                                                        <div className="containerButtonsDetailsEnvelope">
                                                                            {
                                                                                this.state.buttons[item.categoria - 1]?.buttonCreateEnvelope && (
                                                                                    <Button
                                                                                        name={`${this.state.disableCreateEnvelope ? this.state.messageDisableCreateEnvelope : "criar envelope"}`}
                                                                                        title={this.state.messageDisableCreateEnvelope}
                                                                                        classaditional={`buttonDetailsEnvelope ${ this.state.disableCreateEnvelope ? "disableButtonCreateEnvelope" : "" }`}
                                                                                        disabled={this.state.disableCreateEnvelope}
                                                                                        onClick={async () => {
                                                                                            await this.setState({ loading: true });
                                                                                            await this.createEnvelope(item.categoria);
                                                                                            await this.setState({ loading: false });
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.buttons[item.categoria - 1]?.buttonSendSign && (
                                                                                    <Button
                                                                                        name={this.state.attachmentsFail ? this.state.messageDisableButtonSendSign : (this.state.disableButtonSendSign ? "aguarde..." : "enviar para assinatura")}
                                                                                        disabled={this.state.disableButtonSendSign}
                                                                                        onClick={async () => {
                                                                                            await this.setState({ disableButtonSendSign: true, clickButtonSendSign: true });
                                                                                            await this.sendSignEnvelope(item.categoria);
                                                                                            await this.setState({ disableButtonSendSign: this.state.attachmentsFail ? true : false, clickButtonSendSign: false });
                                                                                        }}
                                                                                        classaditional={`buttonDetailsEnvelope ${ this.state.attachmentsFail ? "disableButtonCreateEnvelope" : "" }`}
                                                                                    />
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.buttons[item.categoria - 1]?.buttonTimer && (
                                                                                    <div className={`timerDetailsEnvelope`}>
                                                                                        <i className="fa fa-alarm-clock" />
                                                                                        <Timer token={this.state.envelopesData[item.categoria - 1].token} initialMinutes={this.state.timer} onTimeEnd={() => this.queryEnvelopesService()} />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.buttons[item.categoria - 1]?.buttonDeleteEnvelope && (
                                                                                    <Button
                                                                                        icon="fa fa-trash"
                                                                                        onClick={async () => {
                                                                                            await this.queryEnvelopesService();
                                                                                            await this.confirmationDeleteEnvelope(item.categoria);
                                                                                        }}
                                                                                        classaditional={"buttonDetailsEnvelope buttonDeleteDetailsEnvelope"}
                                                                                    />
                                                                                )
                                                                            }
                                                                            {
                                                                                (this.state.buttons[item.categoria - 1]?.buttonSignaturesEnvelope && this.state[`signCompletedCategory${item.categoria}`] === false) && (
                                                                                    <Button
                                                                                        name={this.state.disabledButtonStatusSignatures ? "aguarde..." : "status dos signatários"} 
                                                                                        disabled={this.state.disabledButtonStatusSignatures}
                                                                                        classaditional={"buttonDetailsEnvelope buttonSignaturesDetailsEnvelope"}
                                                                                        onClick={async () => {
                                                                                            this.setState({ disabledButtonStatusSignatures: true });
                                                                                            await this.signaturesEnvelope(item.categoria);
                                                                                            await this.handleToggleContainerSignatures(item.categoria);
                                                                                            this.setState({ disabledButtonStatusSignatures: false });
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.buttons[item.categoria - 1]?.buttonAttachmentsEnvelope && (
                                                                                    <Button
                                                                                        name={this.state.disabledButtonAttachments ? "aguarde..." : "anexos do envelope"} 
                                                                                        onClick={async () => {
                                                                                            this.setState({ disabledButtonAttachments: true });
                                                                                            await this.attachmentsEnvelope(item.categoria);
                                                                                            await this.handleToggleContainerAttachments(item.categoria);
                                                                                            this.setState({ disabledButtonAttachments: false });
                                                                                        }}
                                                                                        classaditional={"buttonDetailsEnvelope buttonAttachmentDetailsEnvelope"}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                
                                                                    {
                                                                        (item.temEnvelope && this.state[`showAttachmentsDetailsEnvelope${item.categoria}`]) && (
                                                                            <div className={`containerAttachmentsDetailsEnvelope borderContainerAttachments${item.categoria}DetailsEnvelope  fadeElementDetailsEnvelope ${this.state.classHandleToggleContainerAttachments}`}>
                                                                                <span className="headerTableAttachments">
                                                                                    <h4 className="text-primary mb-4">
                                                                                        <i
                                                                                            className="fa fa-envelope-open-text me-2"
                                                                                        />
                                                                                        Anexos do Envelope
                                                                                    </h4>
                    
                                                                                    <Button
                                                                                        classaditional="buttonRefreshAttachmentsDetailsEnvelope"
                                                                                        icon={this.state.disableButtonRefreshAnalysis ? "fas fa-hourglass-start" : "fas fa-sync"}
                                                                                        disabled={this.state.disableButtonRefreshAnalysis}
                                                                                        onClick={async () => {
                                                                                            this.setState({disableButtonRefreshAnalysis: true})
                                                                                            await this.attachmentsEnvelope(item.categoria);
                                                                                            await this.handleToggleContainerAttachments(item.categoria);
                                                                                            this.setState({disableButtonRefreshAnalysis: false})
                                                                                        }}
                                                                                    />
                                                                                </span>
                                
                                                                                {
                                                                                    (this.state.optionsExtraService.length > 0 && item.notifica !== 1 && !this.state[`maxAddServices${item.categoria}`]) && (
                                                                                        <div className="row align-items-center mb-4">
                                                                                            <div className="col-10">
                                                                                                <SelectMulti
                                                                                                    label="Outros SERVIÇOS deste Cliente"
                                                                                                    classaditional="modernSelectFieldGeneral"
                                                                                                    default={this.state.choosenExtraService}
                                                                                                    options={this.state.optionsExtraService}
                                                                                                    onChange={(choosenExtraService) => {
                                                                                                        const checkedService = choosenExtraService.find(
                                                                                                        (service) => service.checked
                                                                                                        );
                                                                                                        const checkedServices = choosenExtraService.filter(
                                                                                                        (service) => service.checked
                                                                                                        );
                                
                                                                                                        if (checkedService) {
                                                                                                        if (
                                                                                                            checkedService.name.includes("Necessita salvar Parcelas")
                                                                                                        ) {
                                                                                                            this.message(
                                                                                                                "error",
                                                                                                                "Esse serviço não será anexado a outro serviço até que as parcelas dele tenham sido salvas."
                                                                                                            );
                                                                                                        } else {
                                                                                                            this.setState({ choosenExtraService: checkedServices });
                                                                                                        }
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-2 d-flex align-items-end boxButtonSendAttachmentEnvelopeDetailsEnvelope"
                                                                                            >
                                                                                                <Button
                                                                                                    classaditional={`buttonSendAttachmentEnvelopeDetailsEnvelope buttonDetailsEnvelope`}
                                                                                                    name={
                                                                                                        this.state.isDisabledButtonAddServices
                                                                                                        ? "aguarde..."
                                                                                                        : "anexar ao envelope"
                                                                                                    }
                                                                                                    onClick={async () => {
                                                                                                        this.setState({ isDisabledButtonAddServices: true });
                                                                                                        await this.sendAttachmentToEnvelope(item.categoria);
                                                                                                        this.setState({ isDisabledButtonAddServices: false });
                                                                                                    }}
                                                                                                    disabled={this.state.isDisabledButtonAddServices}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            
                                                                                <table className="table table-bordered table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text-end text-right">Serviço</th>
                                                                                            <th className="text-end text-right">Data de Envio</th>
                                                                                            <th>Enviado Por</th>
                                                                                            <th>Documento</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state[`attachmentsEnvelopesData${item.categoria}`]?.attachments.length > 0 ? (
                                                                                                this.state[`attachmentsEnvelopesData${item.categoria}`]?.attachments.map((attachment, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td className="text-end text-right">{attachment.servico}</td>
                                                                                                        <td className="text-end text-right">{attachment.data}</td>
                                                                                                        <td>{ attachment.enviadoPor }</td>
                                                                                                        <td>
                                                                                                            <a
                                                                                                                href={ attachment.doc.url }
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                            >
                                                                                                                { attachment.doc.nome || " - " }
                                                                                                            </a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan="5" className="text-center text-muted">
                                                                                                        Nenhum anexo disponível.
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        )
                                                                    }
                                
                                                                    {
                                                                        this.state[`showSignaturesDetailsEnvelope${item.categoria}`] && this.state[`signCompletedCategory${item.categoria}`] === false && (
                                                                            <div className={`containerSignaturesDetailsEnvelope fadeElementDetailsEnvelope ${this.state.classHandleToggleContainerSignatures}`}>
                                                                                <h4 className="text-primary mb-4">
                                                                                    <i className="fa fa-file-signature me-2" />
                                                                                    Signatários
                                                                                </h4>
                                                                                <table className={`table table-bordered mt-3`}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Tipo</th>
                                                                                            <th>Nome</th>
                                                                                            <th>Status</th>
                                                                                            <th>Re-enviar</th>
                                                                                            <th>Link</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state.signaturesEnvelopesData &&
                                                                                                this.state.signaturesEnvelopesData.length > 0 ? (
                                                                                                    this.state.signaturesEnvelopesData.map((signature, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>
                                                                                                                {signature.qualification ? signature.qualification : " - "}
                                                                                                            </td>
                                                                                                            <td>{signature.nome ? signature.nome : " - "}</td>
                                                                                                            <td className="statusSignDetailsEnvelope">
                                                                                                                <span
                                                                                                                    className={`spanInfoSignedDetailsEnvelope ${signature.classStatusAss}`}
                                                                                                                >
                                                                                                                    {signature.nameStatusAss ? signature.nameStatusAss : " - "}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    signature.exibirZap === "1" ? (
                                                                                                                        <Button
                                                                                                                            className={`buttonSignaturesLinkDetailsEnvelope buttonWhatsappSignaturesDetailsEnvelope`}
                                                                                                                            icon="fab fa-whatsapp"
                                                                                                                            onClick={async () => {
                                                                                                                                await this.sendLinkSignatureToClient(signature.sign_url);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        ) : (
                                                                                                                            " - "
                                                                                                                        )
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    signature.qualification === "EMPRESA" &&
                                                                                                                        this.state[`releaseSignToCreator${item.categoria}`] ? (
                                                                                                                            <Button
                                                                                                                                className="buttonSignaturesLinkDetailsEnvelope"
                                                                                                                                icon="fa fa-external-link"
                                                                                                                                onClick={() => window.open(signature.sign_url, "_blank")}
                                                                                                                            />
                                                                                                                        ) : (
                                                                                                                            " - "
                                                                                                                        )
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan="6" className="text-center text-muted">
                                                                                                        Este envelope não possui nenhum anexo atualmente
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Fragment>
                                                            ))
                                                        )
                                                    }
                                                </section>
                                            ) : (
                                                <section className="containerDetailsEnvelope">
                                                    <div className={`containerNoticeDetailsEnvelope`}>
                                                        <div className="alert alert-warning alertDetailsEnvelope" role="alert">
                                                            <h5 className="alert-heading alertHeadingDetailsEnvelope">
                                                                <i className="fa fa-exclamation-triangle" /> 
                                                                AVISO
                                                            </h5>
                                                            <span className="alertAllowHeadingDetailsEnvelope">Corrija os seguintes erros: </span>
                                                            <span dangerouslySetInnerHTML={{ __html: this.state.allowContainerEnvelopeMessage }} />
                                                        </div>
                                                    </div>
                                                </section>
                                            )
                                        }
                                    </Fragment>
                                ) : (
                                    <section className="containerDetailsEnvelope">
                                        <div className={`containerNoticeDetailsEnvelope`}>
                                            <div className="alert alert-warning alertDetailsEnvelope" role="alert">
                                                <h5 className="alert-heading alertHeadingDetailsEnvelope">
                                                    <i className="fa fa-exclamation-triangle" /> 
                                                    AVISO
                                                </h5>
                                                {/* <span>Este cliente já tem um envelope aberto <b><a href={``}>[clique aqui]</a></b> para incluir este serviço junto com o outro.</span> */}
                                                <span>Este cliente já tem um envelope aberto no serviço <b>{this.state.idAnotherServiceWithOpenEnvelope}</b>, você deve incluir serviço atual junto com o outro.</span>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }
                        </Fragment>
                    ) : (
                        <Fragment>
                            <section className="containerDetailsEnvelope">
                                {
                                    [...Array(1)].map((_, index) => (
                                        <div key={index} className="containerEnvelopeDetailsEnvelope fadeElementDetailsEnvelope skeletonLoaderDetailsEnvelope">
                                            <div className="skeletonBoxDetailsEnvelope containerLabelsDetailsEnvelope">
                                                <h4 className="skeletonBoxDetailsEnvelope" style={{ width: "100px", height: "20px" }} aria-hidden="true"></h4>
                                                <span className="d-flex flex-column">
                                                    <span className="skeletonBoxDetailsEnvelope" style={{ width: "200px", height: "15px" }}></span>
                                                    <p className="skeletonBoxDetailsEnvelope" style={{ width: "100px", height: "15px" }}></p>
                                                </span>
                                            </div>

                                            <div className="containerButtonsDetailsEnvelope">
                                                {[...Array(3)].map((_, i) => (
                                                    <div key={i} className="skeletonBoxDetailsEnvelope" style={{ width: "180px", height: "46px", marginRight: "6px" }}></div>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </section>
                        </Fragment>
                    )
                }
            </Fragment>
        )
    }
}

function mapStateToProps (state) 
{
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const permissions = state.permissions;
    const { user } = state.auth;
    
    return {
        hasPermissionsLoaded,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsEnvelope));