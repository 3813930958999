import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class BusinessServices
{
    async getByIdCompanyUnit(idCompanyUnit) 
    {
        const response = await api.businessServices().getByIdCompanyUnit(idCompanyUnit);
        return apiCallbackTreatment(response);
    }
    async getAllByIdCompanyUnit(idCompanyUnit) 
    {
        const response = await api.businessServices().getAllByIdCompanyUnit(idCompanyUnit);
        return apiCallbackTreatment(response);
    }
    async getTypesServicesByIdCompany(idCompanyUnit) 
    {
        const response = await api.businessServices().getTypesServicesByIdCompany(idCompanyUnit);
        return apiCallbackTreatment(response);
    }
    async getDetailsEnvelopeByIdService(id) 
    {
        const response = await api.businessServices().getDetailsEnvelopeByIdService(id);
        return apiCallbackTreatment(response);
    }
}