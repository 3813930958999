import { 
    NEW_DEAL_BUSINESS, 
    CHANGE_SERVICE_TYPE_BUSINESS,
    CHANGE_DEAL_BUSINESS,
    CHANGE_MODE_VIEW_BUSINESS,
    GET_ALL_DRAGGABLES_BUSINESS,
    TOGGLE_CHECKED_ALL_BUSINESS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS
}  from "../../core/constants";

//action creator
export function newDealBusiness (data)
{
    return {
        type: NEW_DEAL_BUSINESS,
        payload: data,
    }
}

//action creator
export function changeDealBusiness (data)
{
    return {
        type: CHANGE_DEAL_BUSINESS,
        payload: data,
    }
}

//action creator
export function changeModeViewBusiness (mode)
{
    return {
        type: CHANGE_MODE_VIEW_BUSINESS,
        payload: mode,
    }
}

//action creator
export function getAllDraggablesBusiness (data)
{
    return {
        type: GET_ALL_DRAGGABLES_BUSINESS,
        payload: data
    }
}

//action creator
export function toggleCheckedAllBusiness (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_BUSINESS,
        payload: status
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedBusiness (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS,
        payload: status
    }
}

//action creator
export function changeServiceTypeBusiness (status)
{
    return {
        type: CHANGE_SERVICE_TYPE_BUSINESS,
        payload: status
    }
}