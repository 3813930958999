import React, { useState, Fragment } from "react";
import "./Box.css";

export default function Box(props) {
    const { 
        classaditional, 
        classaditionalcontentbox, 
        children, 
        title, 
        collapsible = false, 
        defaultCollapsed = false 
    } = props;
    
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    
    const toggleCollapse = () => {
        if (collapsible) {
            setIsCollapsed(!isCollapsed);
        }
    };
    
    return (
        <Fragment>
            {collapsible ? (
                <div className={`box ${classaditional || ''}`}>
                    {title && (
                        <div className="headerBox" onClick={toggleCollapse}>
                            <div className="titleBox">{title}</div>
                            <div className="collapseIconBox">
                                <i className={`fas ${isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                            </div>
                        </div>
                    )}
                    
                    <div className={`contentBox ${classaditionalcontentbox || ''} ${isCollapsed ? 'collapsed' : ''}`}>
                        {children}
                    </div>
                </div>
            ) : (
                <div className={`boxWithoutCollapse ${classaditional || ''}`}>
                    {children}
                </div>
            )}
        </Fragment>
    );
}