import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MenusCRM from "../../components/CRM/MenusCRM";
import ScreensCRM from "./ScreensCRM";
import "./CRM.css";

import { connect } from "react-redux";
import { setModalMessage, setListenerWhatsAppUpdatedMessages } from "../../store/actions/general";
import { 
    changeServiceQueue, 
    changeStatusLastPositionSpecialistUpdatedServiceQueue,
    changeStatusFrozenSpecialistUpdatedServiceQueue,
} from "../../store/actions/queue";
import {
    changeStatusInsertedReportCommissionRules,
    changeStatusDeletedReportCommissionRules,
    changeStatusGetFromDatabaseCompletedCommissionRules,
    getAllCommissionRules
} from "../../store/actions/commission";
import { setSocketsMainOnlineAuth, setSocketsMainOnlineUpdatedAuth } from "../../store/actions/auth";
import socketIOClient from "socket.io-client";
import { 
    EVENT_SERVICE_QUEUE_ALL, 
    EVENT_SERVICE_QUEUE_UPDATED,
    EVENT_COMMISSION_RULES_NEW_REPORT,
    EVENT_COMMISSION_RULES_DELETE_REPORT,
    EVENT_COMMISSION_RULES_UPDATED_REPORT,
    IDS_PERMISSIONS_GROUPS_NEED_CHANGE_PASSWORD_EVERY_MONDAY,
    EVENT_CONNECTION_OPEN,
    EVENT_CONNECTION_ESTABLISHED,
    EVENT_CONNECTION_UPDATED,
    EVENT_CONNECTION_SURVIVAL_PACKAGE,
    TIME_INTERVAL_SURVIVAL_PACKAGES_SEND_MINIMUM,
    EVENT_LISTENER_WHATSAPP_UPDATED_MESSAGES
} from "../../core/constants";
import { baseURLSocket } from "../../configuration";
import ServiceQueuePersonsController from "../../controllers/ServiceQueuePersonsController";
import { 
    formatedDateTimeToUSA, 
    getAllSpecificDaysOnMonth, 
    getDateTimeCurrentBrowserAtMidnight, 
    identifiesBrowser
} from "../../auxiliary/generalFunctions";
import Message from "../../components/general/Message";

import AuthController from "../../controllers/AuthController";
import ServiceController from "../../controllers/ServiceController";
import BusinessController from "../../controllers/BusinessController";

const initialState = {
    allSpecialistsNotFiltered: [
        // {
        //     idCompanyUnit: 1,
        //     specialists: [
        //         {
        //             id: 1,
        //             name: "Paulo Fernandes",
        //             frozen: 2,
        //             position: 1,
        //             idCompanyUnit: 1
        //         }
        //     ]
        // }
    ],
    idCurrentCompanyUnit: null,

    currentScreenCRM: "",

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message

    havePendingIssuesOnService: false,

    notificationsWhatsAppInQueue: 0,
    notificationsWhatsApp: 0,
}

const serviceQueuePersonsController = new ServiceQueuePersonsController();
const authController = new AuthController();
const serviceController = new ServiceController();
const businessController = new BusinessController();

var socket;
class CRM extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {   
        socket = socketIOClient(baseURLSocket); 

        await this.getCurrentCompanyUnit();

        let browser = identifiesBrowser();

        socket.on(EVENT_CONNECTION_OPEN, async (data) => {
            let user = this.props.user;
            await this.setState({idConnectionSocket: data.idConnectionSocket, ipConnectionSocket: data.ipConnectionSocket});

            this.sendSurvivalPackage();
            this.setIntervalSendSurvivalPackage();

            socket.emit(EVENT_CONNECTION_ESTABLISHED, 
                {
                    id: user.PER_ID,
                    name: user.PER_NAME,
                    idConnectionSocket: data.idConnectionSocket,
                    ipConnectionSocket: data.ipConnectionSocket,
                    browser: `${browser.name} - ${navigator?.userAgentData?.platform || "[Não identificado]"}`
                }
            );
        });

        socket.on(EVENT_CONNECTION_UPDATED, (users) => {
            this.props.setSocketsMainOnlineAuth(users.clients);
            this.props.setSocketsMainOnlineUpdatedAuth(true);
        });

        socket.on(EVENT_SERVICE_QUEUE_ALL, (allSpecialistsNotFiltered) => {
            this.setState({allSpecialistsNotFiltered});
            this.props.changeServiceQueue(allSpecialistsNotFiltered);
        });

        socket.on(EVENT_COMMISSION_RULES_UPDATED_REPORT, async (msg) => {
            await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
            await this.props.getAllCommissionRules();
        });

        socket.on(EVENT_LISTENER_WHATSAPP_UPDATED_MESSAGES, async (msg) => {
            this.props.setListenerWhatsAppUpdatedMessages(true);
        });

        this.haveChangedPasswordEveryMonday();
        // this.havePendingIssuesOnService(); // INFORMATION: Don't remove!
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("CRM prevProps ", prevProps.general);
        // console.log("CRM this.props ", this.props);

        if (this.props.general !== undefined && prevProps.general)
        {
            if (prevProps.general.message_show !== this.props.general.message_show)
            {
                await this.setState({...this.props.general});
            }
        }

        if 
        (
            this.props.queue.idSpecialistForLastPosition !== null &&
            !this.props.queue.idSpecialistForLastPositionUpdated
        )
        {
            await this.setLastPositionSpecialist(this.props.queue.idSpecialistForLastPosition);
        }

        if 
        (
            this.props.queue.idSpecialistForFrozen !== null &&
            !this.props.queue.idSpecialistForFrozenUpdated
        )
        {
            await this.setFrozenSpecialist(this.props.queue.idSpecialistForFrozen);
        }

        if
        (
            this.props.commission.idCommissionRuleForReport !== null &&
            !this.props.commission.idCommissionRuleForReportUpdated
        )
        {
            await this.newReportCommissionRule(this.props.commission.idCommissionRuleForReport);
        }

        if
        (
            this.props.commission.idCommissionRuleForDeleteReport !== null &&
            !this.props.commission.idCommissionRuleForDeleteReportUpdated
        )
        {
            await this.deleteReportCommissionRule(this.props.commission.idCommissionRuleForDeleteReport);
        }

        if (this.props.general.currentScreenCRM !== this.state.currentScreenCRM)
        {
            // this.havePendingIssuesOnService(); // INFORMATION: Don't remove!
            this.haveChangedPasswordEveryMonday();
            this.setState({currentScreenCRM: this.props.general.currentScreenCRM});
        }
    }

    sendSurvivalPackage = async () =>
    {
        let user = this.props.user;
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let mili = date.getMilliseconds();

        let browser = identifiesBrowser();

        let idConnectionSocket = await this.state.idConnectionSocket;
        let ipConnectionSocket = await this.state.ipConnectionSocket;

        socket.emit(EVENT_CONNECTION_SURVIVAL_PACKAGE, 
            {
                id: user.PER_ID,
                name: user.PER_NAME,
                stamp: Date.UTC(year, month, day, hours, minutes, seconds, mili),
                idConnectionSocket: idConnectionSocket,
                ipConnectionSocket: ipConnectionSocket,
                browser: `${browser.name} - ${navigator?.userAgentData?.platform || "[Não identificado]"}`
            }
        );
    }

    setIntervalSendSurvivalPackage = () =>
    {
        setInterval(() => {
            this.sendSurvivalPackage();
        }, TIME_INTERVAL_SURVIVAL_PACKAGES_SEND_MINIMUM);
    }

    getCurrentCompanyUnit = async () =>
    {
        let userCompanyUnits = this.props.user.unit_companies;
        
        // getting just a service queue (of a company unit)
        if (userCompanyUnits.length === 1)
        {
            let idCurrentCompanyUnit = userCompanyUnits[0].UNT_ID;
            await this.setState({idCurrentCompanyUnit});
        }
    }

    setLastPositionSpecialist = async (idSpecialistUpdatePosition) =>
    {
        let amountNotFrozenSpecialists = 0;
        let allSpecialistsNotFiltered = await this.state.allSpecialistsNotFiltered;
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;

        allSpecialistsNotFiltered.forEach(u => {
            if (u.idCompanyUnit === idCurrentCompanyUnit)
            {
                u.specialists.forEach(s => {
                    if (s.frozen === 2)
                    {
                        amountNotFrozenSpecialists++;
                    }
                });
            }
        });

        let responseUpdatePositions = await serviceQueuePersonsController.updatePosition({
            "SEP_ID_PERSON": idSpecialistUpdatePosition,
            "SEP_POSITION": (amountNotFrozenSpecialists)
        });

        if (responseUpdatePositions.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }

        await this.props.changeStatusLastPositionSpecialistUpdatedServiceQueue(true);
    }

    setFrozenSpecialist = async (idSpecialistUpdateFrozen) =>
    {
        let amountFrozenSpecialists = 0;
        let allSpecialistsNotFiltered = await this.state.allSpecialistsNotFiltered;
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;

        allSpecialistsNotFiltered.forEach(u => {
            if (u.idCompanyUnit === idCurrentCompanyUnit)
            { amountFrozenSpecialists = u.specialists.length; }
        });

        // changing the position first.
        let responseUpdatePositions = await serviceQueuePersonsController.updatePosition({
            "SEP_ID_PERSON": idSpecialistUpdateFrozen,
            "SEP_POSITION": amountFrozenSpecialists
        });

        // informing all sockets of the position update.
        if (responseUpdatePositions.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }

        // changing the frozen status.
        let responseUpdateFrozen = await serviceQueuePersonsController.update(idSpecialistUpdateFrozen, {
            "SEP_FROZEN": 1
        });

        // informing all sockets of the frozen status update.
        if (responseUpdateFrozen.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }

        await this.props.changeStatusFrozenSpecialistUpdatedServiceQueue(true);
    }

    newReportCommissionRule = async (idCommissionRule) =>
    {
        socket.emit(EVENT_COMMISSION_RULES_NEW_REPORT, idCommissionRule);
        await this.props.changeStatusInsertedReportCommissionRules(false);
    }

    deleteReportCommissionRule = async (idCommissionRule) =>
    {
        socket.emit(EVENT_COMMISSION_RULES_DELETE_REPORT, idCommissionRule);
        await this.props.changeStatusDeletedReportCommissionRules(false);
    }

    haveChangedPasswordEveryMonday = async () =>
    {
        let user = this.props.user;
        let idPermissionGroup = user.PER_ID_PERMISSIONS_GROUP

        if (IDS_PERMISSIONS_GROUPS_NEED_CHANGE_PASSWORD_EVERY_MONDAY.includes(idPermissionGroup))
        {
            let currentDay = getDateTimeCurrentBrowserAtMidnight();
            let currentDate = formatedDateTimeToUSA(currentDay.toDateString());
            let parts = currentDate.toString().split("-");
            let currentTimeStamp = currentDay.getTime();
            let currentMonthYear = [parts[0], parts[1]].join("-");
            let allMondays = getAllSpecificDaysOnMonth(1, currentMonthYear);
            let beforeMondays = allMondays.filter(m => currentTimeStamp >= m.stamp);
            let lastMonday = beforeMondays[beforeMondays.length - 1];
            let currentLocation = window.location.href;
            let changePasswordLocation = "preferences-user/mandatory=true";

            //DANGER: It's not the turn of the month.
            if (lastMonday)
            {
                let responsePassword = await authController.isChangedPasswordFromDate(user.PER_ID, lastMonday.dateUSA);
        
                if (!responsePassword.status)
                {
                    if (currentLocation.indexOf(changePasswordLocation) < 0)
                    {
                        window.location.href = changePasswordLocation;
                        await this.setState({isMandatoryChangePassword: true, locationChangePassword: changePasswordLocation});
                    }
                }
            }
        }
    }
    
    // INFORMATION: Don't remove!
    havePendingIssuesOnService = async () => {
        if (window.location.href.includes('preferences-user')) { return; }
    
        const user = this.props.user;
        
        if (user.PER_ID_PERMISSIONS_GROUP === 7 || user.PER_ID_PERMISSIONS_GROUP === 14) {
            const data = {
                id_user: user.PER_ID_USER_ERP
            };
        
            const result = await serviceController.havePendingIssuesOnService(data);
            
            if (!result.status) { return; }
            if (result.data.data.length === 0 || result.data.data[0].servicos.length === 0) { return; }
    
            this.setState({ havePendingIssuesOnService: true })
        
            const business = await businessController.getBusinessByIdService(result.data.data[0].servicos[0].idServico);
            if (business.data.data.length <= 0) { return; }
            const businessId = business.data.data[0].BUS_ID;
        
            const baseUrl = window.location.origin;
            const targetUrl = `${baseUrl}/business/details/${businessId}`;
    
            this.setState({
                message_show: true,
                message: "Há pendências de assinatura no serviço associado a este negócio. Conclua o serviço para prosseguir com outras atividades."
            });

            if (window.location.href !== targetUrl)
            {
                window.location.href = targetUrl;
            }
        }
    };
    

    render ()
    {
        return (
            <div className="crm">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => {this.state.havePendingIssuesOnService ? this.setState({ message_show: false, message: "" }) : this.props.setModalMessage({show: false, type: "success", message: ""})}}
                    show={this.state.message_show}
                />
                <Router>
                    <MenusCRM 
                        onLogout={this.props.onLogout} 
                        notificationsWhatsAppInQueue={this.state.notificationsWhatsAppInQueue}
                        notificationsWhatsApp={this.state.notificationsWhatsApp}
                    />
                    <ScreensCRM />
                </Router>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const { commission, general, queue, permissions } = state;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        user,
        queue,
        general,
        commission,
        permissions,
        hasPermissionsLoaded
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeServiceQueue (data) 
        {
            //action creator -> action
            const action = changeServiceQueue(data);
            dispatch(action);
        },
        changeStatusInsertedReportCommissionRules (status) 
        {
            //action creator -> action
            const action = changeStatusInsertedReportCommissionRules(status);
            dispatch(action);
        },
        changeStatusDeletedReportCommissionRules (status) 
        {
            //action creator -> action
            const action = changeStatusDeletedReportCommissionRules(status);
            dispatch(action);
        },
        changeStatusLastPositionSpecialistUpdatedServiceQueue (status) 
        {
            //action creator -> action
            const action = changeStatusLastPositionSpecialistUpdatedServiceQueue(status);
            dispatch(action);
        },
        changeStatusFrozenSpecialistUpdatedServiceQueue (status) 
        {
            //action creator -> action
            const action = changeStatusFrozenSpecialistUpdatedServiceQueue(status);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedCommissionRules (status) 
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedCommissionRules(status);
            dispatch(action);
        },
        getAllCommissionRules ()
        {
            //action creator -> action
            const action = getAllCommissionRules();
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        setSocketsMainOnlineAuth (data)
        {
            //action creator -> action
            const action = setSocketsMainOnlineAuth(data);
            dispatch(action);
        },
        setSocketsMainOnlineUpdatedAuth (status)
        {
            //action creator -> action
            const action = setSocketsMainOnlineUpdatedAuth(status);
            dispatch(action);
        },
        setListenerWhatsAppUpdatedMessages (status)
        {
            //action creator -> action
            const action = setListenerWhatsAppUpdatedMessages(status);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRM);