import React, { useEffect, useState } from "react";
import ModalEdit from "./ModalEdit";
import Select from "./Select";

import BusinessServicesController from "../../controllers/BusinessServicesController";

const businessServicesController = new BusinessServicesController();

const SelectTypeServices = ({
    classaditional,
    title,
    onPositiveClick,
    onNegativeClick,
    permissions,
    optionsSelectMulti,
    selectedOption,
    setSelectedOption,
    isServiceComplete,
    label,
    businessIdCompanyUnit,
}) => {
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() =>
    {
        async function fetchOptions()
        {
            if (!businessIdCompanyUnit) { return; }
            try
            {
                let optionsTypeServices = [];
                const options = await businessServicesController.getTypesServicesByIdCompany(businessIdCompanyUnit);
                
                for(const option of options.data)
                {
                    optionsTypeServices.push(
                        {
                            value: option.FIN_ID,
                            name: option.BUP_NAME,
                            BUP_ID: option.BUP_ID,
                            status: option.BUP_STATUS,
                            BUP_STATUS: option.BUP_STATUS,
                            id: option.BUN_ID_BUSINESS_SERVICE,
                            id_service_erp: option.BUP_ID_SERVICE_ERP,
                            id_financial_service: option.BUP_ID_FINANCIAL_SERVICE,
                            percentRateService: parseFloat(option.FIN_PERCENT_RATE),
                            percentFeesService: parseFloat(option.FIN_PERCENT_FEES),
                            checked: false
                        }
                    )
                }

                setSelectOptions(optionsTypeServices);
            }
            catch (error)
            {
                console.error("Erro ao buscar serviços:", error);
            }
        }

        fetchOptions();
    }, [businessIdCompanyUnit]);

    return (
        <ModalEdit
            classaditional={classaditional}
            title={title}
            onPositiveClick={onPositiveClick}
            onNegativeClick={onNegativeClick}
            permissions={permissions}
            optionsSelectMulti={optionsSelectMulti}
        >
            <Select
                label={label}
                disabled={isServiceComplete}
                default={selectedOption}
                options={selectOptions}
                withFieldSearch
                onChange={async (option) => {
                    const selected = selectOptions.find((e) => e.value === option.value);
                    console.log('🔍 QUICK LOG:', selected);
                    await setSelectedOption(selected);
                }}
            />
        </ModalEdit>
    );
};

export default SelectTypeServices;
