import { Component, Fragment, createRef } from "react";
import "./ServiceForm.css"

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import 
{ 
    capitalize,
    formatedDateTime,
    formatedMoneyBRA,
    formatedMoneyBRAFromFloat,
    formatedMoneyBRAToFloat,
    formatedOnlyNumber,
    formatedPhoneAndCellphoneWithSpace,
    hasSecondName,
    isCpfOrCnpj,
    isEmail,
    isUnderage,
    maskCNPJ,
    maskCPF 
} from "../../auxiliary/generalFunctions";

import Button from "../general/Button";
import InputGroup from "../general/InputGroup";
import InputGeneral from "../general/InputGeneral";
import MessageConfirmation from "../general/MessageConfirmation";
import MessageAttention from "../general/MessageAttention";
import LargeModal from "../general/LargeModal";
import Wizard from "../general/Wizard";
import DetailsEnvelope from "../general/DetailsEnvelope";
import Select from "../general/Select";
import Check from "../general/Check";

import Accordion from "react-bootstrap/Accordion";

import BusinessController from "../../controllers/BusinessController";
import ClientFromERPController from "../../controllers/ClientFromERPController";
import FinancialServicesController from "../../controllers/FinancialServicesController";
import ServiceController from "../../controllers/ServiceController";

import FinancialServices from "../../assets/others/financial-services-class/assets/js/FinancialServices";

import { setModalMessage } from "../../store/actions/general";
import SelectMulti from "../general/SelectMulti";
import FileUpload from "../general/FileUpload";

const axios = require("axios");

const initialState = {
    disabledButton: false,
    visibleButton: true,
    id: null,
    thisClientHavePendencies: false,
    continueWithHavePendencies: false,
    dateCreated: null,
    businessId: null,
    officeId: null,
    bondsType: null,
    file: null,
    filePath: null,
    hasProcuratorDocument: false,
    procuratorDocumentStatusMessage: "",

    //START SHOW GROUP FIELD
    showDataVehicles: 0,
    showDataCreditCard: 0,
    showDataOverdraft: 0,
    showDataClearName: 0,
    showAdress: 1,
    showDataInstitution: 0,
    showFieldComarca: 0,
    showFieldDateInstitution: 0,
    //END SHOW GROUP FIELD 

    // start content money
    validMoney: true,
    // ends content money

    showValidationCodeField: false,
    isDisabledButton: false,
    showValidationButton: true,
    statusValidationCode: 1,
    validationCodeField: "",
    validationCode: "",

    // START MODAL
    large_modal_show_confirmation: false,
    objectConfirmation: {},
    statusConfirmation: false,
    message_show_confirmation: false,
    message_type_confirmation: "",
    message_confirmation: "Insira o código de validação",

    message_envelope_show_confirmation: false,
    message_envelope_type_confirmation: "information",
    message_envelope_type_functions: "",
    message_envelope_confirmation: "",

    categorySendEnvelope: null,
    categoryDeleteEnvelope: null,
    categoryStatusSignatoriesEnvelope: null,
    categoryDetailsEnvelope: null,
    categoryCreateEnvelope: null,
    showFirstCategory: false,
    showSecondCategory: false,

    showDetailsEnvelopeFirstCategory: false,
    showDetailsEnvelopeSecondCategory: false,
    showDetailsEnvelopeThirdCategory: false,

    // END MODAL

    // START INFORMATION ABOUT CLIENT TAB
    optionsClientType: [
        {
            name: "Física",
            value: 1
        },
        {
            name: "Jurídica",
            value: 2
        },
    ],
    choosenClientType: {
        name: "Física",
        value: 1
    },
    clientNationalRegistration: "",
    clientId: null,
    clientName: "",
    clientNameBusiness: "",
    optionsClientGender: [
        {
            name: "MASCULINO",
            value: "2"
        },
        {
            name: "FEMININO",
            value: "1"
        }
    ],
    choosenClientGender: null,
    optionsClientMaritalStatus: [
        {
            name: "SOLTEIRO",
            value: "1",
        },
        {
            name: "CASADO",
            value: "2",
        },
        {
            name: "VIUVO",
            value: "3",
        },
        {
            name: "DIVORCIADO",
            value: "4",
        }
    ],
    choosenClientMaritalStatus: {
        name: "Solteiro",
        value: "1"
    },
    clientBirthday: "",
    clientIdentity: "",
    clientEmail: "",
    clientJob: "",
    clientCPFCNPJ: "",
    clientNationality: "",
    clientFatherName: "",
    clientMotherName: "",
    clientSpouse: "",
    clientCpfSpouse: null,
    clientNaturalness: "",
    clientZipCode: "",
    optionsClientState: [],
    choosenClientState: null,
    optionsClientCity: [],
    choosenClientCity: null,
    optionsClientNeighborhood: [],
    choosenClientNeighborhood: null,
    choosenClientIdentificationType: 1,
    validateCpfCnpj: 2,
    isPhoneValidated: false,
    validatedContactClient: false,
    clientAddress: "",
    clientAddressNumber: "",
    clientAddressComplement: "",
    clientCellPhone1: "",
    clientCellPhone1ContactName: "",
    clientCellPhone1ChoosenKinship: null,
    clientCellPhone2: "",
    clientCellPhone2ContactName: "",
    clientCellPhone2ChoosenKinship: null,
    clientCellPhone3: "",
    clientCellPhone3ContactName: "",
    clientCellPhone3ChoosenKinship: null,
    clientCellPhone4: "",
    clientCellPhone4ContactName: "",
    clientCellPhone4ChoosenKinship: null,
    clientCellPhone5: "",
    clientCellPhone5ContactName: "",
    clientCellPhone5ChoosenKinship: null,
    clientCellPhone6: "",
    clientCellPhone6ContactName: "",
    clientCellPhone6ChoosenKinship: null,
    clientCellPhone7: "",
    clientCellPhone7ContactName: "",
    clientCellPhone7ChoosenKinship: null,
    clientCellPhone8: "",
    clientCellPhone8ContactName: "",
    clientCellPhone8ChoosenKinship: null,
    clientCellPhones: [],
    clientCellPhoneContactNames: [],
    clientCellPhoneKinships: [],
    optionsClientKinship: [],
    clientStateRegistration: "",
    clientRegistered: false,
    clientRegisteredOnService: false,
    clientRegistrationSent: false,
    onClickValidationContactOnWitness: false,
    clientIssuingInstitution: "",
    clientRG: "",
    validatedContactClientStatus: null,
    validatedContactClientIcon: "fa fa-exclamation-triangle",
    fieldsContactElementsClient: [],
    fieldsContactElementsProcurator: [],
    fieldsContactElementsGuarantor: [],
    fieldsClientContact: 3,
    fieldsProcuratorContact: 3,
    fieldsGuarantorContact: 3,
    // ENDS INFORMATION ABOUT CLIENT TAB

    checkVerifyWhatsapp: false,
    // START GUARANTOR
    checkGuarantor: false,
    optionsGuarantorType: [
        {
            name: "Física",
            value: 1
        },
        {
            name: "Jurídica",
            value: 2
        },
    ],
    choosenGuarantorType: {
        name: "Física",
        value: 1
    },
    guarantorNationalRegistration: "",
    guarantorId: null,
    guarantorName: "",
    optionsGuarantorGender: [
        {
            name: "MASCULINO",
            value: "2"
        },
        {
            name: "FEMININO",
            value: "1"
        }
    ],
    choosenGuarantorGender: null,
    guarantorCPFCNPJ: "",
    guarantorBirthday: "",
    guarantorIdentity: "",
    guarantorEmail: "",
    guarantorJob: "",
    guarantorNationality: "",
    guarantorFatherName: "",
    guarantorMotherName: "",
    guarantorNaturalness: "",
    guarantorZipCode: "",
    guarantorSpouse: "",
    guarantorCpfSpouse: null,
    optionsGuarantorState: [],
    choosenGuarantorState: null,
    choosenGuarantorMaritalStatus: null,
    validatedContactGuarantor: false,
    optionsGuarantorCity: [],
    optionsGuarantorNeighborhood: [],
    choosenGuarantorCity: null,
    guarantorRegistered: false,
    guarantorIsClient: false,
    guarantorIsProcurator: false,
    choosenGuarantorNeighborhood: null,
    choosenGuarantorIdentificationType: 1,
    validateGuarantorCpfCnpj: 2,
    guarantorAddress: "",
    guarantorAddressNumber: "",
    guarantorAddressComplement: "",
    guarantorCellPhone1: "",
    guarantorCellPhone1ContactName: "",
    guarantorCellPhone1ChoosenKinship: null,
    guarantorCellPhone2: "",
    guarantorCellPhone2ContactName: "",
    guarantorCellPhone2ChoosenKinship: null,
    guarantorCellPhone3: "",
    guarantorCellPhone3ContactName: "",
    guarantorCellPhone3ChoosenKinship: null,
    guarantorCellPhone4: "",
    guarantorCellPhone4ContactName: "",
    guarantorCellPhone4ChoosenKinship: null,
    guarantorCellPhone5: "",
    guarantorCellPhone5ContactName: "",
    guarantorCellPhone5ChoosenKinship: null,
    guarantorCellPhone6: "",
    guarantorCellPhone6ContactName: "",
    guarantorCellPhone6ChoosenKinship: null,
    guarantorCellPhone7: "",
    guarantorCellPhone7ContactName: "",
    guarantorCellPhone7ChoosenKinship: null,
    guarantorCellPhone8: "",
    guarantorCellPhone8ContactName: "",
    guarantorCellPhone8ChoosenKinship: null,
    guarantorStateRegistration: "",
    guarantorIssuingInstitution: "",
    guarantorRG: "",
    validatedContactGuarantorIcon: "fa fa-exclamation-triangle",
    validatedContactGuarantorStatus: null,
    // END GUARANTOR

    // START PROCURATOR
    checkProcurator: false,
    optionsProcuratorType: [
        {
            name: "Física",
            value: 1
        },
        {
            name: "Jurídica",
            value: 2
        },
    ],
    choosenProcuratorType: 1,
    procuratorNationalRegistration: "",
    procuratorId: null,
    procuratorName: "",
    optionsProcuratorGender: [
        {
            name: "Masculino",
            value: "2"
        },
        {
            name: "Feminino",
            value: "1"
        }
    ],
    choosenProcuratorGender: {
        name: "Masculino",
        value: "2"
    },
    procuratorCPFCNPJ: "",
    procuratorBirthday: "",
    procuratorIdentity: "",
    procuratorEmail: "",
    procuratorJob: "",
    procuratorSpouse: "",
    procuratorCpfSpouse: null,
    validatedContactProcurator: false,
    procuratorNationality: "",
    procuratorFatherName: "",
    procuratorMotherName: "",
    procuratorNaturalness: "",
    procuratorZipCode: "",
    optionsProcuratorState: [],
    choosenProcuratorState: null,
    optionsProcuratorCity: [],
    choosenProcuratorCity: null,
    optionsProcuratorNeighborhood: [],
    procuratorRegistered: false,
    procuratorIsClient: false,
    choosenProcuratorNeighborhood: null,
    choosenProcuratorIdentificationType: 1,
    validateProcuratorCpfCnpj: 2,
    procuratorAddress: "",
    procuratorAddressNumber: "",
    procuratorAddressComplement: "",
    procuratorCellPhone1: "",
    procuratorCellPhone1ContactName: "",
    procuratorCellPhone1ChoosenKinship: null,
    procuratorCellPhone2: "",
    procuratorCellPhone2ContactName: "",
    procuratorCellPhone2ChoosenKinship: null,
    procuratorCellPhone3: "",
    procuratorCellPhone3ContactName: "",
    procuratorCellPhone3ChoosenKinship: null,
    procuratorCellPhone4: "",
    procuratorCellPhone4ContactName: "",
    procuratorCellPhone4ChoosenKinship: null,
    procuratorCellPhone5: "",
    procuratorCellPhone5ContactName: "",
    procuratorCellPhone5ChoosenKinship: null,
    procuratorCellPhone6: "",
    procuratorCellPhone6ContactName: "",
    procuratorCellPhone6ChoosenKinship: null,
    procuratorCellPhone7: "",
    procuratorCellPhone7ContactName: "",
    procuratorCellPhone7ChoosenKinship: null,
    procuratorCellPhone8: "",
    procuratorCellPhone8ContactName: "",
    procuratorCellPhone8ChoosenKinship: null,
    procuratorStateRegistration: "",
    procuratorIssuingInstitution: "",
    procuratorRG: "",
    choosenProcuratorMaritalStatus: null,
    optionsProcuratorMaritalStatus: [],
    validatedContactProcuratorStatus: null,
    validatedContactProcuratorIcon: "fa fa-exclamation-triangle",
    // END PROCURATOR

    // START SERVICE
    optionsServiceType: [],
    choosenServiceType: null,
    title: "",
    busIdServiceERP: null,
    optionsServiceStatusNegociation: [],
    choosenServiceStatusNegociation1: null,
    choosenServiceStatusNegociation2: null,
    optionsFinancialInstitution: [],
    choosenFinancialInstitution: null,
    optionsProcurator: [],
    choosenProcurator: null,
    choosenServiceStatus: null,
    optionsServiceStatus: [
        { value: 1, name: "Aberto" },
        { value: 2, name: "Fechado" },
        { value: 3, name: "Suspenso" },
        { value: 4, name: "Cancelado" },
        { value: 5, name: "Em Análise exclusão" }
    ],
    choosenSuspendService: null,
    optionsSuspendService: [
        { value: 1, name: "SIM" },
        { value: 2, name: "NÃO" }
    ],
    choosenCommissionService: null,
    optionsCommissionService: [
        { value: 1, name: "SIM" },
        { value: 2, name: "NÃO ESPECIALISTA" },
        { value: 3, name: "NÃO GERENTE" },
        { value: 4, name: "NÃO AMBOS" }
    ],
    // END SERVICE

    // START CONTRACT
    optionsTypeContract: [
        {
            name: "CDC",
            value: 1
        },
        {
            name: "LEASING",
            value: 2
        },
        {
            name: "FINANCIAMENTO",
            value: 3
        },
    ],
    choosenTypeContract: null,
    signatureDate: "",
    contractNumber: "",
    contractComarca: "",
    processNumber: "",
    dueDate: "5",
    contractZipCode: "",
    optionsContractState: [],
    choosenContractState: null,
    optionsContractCity: [],
    choosenContractCity: null,
    optionsContractNeighborhood: [],
    choosenContractNeighborhood: null,
    contractAddress: "",
    contractAddressNumber: "",
    contractAddressComplement: "",
    vehicleType: "",
    vehicleBrand: "",
    vehicleYear: "",
    vehicleColor: "",
    vehiclePlate: "",
    vehicleRenavam: "",
    vehicleChassis: "",
    optionsDetranState: [],
    choosenDetranState: {
        name: "ESTADO",
        value: 0
    },
    bankAgency: "",
    bankCheckingAccount: "",
    creditCardFinal: "",
    contractLateDate: "",
    contractNumberInstallments: "",
    contractInstallmentValue: 0,
    contractNumberInstallmentsPaid: "",
    contractAmountFinanced: 0,
    contractAmountTotalPaid: 0,
    contractAmountTotalDebit: 0,
    contractDischargeIsLessThan: 0,
    contractAmountTotalClosed: 0,
    contractAmountDiscountObtained: 0,
    // END CONTRACT

    // START SERVICE VALUE
    optionsPaymentMethod: [],
    paymentMethodInstallmentEntry: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment1: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment2: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment3: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment4: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment5: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment6: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment7: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment8: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment9: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment10: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment11: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment12: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment13: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment14: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment15: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment16: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment17: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment18: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment19: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment20: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment21: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment22: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment23: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment24: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment25: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment26: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment27: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment28: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment29: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment30: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment31: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment32: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment33: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment34: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment35: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment36: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment37: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment38: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment39: { name: "SELECIONE", value: 0 },
    paymentMethodInstallment40: { name: "SELECIONE", value: 0 },
    choosenPaymentMethod: null,
    paymentMethodDescription: "",
    serviceValue: 0,
    serviceValueHasInstallment: 0,
    optionsPayment: [],
    choosenPayment: null,
    analysisInstallments: [],
    amountEntry: 0,
    setInstallmentsBySelectInstallments: false,
    amountService: "",
    // END SERVICE VALUE

    //START FINANCE VALUES
    alertEntry: false,
    linkPrint: "",
    installmentSelected: 0,
    optionsInstallments: [],
    installmentElements: [],
    installmentsAvailable: [],
    choosenInstallment: {
        "name": "Selecione uma parcela",
        "value": 0
    },
    hasInstallments: false,
    numInstallments: 0,
    enableInstallmentsGroup: false,
    debts: null,
    paymentMethodsByIndex: {},
    totalInstallments: "",
    totalInstallmentsPaid: "",
    amountInstallment: "",
    totalInstallmentsNotPaid: "",
    textButtoncreateInstallments: false,
    showButtoncreateInstallments: false,
    // END FINANCE VALUES

    // START SIGNATURES
    witnesses: [],
    tableSignatures: null,
    availableWitnesses: [],
    witnessNumberOne: null,
    witnessNumberTwo: null,
    hasEnvelopeContract: false,
    controlTimer: false,
    currentTimeEnvelope: null,
    envelopeToken: "",
    attachmentDetails: null,
    showAttachmentsDetails: true,
    signaturesEnvelope: null,
    releaseSignToCreator: false,
    showSignaturesEnvelope: false,
    detailsEnvelopeData: null,
    detailsEnvelopeSender: null,
    detailsEnvelopeSign: null,
    envelopeTimer: null,
    categorySelected: null,
    optionsServiceExtra: [],
    choosenServiceExtra: [],
    buttonSendSign: {},
    isDisabledButtonCreateEnvelope: false,
    isDisabledButtonCreateEnvelopeSecondCategory: false,
    isDisabledButtonAddServices: false,
    isDisabledButtonStatusSignatures: false,
    isDisabledButtonDetailsEnvelope: false,
    buttonStatusSignatories: {},
    buttonDetailsEnvelope: {},
    buttonDeleteEnvelope: {},
    buttonTimerEnvelope: {},
    buttonCreateEnvelope: {},
    messageHasAnotherServiceWithEnvelope: "",
    disabledAttachServicesButton: false,
    statusHasAnotherServiceWithEnvelope: false,
    labelsDateEnvelope: null,
    colorEnvelopeFirstCategory: null,
    labelsEnvEnvelope: null,
    labelsNameDocsEnvelope: null,
    labelsServicesEnvelope: null,
    labelsFirstCategory: "",
    bodyModalConfirmationOfEnvelope: <></>,
    buttonSendSignSecondCategory: {},
    buttonCreateEnvelopeSecondCategory: {},
    buttonStatusSignatoriesSecondCategory: {},
    buttonDetailsEnvelopeSecondCategory: {},
    buttonDeleteEnvelopeSecondCategory: {},
    detailsEnvelopeDataSecondCategory: null,
    detailsEnvelopeSenderSecondCategory: null,
    detailsEnvelopeSignSecondCategory: null,
    envelopeTimerSecondCategory: null,
    controlTimerSecondCategory: false,
    showAttachmentsDetailsSecondCategory: true,
    attachmentDetailsSecondCategory: null,
    showSignaturesEnvelopeSecondCategory: false,
    signaturesEnvelopeSecondCategory: null,
    labelsSecondCategory: "",
    labelsDateEnvelopeSecondCategory: null,
    colorEnvelopeSecondCategory: null,
    labelsEnvEnvelopeSecondCategory: null,
    labelsNameDocsEnvelopeSecondCategory: null,
    labelsServicesEnvelopeSecondCategory: null,
    enableFieldsEnvelope: false,
    enableFieldsEnvelopeMessage: "",
    envelopeAlertFirstCategory: "",
    envelopeAlertSecondCategory: "",
    // END SIGNATURES

    saveService: false,
    hasEnvelopes: false,
    installmentsWithRegistration: false,
    phoneId: null,
    activeKey: 0,
    installments: [],
    initializedTabDetailsFinance: false,
    onlyFirstTimeIfServiceExists: false,
    signaturesOpen: false,
    onlyFirstTimeIfClientExists: false,
    onlyFirstTimeIfProcuratorExists: false,
    onlyFirstTimeIfGuarantorExists: false,

    messageAttentionType: "",
    messageAttentionShow: false,
    messageAttentionText: "",
    messageAttentionTitle: "",

    dataEnvelope: null
}

const businessController = new BusinessController();
const clientController = new ClientFromERPController();
const financialServicesController = new FinancialServicesController();
const serviceController = new ServiceController();

const financialServicesClass = new FinancialServices();

class ServiceForm extends Component 
{
    state = { ...initialState }

    constructor(props) 
    {
        super(props);
        this.wizardRef = createRef();
        
        this.serviceRefs = {
            clientName: createRef(),
            guarantorName: createRef(),
            procuratorName: createRef(),
        };
    }

    componentDidMount = async () => 
    {
        const idService = this.props.idService;
        
        this.setState({ id: idService }, async () => {
            try 
            {
                await this.getSelectOptions();
                await this.getBusinessFromURL();

                const [selectOptions, businessFromURL, installmentsAvailable, numberOfInstallments] = await Promise.all([
                    this.getSelectOptions(),
                    this.getBusinessFromURL(),
                ]);
    
                this.setState({
                    selectOptions,
                    businessFromURL,
                    installmentsAvailable,
                    numberOfInstallments,
                });

                await this.getService();

                this.setState({ dataEnvelope: {
                    ...this.state.dataEnvelope,
                    serviceId: this.state.id,
                    clientCellPhone1: this.state.clientCellPhone1,
                    hasInstallments: this.state.hasInstallments,
                    procuratorCellPhone1: this.state.procuratorCellPhone1,
                    guarantorCellPhone1: this.state.guarantorCellPhone1,
                    witnessNumberOne: this.state.witnessNumberOne,
                    witnessNumberTwo: this.state.witnessNumberTwo,
                    procuratorId: this.state.procuratorId,
                    guarantorId: this.state.guarantorId,
                    clientId: this.state.clientId,
                    checkGuarantor: this.state.checkGuarantor,
                    checkProcurator: this.state.checkProcurator,
                    officeId: this.state.officeId,
                    userId: this.props.user.PER_ID_USER_ERP,
                    creatorName: this.state.creatorName,
                    clientName: this.state.clientName,
                    procuratorName: this.state.procuratorName,
                    guarantorName: this.state.guarantorName,
                    businessClientId: this.props.business.clientId
                }});
            } 
            catch (error) 
            {
                console.error("Erro ao carregar dados iniciais:", error);
            }
        });
    };
    
    componentDidUpdate = async (prevProps) => 
    {
        if (prevProps.idService !== this.props.idService) 
        {
            this.setState({ id: prevProps.idService })
            await this.getService();
            
            this.setState({ dataEnvelope: {
                ...this.state.dataEnvelope,
                serviceId: this.state.id,
                clientCellPhone1: this.state.clientCellPhone1,
                hasInstallments: this.state.hasInstallments,
                procuratorCellPhone1: this.state.procuratorCellPhone1,
                guarantorCellPhone1: this.state.guarantorCellPhone1,
                witnessNumberOne: this.state.witnessNumberOne,
                witnessNumberTwo: this.state.witnessNumberTwo,
                procuratorId: this.state.procuratorId,
                guarantorId: this.state.guarantorId,
                clientId: this.state.clientId,
                checkGuarantor: this.state.checkGuarantor,
                checkProcurator: this.state.checkProcurator,
                officeId: this.state.officeId,
                userId: this.props.user.PER_ID_USER_ERP,
                creatorName: this.state.creatorName,
                clientName: this.state.clientName,
                procuratorName: this.state.procuratorName,
                guarantorName: this.state.guarantorName,
                businessClientId: this.props.business.clientId
            }})
        }
    
        if (prevProps.businessService !== this.props.businessService) 
        {
            this.handleBusinessServiceChange();
            this.switchServices();
        }
    
        if (!prevProps.onClickButtonService && this.props.onClickButtonService)
        {
            this.setState(
                { isDisabledButton: true, continueRegistrationActive: true },
                async () =>
                {
                    try
                    {
                        await this.getBusinessFromURL();
                        if (this.state.busIdServiceERP !== this.state.id || this.state.id === null)
                        {
                            this.setState({ id: this.state.busIdServiceERP })
                        }
                        await Promise.all([
                            this.getService(),
                            this.getWitnesses(),
                            this.hasInstallments(),
                            this.getInstallmentsAvailable(),
                        ]);

                        if (this.state.id !== null)
                        {
                            await this.queryEnvelopesService();
                        }
                    } 
                    catch (error)
                    {
                        console.error("Erro ao processar clique no botão de serviço:", error);
                    }
                    finally
                    {
                        this.setState({
                            isDisabledButton: false,
                            large_modal_show_confirmation: true,
                        });
    
                        setTimeout(
                            () => this.setState({ clientRegisteredOnService: false }),
                            3000
                        );
                    }
                }
            );
        }
    };
    
    
    message = (type, message, status) =>
    {
        this.props.setModalMessage({show: status === false ? false : true, type, message});
    }
    
    messageAttention = (type, message, title) =>
    {
        this.setState({ messageAttentionType: type, messageAttentionText: message, messageAttentionTitle: title, messageAttentionShow: true })
    }

    getService = async() =>
    {
        if (this.state.id === null) { return; }
        const result = await serviceController.getById(this.state.id);

        if (!result.status) { return; }
        this.setState({ ...result.data.service });
        this.setState({ clientId: result.data.fromDB.client.PS_ID });
        await this.setState({checkProcurator: result.data.fromDB.service.idProcurador !== 0 ? true : false})
        await this.setState({checkGuarantor: result.data.fromDB.service.idFiador !== 0 ? true : false})

        let financialInstitution = this.state.optionsFinancialInstitution.find((e) => e.value === result.data.fromDB.service.idFinanceira);
        financialInstitution = financialInstitution?.value;
        
        if (financialInstitution?.value !== this.state.choosenFinancialInstitution?.value)
        {
            financialInstitution = this.state.choosenFinancialInstitution?.value;
        }

        this.setState({
            choosenFinancialInstitution: this.state.optionsFinancialInstitution.find((e) => e.value === financialInstitution),
            choosenServiceType: result.data.fromDB.service.Tipo,
            creatorId: result.data.fromDB.service.idUsuario,
            creatorName: result.data.fromDB.service.nomeUsuario,
            dateCreated: result.data.fromDB.service.DtCadastro,
            guarantorId: result.data.fromDB.service.idFiador,
            procuratorId: result.data.fromDB.service.idProcurador,
            officeId: result.data.fromDB.service.idEscritorio,
            title: result.data.fromDB.service.Titulo,
            contractNumber: result.data.fromDB.service.NumeroContrato,
            contractComarca: result.data.fromDB.service.Comarca,
            choosenTypeContract: result.data.fromDB.service.TipoContrato,
            dueDate: result.data.fromDB.service.DiaVencimento,
            contractAmountTotalClosed: formatedMoneyBRAFromFloat(parseFloat(result.data.fromDB.service.ValorFechado)),
            contractLateDate: result.data.fromDB.service.DataUltPagto,
            choosenPaymentMethod: this.state.optionsPaymentMethod.find((e) => e.value === result.data.fromDB.service.FormaPagto),
            paymentMethodDescription: result.data.fromDB.service.FormaPgtoDesc,
            serviceValueHasInstallment: (formatedMoneyBRAFromFloat(parseFloat(result.data.fromDB.service.Serv_Valor)) === formatedMoneyBRAFromFloat(formatedMoneyBRAToFloat(this.state.serviceValue))) ? formatedMoneyBRAFromFloat(parseFloat(result.data.fromDB.service.Serv_Valor)) : formatedMoneyBRAFromFloat(formatedMoneyBRAToFloat(this.state.serviceValue)),
            numberInstallments: result.data.fromDB.service.Serv_NrParcelas,
            choosenServiceStatusNegociation1: result.data.fromDB.service.idStatusEscrito,
            choosenServiceStatusNegociation2: result.data.fromDB.service.idStatusEscrito2,
            processNumber: result.data.fromDB.service.Exec_NrProcesso,
            signatureDate: result.data.fromDB.service.Data_Assinatura_Contrato,
            vehicleType: result.data.fromDB.service.Veiculo_Tipo,
            vehicleColor: result.data.fromDB.service.Veiculo_Cor,
            vehiclePlate: result.data.fromDB.service.Veiculo_Placa,
            vehicleChassis: result.data.fromDB.service.Veiculo_Chassi,
            vehicleRenavam: result.data.fromDB.service.Veiculo_Renavam,
            choosenDetranState: this.state.optionsDetranState.find((e) => e.value === result.data.fromDB.service.Detran_Estado),
            vehicleBrand: result.data.fromDB.service.Veiculo_Marca_Modelo,
            vehicleYear: result.data.fromDB.service.Veiculo_Ano_Modelo,
            bankAgency: result.data.fromDB.service.Cheque_Agencia,
            bankCheckingAccount: result.data.fromDB.service.Cheque_Conta,
            creditCardFinal: result.data.fromDB.service.Final_Cartao,
            contractAddress: result.data.fromDB.service.EnderecoCarne,
            contractAddressNumber: result.data.fromDB.service.EnderecoNumCarne,
            contractAddressComplement: result.data.fromDB.service.EnderecoComplementoCarne,
            contractZipCode: result.data.fromDB.service.EnderecoCepCarne,
            choosenContractState: result.data.fromDB.service.ENDERECO_ESTADO_CARNE,
            choosenContractCity: result.data.fromDB.service.ENDERECO_CIDADE_CARNE,
            choosenContractNeighborhood: result.data.fromDB.service.ENDERECO_BAIRRO_CARNE,
            witnessNumberOne: result.data.fromDB.service.Testemunha1,
            witnessNumberTwo: result.data.fromDB.service.Testemunha2,
            onlyFirstTimeIfServiceExists: true,
        })

        if (formatedOnlyNumber(this.state.contractZipCode).length === 8) 
        {
            await this.getContractAddressFromZipCode();
        }

        this.setState({
            contractAddress: result.data.fromDB.service.EnderecoCarne,
            choosenContractState: this.state.optionsContractState.find((e) => e.value === result.data.fromDB.service.ENDERECO_ESTADO_CARNE),
            choosenContractCity: this.state.optionsContractCity.find((e) => e.value === result.data.fromDB.service.ENDERECO_CIDADE_CARNE),
            choosenContractNeighborhood: this.state.optionsContractNeighborhood.find((e) => e.value === result.data.fromDB.service.ENDERECO_BAIRRO_CARNE),
        })

        await this.getClientById(this.state.clientId);
        await this.getProcuratorById(this.state.procuratorId);
        await this.getGuarantorById(this.state.guarantorId);
        // await this.queryEnvelope();
    }

    handleBusinessServiceChange = async () =>
    {
        const data = {...this.state};
        const dataService = {
            choosenServiceType: data.choosenServiceType?.value || 0,
        }

        const result = await serviceController.update(this.state.id, { ...dataService });
        if (!result.status || result.data.length === 0) return;
    }

    getInstallmentsAvailable = async () =>
    {
        const result = await businessController.getInstallmentsAvailable(this.props.user.PER_ID_PERMISSIONS_GROUP);
        
        if (!result.status || result.data.length === 0) return;

        let installmentsAvailable = result.data[0].BAI_AMOUNT_MAXIMUM_INSTALLMENTS;
        
        if (this.props.amountInstallments > result.data[0].BAI_AMOUNT_MAXIMUM_INSTALLMENTS)
        {
            installmentsAvailable = this.props.amountInstallments;
        }
        
        if (this.state.installmentSelected > result.data[0].BAI_AMOUNT_MAXIMUM_INSTALLMENTS)
        {
            installmentsAvailable = this.state.installmentSelected;
        }
        
        this.setState({ installmentsAvailable });
    }

    getNumberOfInstallments = async () =>
    {
        let optionsInstallments = [];

        optionsInstallments.push({
            name: `À VISTA`,
            value: 0
        });

        for (let number = 1; number <= this.state.installmentsAvailable; number++)
        {
            optionsInstallments.push({
                name: `${number}x`,
                value: number
            });
        }

        this.setState({ optionsInstallments });
    }

    positionInstallmentDate = async (date, currentInstallment) => 
    {
        const installments = this.state.choosenInstallment.value;
        let dateString = date + "T00:00:00";
        const baseDate = new Date(dateString);
        const originalDay = baseDate.getDate();
    
        for (let index = currentInstallment + 1; index <= installments; index++)
        {
            const paymentDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + (index - currentInstallment), originalDay);
    
            if (paymentDate.getDate() !== originalDay) paymentDate.setDate(0);
    
            const formattedDate = paymentDate.toISOString().split("T")[0];
            document.getElementById(`installmentDate${index}`).value = formattedDate;
        }
    }

    positionPaymentMethod = async (paymentMethod, currentInstallment) => 
    {
        const installments = this.state.choosenInstallment !== undefined ? this.state.choosenInstallment.value : 0;
    
        for (let index = currentInstallment + 1; index <= installments; index++)
        {
            await this.setState({ [`paymentMethodInstallment${index}`]: paymentMethod });
        }
    }

    updatePaymentMethod = async (index, value) =>
    {
        this.setState((prevState) => ({
            paymentMethodsByIndex: {
                ...prevState.paymentMethodsByIndex,
                [index]: value,
            },
        }));
    }

    getInstallmentsOnStorage = async () =>
    {
        const installments = this.state.numInstallments;
        const installmentsGroup = JSON.parse(localStorage.getItem('installmentsGroup')) || [];

        for (let index = 1; index <= installments; index++)
        {
            if (installmentsGroup[index - 1] !== undefined && document.getElementById(`installmentServiceValue${index}`)) 
            {
                document.getElementById(`installmentServiceValue${index}`).value = formatedMoneyBRAFromFloat(installmentsGroup[index - 1]);
            }
        }

        this.setState({ enableInstallmentsGroup: true });
    }

    checkAllbusinessAndServicesOfClient = async () =>
    {
        this.setState({ thisClientHavePendencies: false})

        const checksContractsByClient = this.state.optionsServiceExtra;
        const checksContractsWithoutAnalysis = await businessController.checkBusinessContractData(this.props.business.clientId, "withoutAnalysis");
        const checksContractsWithAnalysisNoService = await businessController.checkBusinessContractData(this.props.business.clientId, "withAnalysisNoService");

        let countChecksContractsByClient = 0;
        let countChecksContractsWithoutAnalysis = 0;
        let countChecksContractsWithAnalysisNoService = 0;

        if (checksContractsByClient)
        {
            countChecksContractsByClient = checksContractsByClient.length;
        }

        if (checksContractsWithoutAnalysis.data)
        {
            countChecksContractsWithoutAnalysis = checksContractsWithoutAnalysis.data.data[0].businessesCount;
        }

        if (checksContractsWithAnalysisNoService.data)
        {
            countChecksContractsWithAnalysisNoService = checksContractsWithAnalysisNoService.data.data[0].businessesCount;
        }

        if (countChecksContractsByClient !== 0 || countChecksContractsWithoutAnalysis !== 0 || countChecksContractsWithAnalysisNoService !== 0)
        {
            let messages = [];

            if (countChecksContractsByClient !== 0)
            {
                messages.push(`<b>[${countChecksContractsByClient}]</b> SERVIÇO(S) DESTE MESMO CLIENTE (SEM ENVELOPE)`);
            }
            
            if (countChecksContractsWithoutAnalysis !== 0)
            {
                messages.push(`<b>[${countChecksContractsWithoutAnalysis}]</b> NEGÓCIO(S) DESTE MESMO CLIENTE (SEM ANÁLISE).`);
            }
            
            if (countChecksContractsWithAnalysisNoService !== 0)
            {
                messages.push(`<b>[${countChecksContractsWithAnalysisNoService}]</b> NEGÓCIO(S) DESTE MESMO CLIENTE (COM ANÁLISE E SEM SERVIÇO).`);
            }

            let message = `<b>ENCONTRAMOS NO SISTEMA</b>:
            
            ${messages.join("\n")}
            
            ANTES DE CONTINUAR, INCLUA NESTE ENVELOPE <b>TODOS OS SERVIÇOS</b> DO MESMO CLIENTE
            (CADA ENVELOPE TEM A CAPACIDADE DE ATÉ <b>10 DOCUMENTOS</b>).
            
            *** NEGÓCIO <b>PERDIDO</b> DEVE SER TRATADO ANTES DE PROSSEGUIR.
            `;

            this.setState({ thisClientHavePendencies: true });

            return this.messageAttention("warning", message, "ATENÇÃO")
        }
        else
        {
            this.setState({ thisClientHavePendencies: false });
        }
    }
    
    setInstallments = async () => 
    {
        this.setState({ installmentElements: [] });
        this.props.updateContinueRegistrationActive(true);

        const installmentElements = [];
        const installments = this.state.numInstallments;
        
        const today = new Date();

        installmentElements.push(
            <div className={`mb-3 installmentDetailsWizard columnActivityForm d-flex align-items-center justify-content-between w-100`}>
                <div><span className="labelNumberInstallmentWizard">{`PARCELA 1`}</span><span style={{color: "#40af40", fontSize: "13px", marginLeft: "10px"}}>ENTRADA</span></div>
                <div className="d-flex align-items-center w-50 justify-content-end">
                    <InputGeneral
                        type="date"
                        id={`installmentDateEntry`}
                        classaditional="col-4 me-2"
                        defaultValue={(new Date()).toISOString().split("T")[0]}
                        min={(new Date()).toISOString().split("T")[0]}
                        max={new Date(new Date().setDate(new Date().getDate() + 5)).toISOString().split("T")[0]}
                    />
                    <InputGeneral
                        classaditional="col-4"
                        id={`installmentServiceValueEntry`}
                        valid={this.state.validMoney.toString()}
                        onChange={() => {
                            this.message("error", "Para alterar o valor da entrada você deverá ir até a análise e fazer a alteração desse valor")
                        }}
                        value={this.state.amountEntry}
                        maxLength={12}
                        mandatory="true"
                    />
                    <Select
                        classaditional="col-4"
                        default={this.state.paymentMethodInstallmentEntry}
                        options={this.state.optionsPaymentMethod}
                        onChange={async (value) => 
                        {
                            await this.positionPaymentMethod(value, 0);
                            await this.setState({ paymentMethodInstallmentEntry: value });
                            await this.setInstallments();
                        }}
                    />
                </div>
            </div>
        );
      
        for (let index = 1; index <= installments; index++) 
        {
            const paymentDate = new Date(today.getFullYear(), today.getMonth() + index, today.getDate()).toISOString().split("T")[0];

            installmentElements.push(
                <div key={index} className={`mb-3 installmentDetailsWizard columnActivityForm d-flex align-items-center justify-content-between w-100`}>
                    <div><span className="labelNumberInstallmentWizard">{`PARCELA ${index + 1}`}</span><span style={{color: "#40af40", fontSize: "13px", marginLeft: "10px"}}></span></div>
                    <div className="d-flex align-items-center w-50 justify-content-end">
                        <InputGeneral
                            type="date"
                            id={`installmentDate${index}`}
                            onChange={(e) => this.positionInstallmentDate(e.target.value, index)}
                            classaditional="col-4 me-2"
                            defaultValue={paymentDate}
                            min={paymentDate}
                        />
                        <InputGeneral
                            classaditional="col-4"
                            id={`installmentServiceValue${index}`}
                            valid={this.state.validMoney.toString()}
                            onChange={(e) => 
                            {
                                let value = e.target.value;
                                value = value.replace(/\D/g, "");
                                if (value === "") value = "0";
                                value = parseInt(value, 10).toString().padStart(3, "0");
                                const formattedValue = formatedMoneyBRA(value);
                                e.target.value = formattedValue;
                                this.calcInstallments(index);
                            }}
                            maxLength={12}
                            mandatory="true"
                        />
                        <Select
                            classaditional="col-4"
                            default={this.state[`paymentMethodInstallment${index}`]}
                            options={this.state.optionsPaymentMethod}
                            onChange={async (value) => 
                            {
                                await this.positionPaymentMethod(value, index);
                                await this.setState({ [`paymentMethodInstallment${index}`]: value });
                                await this.setInstallments();
                            }}
                        />
                    </div>
                </div>
            );
        }
        
        await this.setState({ installmentElements }, async () => 
        {
            if (this.state.setInstallmentsBySelectInstallments === true)
            {
                await this.calcInstallments();
            }
            else
            {
                await this.getInstallmentsOnStorage();
                await this.calcInstallments();
            }
        });
    };

    calcInstallments = async (currentInstallment) => 
    {
        if (this.state.hasInstallments === true) return;
        let amountService = formatedMoneyBRAToFloat(formatedMoneyBRAFromFloat(this.state.amountService));
        let amountEntry = typeof this.state.amountEntry === "number" ? this.state.amountEntry : parseFloat(this.state.amountEntry.replace(/\./g, "").replace(",", "."));
        amountService = amountService - amountEntry;

        let numInstallments = this.state.numInstallments;
        let newAmountService = 0;

        if (currentInstallment)
        {
            for (let index = 1; index <= currentInstallment; index++) 
            {
                newAmountService = newAmountService + parseFloat(document.getElementById(`installmentServiceValue${index}`).value.replace(/\./g, '').replace(',', '.'));
                numInstallments = numInstallments - 1;
            }
    
            amountService = amountService - newAmountService
        }

        let amountBaseInstallment = Math.floor((amountService / numInstallments) * 100) / 100;
        let currentTotal = amountBaseInstallment * numInstallments;
        let diff = (amountService - currentTotal).toFixed(2);
        let installments = new Array(numInstallments).fill(amountBaseInstallment);

        installments[numInstallments - 1] = formatedMoneyBRA((installments[numInstallments - 1] + parseFloat(diff)).toFixed(2));

        let indexOcasionally = (currentInstallment) ? currentInstallment : 1;
        let indexInstallmentValue = 0;
        
        for (let index = indexOcasionally; index <= this.state.installmentsAvailable; index++) 
        {
            if (document.getElementById(`installmentServiceValueEntry`))
            {
                document.getElementById(`installmentServiceValueEntry`).value = formatedMoneyBRAFromFloat(amountEntry);
            }

            if (this.state.enableInstallmentsGroup === false)
            {
                if (currentInstallment && index > currentInstallment)
                {
                    if (document.getElementById(`installmentServiceValue${index}`)) 
                    {
                        document.getElementById(`installmentServiceValue${index}`).value = formatedMoneyBRAFromFloat(installments[indexInstallmentValue]);
                        indexInstallmentValue++
                    }
                }

                if (currentInstallment == null)
                {
                    if (document.getElementById(`installmentServiceValue${index}`)) 
                    {
                        document.getElementById(`installmentServiceValue${index}`).value = formatedMoneyBRAFromFloat(installments[index - 1]);
                    }
                }
            }
        }

        if (this.state.enableInstallmentsGroup === false)
        {
            let installmentsArray = [];
            for (let index = 1; index <= this.state.installmentsAvailable; index++)
            {
                if (document.getElementById(`installmentServiceValue${index}`))
                {
                    let value = parseFloat(
                        document.getElementById(`installmentServiceValue${index}`).value.replace(/\./g, '').replace(',', '.')
                    );
                    installmentsArray.push(value);
                }
            }
    
            localStorage.setItem('installmentsGroup', JSON.stringify(installmentsArray));
        }
        
        this.setState({ enableInstallmentsGroup: false })
    }

    createInstallments = async () => 
    {
        const installments = this.state.numInstallments;
        let debts = [];
        let valueEntry = document.getElementById(`installmentServiceValueEntry`).value;

        debts.push({
            "parcela": 1,
            "vencimento": document.getElementById(`installmentDateEntry`).value,
            "valor": formatedMoneyBRAToFloat(valueEntry),
            "FormaPgto": this.state.paymentMethodInstallmentEntry.value,
        })

        for (let index = 1; index <= installments; index++)
        {
            let value = document.getElementById(`installmentServiceValue${index}`).value;

            debts.push({
                "parcela": index + 1,
                "vencimento": document.getElementById(`installmentDate${index}`).value,
                "valor": formatedMoneyBRAToFloat(value),
                "FormaPgto": this.state[`paymentMethodInstallment${index}`].value,
            })
        }

        await this.setState({ debts: debts });
        
        if ( await this.checkSteps("finance") === false) { return; }

        let result = await serviceController.createInstallments(this.state.id, debts);
        if (!result.status) { return this.message("error", "Ocorreu um erro ao gerar as parcelas!"); }

        await this.onSubmitFormService();

        document.getElementById(`installmentDateEntry`).setAttribute("disabled", true);
        document.getElementById(`installmentServiceValueEntry`).setAttribute("disabled", true);

        for (let index = 1; index <= installments; index++)
        {
            document.getElementById(`installmentDate${index}`).setAttribute("disabled", true);
            document.getElementById(`installmentServiceValue${index}`).setAttribute("disabled", true);
        }
        
        this.setState({hasInstallments: true, onlyFirstTimeIfServiceExists: true });
        await this.hasInstallments();
        return this.message("success", "Parcelas geradas com sucesso!");
    }

    deleteInstallments = async () => 
    {
        let result = await serviceController.deleteInstallments(this.state.id);
        if (!result.status) { return; }

        await this.setInstallments(); 

        if (await this.checkSteps("contract"))
        {
            await this.onSubmitFormService(); 
        }
        
        this.setState({hasInstallments: false });
        return this.message("success", "Parcelas excluidas com sucesso!");
    }

    getBusinessFromURL = async () =>
    {
        const businessId = (this.props.business.id === null) ? this.props.business.id : this.getBusinessId();
        
        if (!businessId) { return; }

        this.setState({ businessId });

        const resultBusiness = await businessController.getById(businessId);

        if (!resultBusiness.status) { return; }

        const business = resultBusiness.data;

        this.setState({
            // clientName: business.PER_NAME,
            // clientNameBusiness: business.PER_NAME,
            // installmentsAvailable: business.BAN_AMOUNT_SIMULATION_INSTALLMENTS,
            installmentSelected: business.BAN_AMOUNT_SIMULATION_INSTALLMENTS,
            clientEmail: business.PER_MAIL || "",
            clientCellPhone1: business.PER_PHONE_ONE,
            clientCellPhone2: business.PER_PHONE_TWO || "",
            busIdServiceERP: business.BUS_ID_SERVICE_ERP,
            officeId: business.UNT_ID_COMPANY_UNIT_ERP
        });
        
        await this.getInstallmentsAvailable();
        await this.getNumberOfInstallments();

        this.setState({ choosenInstallment: this.state.optionsInstallments.find((e) => e.value === this.state.installmentSelected) });

        if (business.BAN_ID)
        {
            let service = null;

            const resultService = await financialServicesController.get(this.state.officeId);

            for (const financial of resultService.data.data)
            {
                if (parseInt(business.BAN_ID_FINANCIAL_SERVICE) === financial.FIN_ID) 
                {
                    service = {
                        name: financial.FIN_NAME,
                        value: financial.FIN_ID,
                        percentRateService: parseFloat(financial.FIN_PERCENT_RATE),
                        percentFeesService: parseFloat(financial.FIN_PERCENT_FEES)
                    };
                }
            }

            const analysis = financialServicesClass.calculateCostFinancialService({
                service,
                amountFinanced: parseFloat(business.BAN_AMOUNT_FINANCED), 
                totalInstallments: parseInt(business.BAN_TOTAL_INSTALLMENTS), 
                totalInstallmentsPaid: parseInt(business.BAN_TOTAL_INSTALLMENTS_PAID), 
                amountInstallments: parseFloat(business.BAN_AMOUNT_INSTALLMENT), 
                amountSpecialFees: parseFloat(business.BAN_AMOUNT_SPECIAL_FEES),
                amountServiceByLongTimeInputValue: parseFloat(business.BAN_AMOUNT_ENTRY)
            });

            if (analysis.status === 200)
            {
                let optionsPayment = [];

                for (const installment of analysis.data.amountServiceByLongTimeInstallments)
                {
                    optionsPayment.push(
                        {
                            name: `Entrada de ${formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_ENTRY))} + ${installment.multiplier}x de ${formatedMoneyBRAFromFloat(parseFloat(installment.installment))}`,
                            value: installment.multiplier
                        }
                    );
                }

                this.setState({
                    optionsPayment,
                    analysisInstallments: analysis.data.amountServiceByLongTimeInstallments,
                });
            }

            this.setState({
                serviceValue: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_SERVICE)),
                amountEntry: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_ENTRY)),
                amountService: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_SERVICE)),
                contractNumberInstallments: business.BAN_TOTAL_INSTALLMENTS,
                contractInstallmentValue: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_INSTALLMENT)),
                contractNumberInstallmentsPaid: business.BAN_TOTAL_INSTALLMENTS_PAID,
                contractAmountFinanced: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_FINANCED)),
                contractAmountTotalPaid: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_TOTAL_PAID_FINANCING)),
                contractAmountTotalDebit: formatedMoneyBRAFromFloat(parseFloat(business.BAN_AMOUNT_NOT_PAID_FINANCING)),
                choosenFinancialInstitution: this.state.optionsFinancialInstitution.find((e) => e.value === business.BAN_ID_FINANCIAL_INSTITUTION_FROM_ERP),
            });
        }

    }

    getSelectOptions = async () => 
    {
        const result = await serviceController.getOptions();
        if (!result.status) { return; }

        await this.setState({
            optionsClientState: result.data.optionsStates,
            optionsGuarantorState: result.data.optionsStates,
            optionsContractState: result.data.optionsStates,
            optionsDetranState: result.data.optionsStatesDetran,
            optionsPaymentMethod: result.data.optionsPaymentMethod,
            optionsClientKinship: result.data.optionsKinship,
            optionsServiceType: result.data.optionsServiceType,
            optionsServiceStatusNegociation: result.data.optionsServiceStatus,
            optionsFinancialInstitution: result.data.optionsFinancialInstitution,
            choosenServiceStatusNegociation1: {
                name: "-----",
                value: 8
            },
            choosenServiceStatusNegociation2: {
                name: "-----",
                value: 8
            },
        });
    }

    hasInstallments = async () => 
    {
        let result = await serviceController.hasInstallments(this.state.id);
        
        if (!result.status)
        { 
            await this.setInstallments();
            await this.setState({hasInstallments: false });
            return;
        }

        if ( result.data.dividas !== undefined && result.data.dividas.length > 0 )
        {
            let debts = [];
            const installmentElements = [];
            const installmentsResults = result.data.dividas;
            const linkPrint = result.data.link;

            if (linkPrint !== "")
            {
                this.setState({ linkPrint });
            }
            else
            {
                this.setState({ linkPrint: "" });
            }
            
            let countInstallments = 0;
            let countInstallmentsWithRegistration = 0;

            installmentsResults.forEach(installment => 
            {
                let installmentAmount;
                if (installment.StatusPgto === 1)
                {
                    installmentAmount = formatedMoneyBRAFromFloat(parseFloat(installment.ValorPago));
                }
                else
                {
                    installmentAmount = formatedMoneyBRAFromFloat(parseFloat(installment.ValorParcela));
                }

                const installmentDate = installment.DataVencimento;
                const installmentNumber = installment.NumeroParcela;
                const statusShipment = installment.StatusRemessa;
                const statusBackgroundColor = installment.boletoRegistrado === 1 ? "#58cc02" : (statusShipment === 0 ? "#999999" : "#ffcc00");
                const statusLabel = installment.boletoRegistrado === 1 ? "Boleto Registrado" : ( statusShipment === 0 ? "Remessa não enviada" : "Remessa enviada");
                const installmentPaymentMethod = installment.FormaPgto;

                if (installment.boletoRegistrado === 1)
                {
                    countInstallmentsWithRegistration++;
                }

                let additiveImage = '';
                let additiveTitle = '';
                
                switch (installment.Boleto_IdBanco)
                {
                  case 1:
                    additiveImage = 'caixa.png';
                    additiveTitle = 'CAIXA';
                    break;
                  case 2:
                    additiveImage = 'bradesco.png';
                    additiveTitle = 'BRADESCO';
                    break;
                  case 3:
                    additiveImage = 'bancoob.png';
                    additiveTitle = 'SICOOB';
                    break;
                  case 5:
                    additiveImage = 'sicredi.png';
                    additiveTitle = 'SICREDI';
                    break;
                  case 6:
                    additiveImage = 'banrisul.png';
                    additiveTitle = 'BANRISUL';
                    break;
                  case 7:
                    additiveImage = 'brasil.png';
                    additiveTitle = 'BANCO DO BRASIL';
                    break;
                  case 8:
                    additiveImage = 'santander.png';
                    additiveTitle = 'SANTANDER';
                    break;
                  case 9:
                  case 10:
                    additiveImage = 'itau.png';
                    additiveTitle = 'ITÁU';
                    break;
                  case 11:
                    additiveImage = 'asaas.svg';
                    additiveTitle = 'ASAAS';
                    break;
                  case 12:
                    additiveImage = 'bmp.png';
                    additiveTitle = 'BMP';
                    break;
                  case 14:
                    additiveImage = '077.png';
                    additiveTitle = 'INTER';
                    break;
                  case 15:
                    additiveImage = '208.png';
                    additiveTitle = 'BTG';
                    break;
                  default:
                    break;
                }

                const imageBank = `https://sempnet.in/boletos/LOGOS-BANCOS/${additiveImage}`;

                countInstallments = installmentNumber;

                if (installmentNumber === 1)
                {
                    installmentElements.push(
                        <div key={installmentNumber} className={`mb-3 installmentDetailsWizard columnActivityForm d-flex align-items-center justify-content-between w-100`}>
                            {/* <div title="STATUS DA DÍVIDA/PARCELA" className="containerStatusPaymentWizard">
                                <span className={`statusBallPaymentWizard ${installment.StatusPgto === 1 ? "paid" : "pending"}`}></span>
                            </div> */}
                            <div className="containerInfoInstallmentsDetailsWizard"><span className="labelNumberInstallmentWizard">{`${installment.FormaPgto === 1 ? "BOLETO" : "PARCELA"} 1`}</span><span style={{color: "#40af40", fontSize: "13px", marginLeft: "10px"}}>ENTRADA</span></div>
                            <div className="containerStatusPaymentWizard">
                                <span className={`statusPaymentWizard ${installment.StatusPgto === 1 ? "paid" : "pending"}`}>
                                    {installment.StatusPgto === 1 ? "PAGO" : "PENDENTE"}
                                </span>
                            </div>
                            <a 
                                style={{
                                    pointerEvents: (installment.FormaPgto !== 1 || installment.boletoRegistrado === 2) ? "none" : "auto",
                                    color: (installment.FormaPgto !== 1 || installment.boletoRegistrado === 2) ? "transparent" : ""
                                }} 
                                title="Imprimir boleto" 
                                target="_blank" 
                                rel="noreferrer"
                                href={installment.linkSingleTicket}
                            >
                                <i className="fa fa-print"></i>
                            </a>
                            <div className="containerImageStatusShipimentWizard d-flex justify-content-end align-items-center">
                                <img  className={installment.Boleto_IdBanco !== 0 ? "statusShipmentWizard" : "d-none"} src={imageBank} alt={additiveTitle} />
                                <span className={installment.Boleto_IdBanco !== 0 ? "statusShipmentWizard" : "d-none"} title={ statusLabel } style={{ border: "1px solid #2125291f", backgroundColor: statusBackgroundColor }} ></span>
                            </div>
                            <div className="d-flex align-items-center w-50 justify-content-end">
                                <InputGeneral
                                    type="date"
                                    id={`installmentDateEntry`}
                                    classaditional="col-4 me-2"
                                    disabled
                                    defaultValue={installmentDate}
                                    min={installmentDate}
                                    max={new Date(installmentDate).toISOString().split("T")[0]}
                                />
                                <InputGeneral
                                    classaditional="col-4"
                                    id={`installmentServiceValueEntry`}
                                    valid={this.state.validMoney.toString()}
                                    disabled
                                    value={installmentAmount}
                                    maxLength={12}
                                    mandatory="true"
                                />
                                <InputGeneral
                                    classaditional="col-4"
                                    id={`installmentServicePaymentMethod${installmentNumber}`}
                                    value={this.state.optionsPaymentMethod.find((e) => e.value === installmentPaymentMethod).name}
                                    disabled
                                    maxLength={12}
                                    mandatory="true"
                                />
                            </div>
                        </div>
                    );
                }
                else
                {
                    installmentElements.push(
                        <div key={installmentNumber} className={`mb-3 installmentDetailsWizard columnActivityForm d-flex align-items-center justify-content-between w-100`}>
                            {/* <div title="STATUS DA DÍVIDA/PARCELA" className="containerStatusPaymentWizard">
                                <span className={`statusBallPaymentWizard ${installment.StatusPgto === 1 ? "paid" : "pending"}`}></span>
                            </div> */}
                            <div className="containerInfoInstallmentsDetailsWizard"><span className="labelNumberInstallmentWizard">{`${installment.FormaPgto === 1 ? "BOLETO" : "PARCELA"} ${installmentNumber}`}</span><span style={{color: "transparent", fontSize: "13px", marginLeft: "10px"}}>ENTRADA</span></div>
                            <div className="containerStatusPaymentWizard">
                                <span className={`statusPaymentWizard ${installment.StatusPgto === 1 ? "paid" : "pending"}`}>
                                    {installment.StatusPgto === 1 ? "PAGO" : "PENDENTE"}
                                </span>
                            </div>
                            <a 
                                style={{
                                    pointerEvents: (installment.FormaPgto !== 1 || installment.boletoRegistrado === 2) ? "none" : "auto",
                                    color: (installment.FormaPgto !== 1 || installment.boletoRegistrado === 2) ? "transparent" : ""
                                }} 
                                title="Imprimir boleto" 
                                target="_blank" 
                                rel="noreferrer"
                                href={installment.linkSingleTicket}
                            >
                                <i className="fa fa-print"></i>
                            </a>
                            <div className="containerImageStatusShipimentWizard d-flex justify-content-end align-items-center">
                                <img  className={installment.Boleto_IdBanco !== 0 ? "statusShipmentWizard" : "d-none"} src={imageBank} alt={additiveTitle} />
                                <span className={installment.Boleto_IdBanco !== 0 ? "statusShipmentWizard" : "d-none"} title={ statusLabel } style={{ border: "1px solid #2125291f", backgroundColor: statusBackgroundColor }} ></span>
                            </div>
                            <div className="d-flex align-items-center w-50 justify-content-end">
                                <InputGeneral
                                    type="date"
                                    id={`installmentDate${installmentNumber}`}
                                    onChange={(e) => this.positionInstallmentDate(e.target.value, installmentNumber)}
                                    classaditional="col-4 me-2"
                                    disabled
                                    defaultValue={installmentDate}
                                    min={installmentDate}
                                />
                                <InputGeneral
                                    classaditional="col-4"
                                    id={`installmentServiceValue${installmentNumber}`}
                                    valid={this.state.validMoney.toString()}
                                    value={installmentAmount}
                                    disabled
                                    maxLength={12}
                                    mandatory="true"
                                />
                                <InputGeneral
                                    classaditional="col-4"
                                    id={`installmentServicePaymentMethod${installmentNumber}`}
                                    value={this.state.optionsPaymentMethod.find((e) => e.value === installmentPaymentMethod).name}
                                    disabled
                                    maxLength={12}
                                    mandatory="true"
                                />
                            </div>
                        </div>
                    );
                }

                debts.push({
                    "parcela": installmentNumber,
                    "status": installment.StatusPgto,
                    "vencimento": installmentDate,
                    "valor": formatedMoneyBRAToFloat(installmentAmount),
                    "FormaPgto": installmentPaymentMethod,
                })
        
                this.setState({ debts: debts });
            });

            if (countInstallmentsWithRegistration > 0)
            {
                this.setState({ installmentsWithRegistration: true});
            }

            if (countInstallmentsWithRegistration !== countInstallments)
            {
                this.setState({ linkPrint: "" });
            }

            countInstallments = countInstallments - 1;

            this.setState({hasInstallments: true, numInstallments: countInstallments, choosenInstallment: this.state.optionsInstallments.find((e) => e.value === countInstallments), installmentElements})
        }
        else
        {
            this.setState({hasInstallments: false });
            await this.setInstallments();
        }
    }

    getClientByNationalRegistration = async (nationalRegistration) =>
    {
        nationalRegistration = formatedOnlyNumber(nationalRegistration);
        this.setState({ clientRegistered: false });

        const result = await serviceController.getClientByNationalRegistration(nationalRegistration);
        if (!result.status || !result?.data)
        { 
            await this.clearFieldsClient("CLIENT", true);
            return;
        }

        this.setState({clientRegistered: true, isPhoneValidated: true});
        await this.getClientById(result.data.id);
    }

    getProcuratorByNationalRegistration = async (nationalRegistration) =>
    {
        nationalRegistration = formatedOnlyNumber(nationalRegistration);
        this.setState({ procuratorRegistered: false });

        const result = await serviceController.getClientByNationalRegistration(nationalRegistration);
        if (!result.status || !result?.data) 
        { 
            await this.clearFieldsClient("PROCURATOR", true);
            return;
        }

        this.setState({procuratorRegistered: true, isPhoneValidated: true});
        await this.getProcuratorById(result.data.id);
    }

    getGuarantorByNationalRegistration = async (nationalRegistration) =>
    {
        nationalRegistration = formatedOnlyNumber(nationalRegistration);
        this.setState({ guarantorRegistered: false });

        const result = await serviceController.getClientByNationalRegistration(nationalRegistration);
        if (!result.status || !result?.data)
        { 
            await this.clearFieldsClient("GUARANTOR", true);
            return;
        }

        this.setState({guarantorRegistered: (this.state.guarantorIsClient) ? false : true , isPhoneValidated: true});
        await this.getGuarantorById(result.data.id);
    }

    getProcurators = async () =>
    {
        const clientId = this.state.clientId;
        const guarantorId = this.state.guarantorId;

        const result = await serviceController.getProcurators(clientId, guarantorId);
        if (!result.status || !result?.data) { return; }
        
        await this.setState({optionsProcurator: result.data.data});
    }

    queryProcuratorDocument = async () =>
    {
        const serviceId = this.state.id;

        const result = await serviceController.queryProcuratorDocument(serviceId);
        if (!result.status || !result?.data) { return; }
        
        this.setState({ hasProcuratorDocument: result.data.data.statusDocument, procuratorDocumentStatusMessage: result.data.message });
    }

    file = () => {
        document.getElementById('fileWizard').click();
        document.getElementById('fileWizard').onchange = () => {
            let fileInput = document.getElementById('fileWizard');
            let file = fileInput.files[0].name;
            this.setState({file, filePath: fileInput.files[0] });
        }
    }

    handleFileUpload = async (file) => {
        await this.setState({ filePath: file }, () => {
            this.sendProcuratorDocument();
        });

    };

    sendProcuratorDocument = async () => {
        const formData = new FormData();
        formData.append('attachmentId', 18);
        formData.append('serviceId', this.state.id);
        formData.append('userId', this.props.user.PER_ID_USER_ERP);
        formData.append('FiledataExtra', this.state.filePath);
    
        const result = await serviceController.sendAttachmantService(formData);
        if (!result.status || !result?.data) { return; }

        this.message("success", "O arquivo foi anexado com sucesso!");

        setTimeout(() => {
            this.queryProcuratorDocument();
            this.message("", "", false);
        }, 1250);

    };

    getClientById = async (id) =>
    {
        if (id === 0 || id === null) { return; }
        const result = await serviceController.getClientById(id);
        if (!result.status || !result?.data) { return; }
        
        if (result.data[0].PS_CEL4 !== null && result.data[0].PS_CEL4 !== "") this.addFieldContact("CLIENT");
        if (result.data[0].PS_CEL5 !== null && result.data[0].PS_CEL5 !== "") this.addFieldContact("CLIENT");
        if (result.data[0].PS_CEL6 !== null && result.data[0].PS_CEL6 !== "") this.addFieldContact("CLIENT");
        if (result.data[0].PS_CEL7 !== null && result.data[0].PS_CEL7 !== "") this.addFieldContact("CLIENT");
        if (result.data[0].PS_CEL8 !== null && result.data[0].PS_CEL8 !== "") this.addFieldContact("CLIENT");

        await this.setState({
            clientId: result.data[0].PS_ID,
            clientName: result.data[0].PS_NOME,
            clientEmail: result.data[0].PS_EMAIL,
            clientCPFCNPJ: result.data[0].PS_CPF,
            clientZipCode: result.data[0].PS_CEP,
            clientJob: result.data[0].PS_PROFISSAO,
            clientFatherName: result.data[0].PS_PAI,
            clientMotherName: result.data[0].PS_MAE,
            clientSpouse: result.data[0].PS_CONJUGE_NOME,
            clientCpfSpouse: maskCPF(result.data[0].PS_CONJUGE_CPF),
            clientCellPhone1: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL1),
            clientCellPhone1ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC1),
            clientCellPhone1ContactName: result.data[0].PS_CEL1_info,
            clientCellPhone2: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL2),
            clientCellPhone2ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC2),
            clientCellPhone2ContactName: result.data[0].PS_CEL2_info,
            clientCellPhone3: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL3),
            clientCellPhone3ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC3),
            clientCellPhone3ContactName: result.data[0].PS_CEL3_info,
            clientCellPhone4: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL4),
            clientCellPhone4ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC4),
            clientCellPhone4ContactName: result.data[0].PS_CEL4_info,
            clientCellPhone5: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL5),
            clientCellPhone5ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC5),
            clientCellPhone5ContactName: result.data[0].PS_CEL5_info,
            clientCellPhone6: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL6),
            clientCellPhone6ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC6),
            clientCellPhone6ContactName: result.data[0].PS_CEL6_info,
            clientCellPhone7: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL7),
            clientCellPhone7ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC7),
            clientCellPhone7ContactName: result.data[0].PS_CEL7_info,
            clientCellPhone8: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL8),
            clientCellPhone8ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC8),
            clientCellPhone8ContactName: result.data[0].PS_CEL8_info,
            clientIssuingInstitution: result.data[0].PS_EMISSOR_ID,
            clientRG: result.data[0].PS_IDENTIDADE,
            choosenClientGender: this.state.optionsClientGender.find((e) => e.value === result.data[0].PS_SEXO),
            clientBirthday: result.data[0].PS_NASCIMENTO,
            clientAddressNumber: result.data[0].PS_END_NUM,
            clientNaturalness: result.data[0].PS_NATURALIDADE,
            clientNationality: result.data[0].PS_NACIONALIDADE,
            clientAddressComplement: result.data[0].PS_COMPLEMENTO,
            choosenClientMaritalStatus: this.state.optionsClientMaritalStatus.find((e) => e.value === result.data[0].PS_ESTADO_CIVIL),
        });

        if (result.data[0].PS_CEP !== null) await this.getClientAddressFromZipCode();

        this.setState({
            clientAddress: result.data[0].PS_ENDERECO,
            choosenClientState: this.state.optionsClientState.find((e) => e.value === result.data[0].PS_ID_ESTADO),
            choosenClientCity: this.state.optionsClientCity.find((e) => e.value === result.data[0].PS_ID_CIDADE),
            choosenClientNeighborhood: this.state.optionsClientNeighborhood.find((e) => e.value === result.data[0].PS_ID_BAIRRO),
            bondsType: 1
        })

        this.setState({ dataEnvelope: {...this.state.dataEnvelope, clientCellPhone1: this.state.clientCellPhone1, serviceId: this.state.id, hasInstallments: this.state.hasInstallments, clientName: this.state.clientName, clientId: this.state.clientId }});

        await this.queryPhoneWhatsapp();
    }

    getProcuratorById = async (id) =>
    {
        if (id === 0 || id === null) { return; }
        const result = await serviceController.getClientById(id);
        if (!result.status || !result?.data) { return; }
        
        if (result.data[0].PS_CEL4 !== null && result.data[0].PS_CEL4 !== "") this.addFieldContact("PROCURATOR");
        if (result.data[0].PS_CEL5 !== null && result.data[0].PS_CEL5 !== "") this.addFieldContact("PROCURATOR");
        if (result.data[0].PS_CEL6 !== null && result.data[0].PS_CEL6 !== "") this.addFieldContact("PROCURATOR");
        if (result.data[0].PS_CEL7 !== null && result.data[0].PS_CEL7 !== "") this.addFieldContact("PROCURATOR");
        if (result.data[0].PS_CEL8 !== null && result.data[0].PS_CEL8 !== "") this.addFieldContact("PROCURATOR");
        
        await this.setState({
            procuratorId: result.data[0].PS_ID,
            procuratorName: result.data[0].PS_NOME,
            procuratorEmail: result.data[0].PS_EMAIL,
            procuratorCPFCNPJ: result.data[0].PS_CPF,
            procuratorZipCode: result.data[0].PS_CEP,
            procuratorJob: result.data[0].PS_PROFISSAO,
            procuratorFatherName: result.data[0].PS_PAI,
            procuratorMotherName: result.data[0].PS_MAE,
            procuratorSpouse: result.data[0].PS_CONJUGE_NOME,
            procuratorCpfSpouse: maskCPF(result.data[0].PS_CONJUGE_CPF),
            procuratorCellPhone1: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL1),
            procuratorCellPhone1ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC1),
            procuratorCellPhone1ContactName: result.data[0].PS_CEL1_info,
            procuratorCellPhone2: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL2),
            procuratorCellPhone2ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC2),
            procuratorCellPhone2ContactName: result.data[0].PS_CEL2_info,
            procuratorCellPhone3: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL3),
            procuratorCellPhone3ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC3),
            procuratorCellPhone3ContactName: result.data[0].PS_CEL3_info,
            procuratorCellPhone4: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL4),
            procuratorCellPhone4ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC4),
            procuratorCellPhone4ContactName: result.data[0].PS_CEL4_info,
            procuratorCellPhone5: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL5),
            procuratorCellPhone5ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC5),
            procuratorCellPhone5ContactName: result.data[0].PS_CEL5_info,
            procuratorCellPhone6: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL6),
            procuratorCellPhone6ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC6),
            procuratorCellPhone6ContactName: result.data[0].PS_CEL6_info,
            procuratorCellPhone7: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL7),
            procuratorCellPhone7ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC7),
            procuratorCellPhone7ContactName: result.data[0].PS_CEL7_info,
            procuratorCellPhone8: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL8),
            procuratorCellPhone8ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC8),
            procuratorCellPhone8ContactName: result.data[0].PS_CEL8_info,
            procuratorIssuingInstitution: result.data[0].PS_EMISSOR_ID,
            procuratorRG: result.data[0].PS_IDENTIDADE,
            choosenProcuratorGender: this.state.optionsProcuratorGender.find((e) => e.value === result.data[0].PS_SEXO),
            procuratorBirthday: result.data[0].PS_NASCIMENTO,
            procuratorAddressNumber: result.data[0].PS_END_NUM,
            procuratorNaturalness: result.data[0].PS_NATURALIDADE,
            procuratorNationality: result.data[0].PS_NACIONALIDADE,
            procuratorAddressComplement: result.data[0].PS_COMPLEMENTO,
            choosenProcuratorMaritalStatus: this.state.optionsClientMaritalStatus.find((e) => e.value === result.data[0].PS_ESTADO_CIVIL),
        });

        if (result.data[0].PS_CEP !== null) await this.getProcuratorAddressFromZipCode();

        this.setState({
            procuratorAddress: result.data[0].PS_ENDERECO,
            choosenProcuratorState: this.state.optionsClientState.find((e) => e.value === result.data[0].PS_ID_ESTADO),
            choosenProcuratorCity: this.state.optionsClientCity.find((e) => e.value === result.data[0].PS_ID_CIDADE),
            choosenProcuratorNeighborhood: this.state.optionsClientNeighborhood.find((e) => e.value === result.data[0].PS_ID_BAIRRO),
            bondsType: 2
        })
        
        await this.queryPhoneWhatsapp();
    }

    getGuarantorById = async (id) =>
    {
        if (id === 0 || id === null) { return; }
        const result = await serviceController.getClientById(id);
        if (!result.status || !result?.data) { return; }
        
        if (result.data[0].PS_CEL4 !== null && result.data[0].PS_CEL4 !== "") this.addFieldContact("GUARANTOR");
        if (result.data[0].PS_CEL5 !== null && result.data[0].PS_CEL5 !== "") this.addFieldContact("GUARANTOR");
        if (result.data[0].PS_CEL6 !== null && result.data[0].PS_CEL6 !== "") this.addFieldContact("GUARANTOR");
        if (result.data[0].PS_CEL7 !== null && result.data[0].PS_CEL7 !== "") this.addFieldContact("GUARANTOR");
        if (result.data[0].PS_CEL8 !== null && result.data[0].PS_CEL8 !== "") this.addFieldContact("GUARANTOR");

        await this.setState({
            guarantorId: result.data[0].PS_ID,
            guarantorName: result.data[0].PS_NOME,
            guarantorEmail: result.data[0].PS_EMAIL,
            guarantorCPFCNPJ: result.data[0].PS_CPF,
            guarantorZipCode: result.data[0].PS_CEP,
            guarantorJob: result.data[0].PS_PROFISSAO,
            guarantorFatherName: result.data[0].PS_PAI,
            guarantorMotherName: result.data[0].PS_MAE,
            guarantorSpouse: result.data[0].PS_CONJUGE_NOME,
            guarantorCpfSpouse: maskCPF(result.data[0].PS_CONJUGE_CPF),
            guarantorCellPhone1: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL1),
            guarantorCellPhone1ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC1),
            guarantorCellPhone1ContactName: result.data[0].PS_CEL1_info,
            guarantorCellPhone2: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL2),
            guarantorCellPhone2ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC2),
            guarantorCellPhone2ContactName: result.data[0].PS_CEL2_info,
            guarantorCellPhone3: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL3),
            guarantorCellPhone3ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC3),
            guarantorCellPhone3ContactName: result.data[0].PS_CEL3_info,
            guarantorCellPhone4: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL4),
            guarantorCellPhone4ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC4),
            guarantorCellPhone4ContactName: result.data[0].PS_CEL4_info,
            guarantorCellPhone5: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL5),
            guarantorCellPhone5ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC5),
            guarantorCellPhone5ContactName: result.data[0].PS_CEL5_info,
            guarantorCellPhone6: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL6),
            guarantorCellPhone6ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC6),
            guarantorCellPhone6ContactName: result.data[0].PS_CEL6_info,
            guarantorCellPhone7: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL7),
            guarantorCellPhone7ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC7),
            guarantorCellPhone7ContactName: result.data[0].PS_CEL7_info,
            guarantorCellPhone8: formatedPhoneAndCellphoneWithSpace(result.data[0].PS_CEL8),
            guarantorCellPhone8ChoosenKinship: this.state.optionsClientKinship.find((e) => e.value === result.data[0].idParenteC8),
            guarantorCellPhone8ContactName: result.data[0].PS_CEL8_info,
            guarantorIssuingInstitution: result.data[0].PS_EMISSOR_ID,
            guarantorRG: result.data[0].PS_IDENTIDADE,
            choosenGuarantorGender: this.state.optionsGuarantorGender.find((e) => e.value === result.data[0].PS_SEXO),
            guarantorBirthday: result.data[0].PS_NASCIMENTO,
            guarantorAddressNumber: result.data[0].PS_END_NUM,
            guarantorNaturalness: result.data[0].PS_NATURALIDADE,
            guarantorNationality: result.data[0].PS_NACIONALIDADE,
            guarantorAddressComplement: result.data[0].PS_COMPLEMENTO,
            choosenGuarantorMaritalStatus: this.state.optionsClientMaritalStatus.find((e) => e.value === result.data[0].PS_ESTADO_CIVIL),
        });

        if (result.data[0].PS_CEP !== null) await this.getGuarantorAddressFromZipCode();

        this.setState({
            guarantorAddress: result.data[0].PS_ENDERECO,
            choosenGuarantorState: this.state.optionsClientState.find((e) => e.value === result.data[0].PS_ID_ESTADO),
            choosenGuarantorCity: this.state.optionsClientCity.find((e) => e.value === result.data[0].PS_ID_CIDADE),
            choosenGuarantorNeighborhood: this.state.optionsClientNeighborhood.find((e) => e.value === result.data[0].PS_ID_BAIRRO),
            bondsType: 3
        })

        await this.queryPhoneWhatsapp();
    }
    
    clearFieldsClient = async (type, isNew = false) =>
    {
        if (type === "CLIENT")
        {
            await this.setState({
                isPhoneValidated: false,
                clientId: null,
                clientName: isNew ? this.state.clientNameBusiness : "",
                clientEmail: "",
                clientZipCode: "",
                clientJob: "",
                clientFatherName: "",
                clientMotherName: "",
                clientCellPhone1: "",
                clientSpouse: "",
                clientCpfSpouse: null,
                clientCellPhone1ChoosenKinship: null,
                clientCellPhone1ContactName: "",
                clientCellPhone2: "",
                clientCellPhone2ChoosenKinship: null,
                clientCellPhone2ContactName: "",
                clientCellPhone3: "",
                clientCellPhone3ChoosenKinship: null,
                clientCellPhone3ContactName: "",
                clientCellPhone4: "",
                clientCellPhone4ChoosenKinship: null,
                clientCellPhone4ContactName: "",
                clientCellPhone5: "",
                clientCellPhone5ChoosenKinship: null,
                clientCellPhone5ContactName: "",
                clientCellPhone6: "",
                clientCellPhone6ChoosenKinship: null,
                clientCellPhone6ContactName: "",
                clientCellPhone7: "",
                clientCellPhone7ChoosenKinship: null,
                clientCellPhone7ContactName: "",
                clientCellPhone8: "",
                clientCellPhone8ChoosenKinship: null,
                clientCellPhone8ContactName: "",
                clientAddress: "",
                clientIssuingInstitution: "",
                clientRG: "",
                choosenClientGender: null,
                clientBirthday: "",
                clientAddressNumber: "",
                clientNaturalness: "",
                clientNationality: "",
                clientAddressComplement: "",
                choosenClientMaritalStatus: null,
                choosenClientState: null,
                optionsClientCity: [],
                choosenClientCity: null,
                optionsClientNeighborhood: [],
                choosenClientNeighborhood: null
            });
        }
        else if (type === "GUARANTOR")
        {
            await this.setState({
                isPhoneValidated: false,
                guarantorId: null,
                guarantorName: "",
                guarantorEmail: "",
                guarantorZipCode: "",
                guarantorJob: "",
                guarantorFatherName: "",
                guarantorMotherName: "",
                guarantorSpouse: "",
                guarantorCpfSpouse: null,
                guarantorCellPhone1: "",
                guarantorCellPhone1ChoosenKinship: null,
                guarantorCellPhone1ContactName: "",
                guarantorCellPhone2: "",
                guarantorCellPhone2ChoosenKinship: null,
                guarantorCellPhone2ContactName: "",
                guarantorCellPhone3: "",
                guarantorCellPhone3ChoosenKinship: null,
                guarantorCellPhone3ContactName: "",
                guarantorCellPhone4: "",
                guarantorCellPhone4ChoosenKinship: null,
                guarantorCellPhone4ContactName: "",
                guarantorCellPhone5: "",
                guarantorCellPhone5ChoosenKinship: null,
                guarantorCellPhone5ContactName: "",
                guarantorCellPhone6: "",
                guarantorCellPhone6ChoosenKinship: null,
                guarantorCellPhone6ContactName: "",
                guarantorCellPhone7: "",
                guarantorCellPhone7ChoosenKinship: null,
                guarantorCellPhone7ContactName: "",
                guarantorCellPhone8: "",
                guarantorCellPhone8ChoosenKinship: null,
                guarantorCellPhone8ContactName: "",
                guarantorAddress: "",
                guarantorIssuingInstitution: "",
                guarantorRG: "",
                choosenGuarantorGender: null,
                guarantorBirthday: "",
                guarantorAddressNumber: "",
                guarantorNaturalness: "",
                guarantorNationality: "",
                guarantorAddressComplement: "",
                choosenGuarantorMaritalStatus: null,
                choosenGuarantorState: null,
                optionsGuarantorCity: [],
                choosenGuarantorCity: null,
                optionsGuarantorNeighborhood: [],
                choosenGuarantorNeighborhood: null
            });
        }
        else if (type === "PROCURATOR")
        {
            await this.setState({
                isPhoneValidated: false,
                procuratorId: null,
                procuratorName: "",
                procuratorEmail: "",
                procuratorZipCode: "",
                procuratorJob: "",
                procuratorFatherName: "",
                procuratorMotherName: "",
                procuratorSpouse: "",
                procuratorCpfSpouse: null,
                procuratorCellPhone1: "",
                procuratorCellPhone1ChoosenKinship: null,
                procuratorCellPhone1ContactName: "",
                procuratorCellPhone2: "",
                procuratorCellPhone2ChoosenKinship: null,
                procuratorCellPhone2ContactName: "",
                procuratorCellPhone3: "",
                procuratorCellPhone3ChoosenKinship: null,
                procuratorCellPhone3ContactName: "",
                procuratorCellPhone4: "",
                procuratorCellPhone4ChoosenKinship: null,
                procuratorCellPhone4ContactName: "",
                procuratorCellPhone5: "",
                procuratorCellPhone5ChoosenKinship: null,
                procuratorCellPhone5ContactName: "",
                procuratorCellPhone6: "",
                procuratorCellPhone6ChoosenKinship: null,
                procuratorCellPhone6ContactName: "",
                procuratorCellPhone7: "",
                procuratorCellPhone7ChoosenKinship: null,
                procuratorCellPhone7ContactName: "",
                procuratorCellPhone8: "",
                procuratorCellPhone8ChoosenKinship: null,
                procuratorCellPhone8ContactName: "",
                procuratorAddress: "",
                procuratorIssuingInstitution: "",
                procuratorRG: "",
                choosenProcuratorGender: null,
                procuratorBirthday: "",
                procuratorAddressNumber: "",
                procuratorNaturalness: "",
                procuratorNationality: "",
                procuratorAddressComplement: "",
                choosenProcuratorMaritalStatus: null,
                choosenProcuratorState: null,
                optionsProcuratorCity: [],
                choosenProcuratorCity: null,
                optionsProcuratorNeighborhood: [],
                choosenProcuratorNeighborhood: null
            });
        }
    }

    getCitiesFromState = async (state) =>
    {
        const result = await serviceController.getCitiesFromState(state);
        if (!result.status) { return []; }

        return result.data;
    }

    getNeighborhoodFromCity = async (city) =>
    {
        const result = await serviceController.getNeighborhoodFromCity(city);
        if (!result.status) { return []; }

        return result.data;
    }

    getClientAddressFromZipCode = async () => {
        const zipCode = this.state.clientZipCode;
    
        const requestId = Date.now();
        this.latestRequestId = requestId;
    
        const result = await this.apiViaCep(zipCode);
        if (!result || result?.erro)
        {
            this.setState({
                choosenClientState: null,
                optionsClientCity: [],
                choosenClientCity: null,
                optionsClientNeighborhood: [],
                choosenClientNeighborhood: null,
            });
            this.message("error", "Não foi possível obter o endereço com o CEP informado");
            return;
        }

        this.setState({
            clientZipCode: result.cep,
            clientAddress: result.logradouro.toUpperCase(),
        });

        const resultSelect = await serviceController.getAddressOptionsFromText(result.uf, result.localidade, result.bairro);
        if (!resultSelect.status)
        {
            return;
        }
        
        const data = resultSelect.data;

        if (this.latestRequestId !== requestId)
        {
            console.warn("Resposta ignorada, pois uma nova requisição foi feita.");
            return;
        }

        // console.log("✅ Atualizando estado com:", data.optionsNeighborhood);
    
        this.setState(prevState => ({
            ...prevState,
            choosenClientState: data.choosenState,
            optionsClientCity: data.optionsCity,
            choosenClientCity: data.choosenCity,
            optionsClientNeighborhood: data.optionsNeighborhood,
            choosenClientNeighborhood: data.choosenNeighborhood,
        }));
    
        setTimeout(() => {
            // console.log("📌 Estado atualizado após 2s:", this.state.optionsClientNeighborhood);
        }, 2000);
    }

    getProcuratorAddressFromZipCode = async () =>
    {
        const zipCode = this.state.procuratorZipCode;

        const result = await this.apiViaCep(zipCode);
        if (!result || result?.erro) 
        {
            this.setState({
                choosenProcuratorState: null,
                optionsProcuratorCity: [],
                choosenProcuratorCity: null,
                optionsProcuratorNeighborhood: [],
                choosenProcuratorNeighborhood: null,
            });
            this.message("error", "Não foi possível obter o endereço com o CEP informado");
            
            return;
        }

        this.setState({
            procuratorZipCode: result.cep,
            procuratorAddress: result.logradouro.toUpperCase(),
        });

        const resultSelect = await serviceController.getAddressOptionsFromText(result.uf, result.localidade, result.bairro);
        if (!resultSelect.status) { return; }
        
        const data = resultSelect.data;

        this.setState({
            choosenProcuratorState: data.choosenState,
            optionsProcuratorCity: data.optionsCity,
            choosenProcuratorCity: data.choosenCity,
            optionsProcuratorNeighborhood: data.optionsNeighborhood,
            choosenProcuratorNeighborhood: data.choosenNeighborhood,
        });
    }

    getGuarantorAddressFromZipCode = async () =>
    {
        const zipCode = this.state.guarantorZipCode;

        const result = await this.apiViaCep(zipCode);
        if (!result || result?.erro) 
        {
            this.setState({
                choosenGuarantorState: null,
                optionsGuarantorCity: [],
                choosenGuarantorCity: null,
                optionsGuarantorNeighborhood: [],
                choosenGuarantorNeighborhood: null,
            });
            this.message("error", "Não foi possível obter o endereço com o CEP informado");

            return;
        }

        this.setState({
            guarantorZipCode: result.cep,
            guarantorAddress: result.logradouro.toUpperCase(),
        });

        const resultSelect = await serviceController.getAddressOptionsFromText(result.uf, result.localidade, result.bairro);
        if (!resultSelect.status) { return; }
        
        const data = resultSelect.data;

        this.setState({
            choosenGuarantorState: data.choosenState,
            optionsGuarantorCity: data.optionsCity,
            choosenGuarantorCity: data.choosenCity,
            optionsGuarantorNeighborhood: data.optionsNeighborhood,
            choosenGuarantorNeighborhood: data.choosenNeighborhood,
        });
    }

    getContractAddressFromZipCode = async () =>
    {
        const zipCode = this.state.contractZipCode;

        const result = await this.apiViaCep(zipCode);
        if (!result || result?.erro) 
        {
            this.setState({
                choosenContractState: null,
                optionsContractCity: [],
                choosenContractCity: null,
                optionsContractNeighborhood: [],
                choosenContractNeighborhood: null,
            });
            this.message("error", "Não foi possível obter o endereço com o CEP informado");

            return;
        }

        this.setState({
            contractZipCode: result.cep,
            contractAddress: result.logradouro.toUpperCase(),
        });

        const resultSelect = await serviceController.getAddressOptionsFromText(result.uf, result.localidade, result.bairro);
        if (!resultSelect.status) { return; }
        
        const data = resultSelect.data;

        this.setState({
            choosenContractState: data.choosenState,
            optionsContractCity: data.optionsCity,
            choosenContractCity: data.choosenCity,
            optionsContractNeighborhood: data.optionsNeighborhood,
            choosenContractNeighborhood: data.choosenNeighborhood,
        });
    }

    getBusinessId = () => 
    {
        const url = window.location.href;
        const regex = /business\/details\/(\d+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    apiViaCep = async (zipCode) =>
    {
        const result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
        .then(data => data.json())
        .then(response => 
        {
            return response;
        })
        .catch(error => 
        {
            return error;
        });
        
        return result;
    }

    addFieldContact = async (prefix, reload) => 
    {
        let amountFieldsContact = this.state[`fields${capitalize(prefix)}Contact`];
        if (amountFieldsContact < 8) 
        {
            amountFieldsContact = reload ? amountFieldsContact : amountFieldsContact + 1;
            this.setState({
                [`fields${capitalize(prefix)}Contact`]: amountFieldsContact,
                fieldsContactElementsClient: [],
                fieldsContactElementsProcurator: [],
                fieldsContactElementsGuarantor: []
            });

            let fieldsContactElements = [];

            for (let index = 4; index <= amountFieldsContact; index++) 
            {
                if (prefix === "CLIENT")
                {
                    fieldsContactElements.push(
                        <div className="row mb-3" key={index}>
                            <div className="col-12 col-md-4">
                            <InputGeneral
                                classaditional="modernFieldInputGeneral"
                                label={`Celular ${index}`}
                                value={this.state[`clientCellPhone${index}`] || ''}
                                onChange={e => {
                                    const formattedValue = formatedPhoneAndCellphoneWithSpace(e.target.value);
                                    this.setState({ [`clientCellPhone${index}`]: formattedValue }, () => {this.addFieldContact(prefix, true)});
                                }}
                            />
                            </div> 
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    defaultValue={this.state[`clientCellPhone${index}ContactName`]}
                                    onChange={e => this.setState({ [`clientCellPhone${index}ContactName`]: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral"
                                    label="De Quem é?"
                                    default={this.state[`clientCellPhone${index}ChoosenKinship`]}
                                    options={this.state.optionsClientKinship}
                                    onChange={(value) => 
                                    {
                                        this.setState(
                                            { [`${prefix.toLowerCase()}CellPhone${index}ChoosenKinship`]: value },
                                            () => this.addFieldContact(prefix, true)
                                        );
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                    );
                }

                if (prefix === "GUARANTOR")
                {
                    fieldsContactElements.push(
                        <div className="row mb-3" key={index}>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label={`Celular ${index}`}
                                    value={this.state[`guarantorCellPhone${index}`]}
                                    onChange={e => {
                                        const formattedValue = formatedPhoneAndCellphoneWithSpace(e.target.value);
                                        this.setState({ [`guarantorCellPhone${index}`]: formattedValue }, () => {this.addFieldContact(prefix, true)});
                                    }}
                                />
                            </div> 
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    defaultValue={this.state[`guarantorCellPhone${index}ContactName`]}
                                    onChange={e => this.setState({ [`guarantorCellPhone${index}ContactName`]: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral"
                                    label="De Quem é?"
                                    default={this.state[`guarantorCellPhone${index}ChoosenKinship`]}
                                    options={this.state.optionsClientKinship}
                                    onChange={(value) => 
                                    {
                                        this.setState(
                                            { [`${prefix.toLowerCase()}CellPhone${index}ChoosenKinship`]: value },
                                            () => this.addFieldContact(prefix, true)
                                        );
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                    );
                }

                if (prefix === "PROCURATOR")
                {
                    fieldsContactElements.push(
                        <div className="row mb-3" key={index}>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label={`Celular ${index}`}
                                    value={this.state[`procuratorCellPhone${index}`]}
                                    onChange={e => {
                                        const formattedValue = formatedPhoneAndCellphoneWithSpace(e.target.value);
                                        this.setState({ [`procuratorCellPhone${index}`]: formattedValue }, () => {this.addFieldContact(prefix, true)});
                                    }}
                                />
                            </div> 
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    defaultValue={this.state[`procuratorCellPhone${index}ContactName`]}
                                    onChange={e => this.setState({ [`procuratorCellPhone${index}ContactName`]: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral"
                                    label="De Quem é?"
                                    default={this.state[`procuratorCellPhone${index}ChoosenKinship`]}
                                    options={this.state.optionsClientKinship}
                                    onChange={async (value) => 
                                    {
                                        this.setState(
                                            { [`${prefix.toLowerCase()}CellPhone${index}ChoosenKinship`]: value },
                                            () => this.addFieldContact(prefix, true)
                                        );
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                    );
                }
            }
            
            if (prefix === "CLIENT") {this.setState({ fieldsContactElementsClient: fieldsContactElements });}
            if (prefix === "GUARANTOR") {this.setState({ fieldsContactElementsGuarantor: fieldsContactElements });}
            if (prefix === "PROCURATOR") {this.setState({ fieldsContactElementsProcurator: fieldsContactElements });}
        }
    };

    sendCodeMessageForValidation = async (contact, phone, code) => 
    {
        const data = {
            idContact: contact,
            phone: phone,
            message: `Olá, a empresa deseja fazer a verificação do seu número de WhatsApp ${phone}. Para isso informe o código ${code} ao Especialista que está lhe atendendo.`
        };

        const url = "https://webhook.spnet.cc/whatsapp/send/0e631de9-e5da-4a5b-804a-8399a8463246";
        
        await axios.post(url, JSON.stringify(data))
        .then((response) => 
        {
            this.message("success", "Mensagem enviado com sucesso");
            this.setState({ validationCode: code});
        })
        .catch((error) => 
        {
            this.message("error", "Ocorreu um erro ao enviar mensagem");
        });
    }

    queryValidationCode = async () => 
    {
        let data = {};
        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "clientID": this.state.clientId,
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                }
                break;

            case 2:
                data = {
                    "clientID": this.state.procuratorId,
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                }
                break;
                
            case 3:
                data = {
                    "clientID": this.state.guarantorId,
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                }
                break;

            default:
                break;
        }

        const result = await serviceController.queryValidationCode(data);
        if (!result.status || !result?.data) { return; }

        if (result.data.message === "Registro não encontrado")
        {
            return false;
        }
        else
        {
            return result.data.data;
        }
    }
    
    validatePhone = async (resend = 0) => 
    {
        let data = {};

        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.clientId,
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                    "resend": resend,
                }
                break;

            case 2:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.procuratorId,
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                    "resend": resend,
                }
                break;
                
            case 3:
                data = {
                    "officeId": this.state.officeId,
                    "clientId": this.state.guarantorId,
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                    "resend": resend,
                }
                break;

            default:
                break;
        }

        let result = await serviceController.createValidationCode(data);

        if (result.data.data.status === "400")
        {
            this.message("warning", result.data.data.message);
        }

        this.setState({
            phoneId: result.data.data.idTelefone,
            statusValidationCode: 1,
            showValidationButton: false,
            showValidationCodeField: true,
            statusConfirmation: true,
            message_confirmation: "",
            message_show_confirmation: true,
            message_type_confirmation: ""
        });

        this.queryPhoneWhatsapp();
    }

    queryPhoneWhatsapp = async () => 
    {
        let data = {};
        await this.setState({showValidationButton: true });
        switch (this.state.bondsType)
        {
            case 1:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1),
                }
                break;

            case 2:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1),
                }
                break;
                
            case 3:
                data = {
                    "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1),
                }
                break;

            default:
                break;
        }

        let result = await serviceController.queryPhoneWhatsapp(data);

        if (result.data.data.status === "400")
        {
            switch (this.state.bondsType)
            {
                case 1:
                    await this.setState({validatedContactClientIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactClientStatus: null,
                        validatedContactClient: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
    
                case 2:
                    await this.setState({validatedContactProcuratorIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactProcuratorStatus: null,
                        validatedContactProcurator: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
                    
                case 3:
                    await this.setState({validatedContactGuarantorIcon: "fa fa-exclamation-triangle"});
                    await this.setState({
                        validatedContactGuarantorStatus: null,
                        validatedContactGuarantor: false,
                        checkVerifyWhatsapp: false,
                        phoneId: null,
                    });
                    break;
                    
                default:
                    break;
            }

            await this.setState({showValidationCodeField: false, showValidationButton: true })
        }
        else if (result.data.data.status === "200")
        {
            await this.setState({showValidationCodeField: true, showValidationButton: false })

            switch (this.state.bondsType)
            {
                case 1:
                    let validatedContactClientIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactClientIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactClientIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactClientIcon = "fa fa-exclamation-triangle"
                    }

                    await this.setState({
                        validatedContactClientIcon: validatedContactClientIcon,
                        validatedContactClientStatus: result.data.data.data[0].verificado,
                        validatedContactClient: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
    
                case 2:
                    let validatedContactProcuratorIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactProcuratorIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactProcuratorIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactProcuratorIcon = "fa fa-exclamation-triangle"
                    }

                    await this.setState({
                        validatedContactProcuratorIcon: validatedContactProcuratorIcon,
                        validatedContactProcuratorStatus: result.data.data.data[0].verificado,
                        validatedContactProcurator: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
                    
                case 3:
                    let validatedContactGuarantorIcon;
                    if (result.data.data.data[0].verificado === "1")
                    {
                        validatedContactGuarantorIcon = "fa fa-check"
                    }
                    else if (result.data.data.data[0].verificado === null)
                    {
                        validatedContactGuarantorIcon = "fa fa-spinner"
                    }
                    else
                    {
                        validatedContactGuarantorIcon = "fa fa-exclamation-triangle"
                    }
                    
                    await this.setState({
                        validatedContactGuarantorIcon: validatedContactGuarantorIcon,
                        validatedContactGuarantorStatus: result.data.data.data[0].verificado,
                        validatedContactGuarantor: result.data.data.data[0].verificado === "1" || result.data.data.data[0].verificado === "3" ? true : false,
                        checkVerifyWhatsapp: result.data.data.data[0].verificado === "3" ? true : false,
                        phoneId: result.data.data.data[0].iqdTelefone,
                    });
                    break;
    
                default:
                    break;
            }

            await this.setState({ dataEnvelope: {
                ...this.state.dataEnvelope,
                clientCellPhone1: this.state.clientCellPhone1,
                guarantorCellPhone1:this.state.guarantorCellPhone1,
                procuratorCellPhone1:this.state.procuratorCellPhone1,
            }});
        }

        if (this.state.onClickValidationContactOnWitness === true)
        {
            // this.queryEnvelope();
            this.createTableSignatures();
        }
    }

    onClickNoConfirmation = () =>
    {
        this.setState({
            statusConfirmation: false,
            objectConfirmation: {},
            message_envelope_show_confirmation: false,
            message_envelope_confirmation: "",
            message_envelope_type_confirmation: ""
        })
    }

    validateClientData = async (target) => 
    {
        const value = target.value;
        
        if (formatedOnlyNumber(value).length >= 11) 
        {
            const brazillianIdentification = isCpfOrCnpj(value);
            let choosenClientIdentificationType;
            let validateCpfCnpj;

            if (brazillianIdentification === "CPF") 
            { 
                choosenClientIdentificationType = 1; 
                validateCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                await this.getClientByNationalRegistration(value);
            }
            else if (brazillianIdentification === "CNPJ") 
            { 
                choosenClientIdentificationType = 2; 
                validateCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                await this.getClientByNationalRegistration(value);
            }
            else if (brazillianIdentification === "Inválido") 
            { 
                validateCpfCnpj = 2;
                target.style.setProperty("border-color", "red", "important");
                await this.setState({ clientRegistered: false });
            }

            await this.setState({ choosenClientIdentificationType, validateCpfCnpj })
        }
        else
        {
            target.style.borderColor = "";
            await this.setState({ validateCpfCnpj: 2, clientRegistered: false })
        }
    }

    validateProcuratorData = async (target) => 
    {
        const value = target.value;
        
        if (formatedOnlyNumber(value).length >= 11) 
        {
            const brazillianIdentification = isCpfOrCnpj(value);
            let choosenProcuratorIdentificationType;
            let validateProcuratorCpfCnpj;

            if (brazillianIdentification === "CPF") 
            { 
                choosenProcuratorIdentificationType = 1; 
                validateProcuratorCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                this.getProcuratorByNationalRegistration(value);
            }
            else if (brazillianIdentification === "CNPJ") 
            { 
                choosenProcuratorIdentificationType = 2; 
                validateProcuratorCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                this.getProcuratorByNationalRegistration(value);
            }
            else if (brazillianIdentification === "Inválido") 
            { 
                validateProcuratorCpfCnpj = 2;
                target.style.setProperty("border-color", "red", "important");
                this.setState({ procuratorRegistered: false });
            }

            this.setState({ choosenProcuratorIdentificationType, validateProcuratorCpfCnpj })
        }
        else
        {
            target.style.borderColor = "";
            this.setState({ validateProcuratorCpfCnpj: 2, procuratorRegistered: false })
        }

        if (formatedOnlyNumber(value) === formatedOnlyNumber(this.state.clientCPFCNPJ)) { this.setState({ procuratorIsClient: true, procuratorRegistered: false, validateProcuratorCpfCnpj: 2 }); }
        else { this.setState({ procuratorIsClient: false }); }
    }

    validateGuarantorData = async (target) => 
    {
        const value = target.value;

        this.setState({ guarantorIsClient: false, guarantorRegistered: false, guarantorIsProcurator: false })
        
        if (formatedOnlyNumber(value).length === 11) 
        {
            await this.clearFieldsClient("GUARANTOR");
            const brazillianIdentification = isCpfOrCnpj(value);
            let choosenGuarantorIdentificationType;
            let validateGuarantorCpfCnpj;

            if (brazillianIdentification === "CPF") 
            { 
                choosenGuarantorIdentificationType = 1; 
                validateGuarantorCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                this.getGuarantorByNationalRegistration(value);
            }
            else if (brazillianIdentification === "CNPJ") 
            { 
                choosenGuarantorIdentificationType = 2; 
                validateGuarantorCpfCnpj = 1;
                target.style.setProperty("border-color", "green", "important");
                this.getGuarantorByNationalRegistration(value);
            }
            else if (brazillianIdentification === "Inválido") 
            { 
                validateGuarantorCpfCnpj = 2;
                target.style.setProperty("border-color", "red", "important");
                this.setState({ GuarantorRegistered: false });
            }

            this.setState({ choosenGuarantorIdentificationType, validateGuarantorCpfCnpj })
        }
        else
        {
            target.style.borderColor = "";
            this.setState({ validateGuarantorCpfCnpj: 2, guarantorRegistered: false })
        }

        if (formatedOnlyNumber(value) === formatedOnlyNumber(this.state.clientCPFCNPJ))
        { 
            this.setState({ guarantorIsClient: true, guarantorRegistered: false, validateGuarantorCpfCnpj: 2 });
        }
    }

    switchServices = async () => 
    {
        let id;

        if (this.props.businessService.id_service_erp === undefined)
        {
            const response = await financialServicesController.getFinServiceByIdService(this.props.businessService.value);
            id = response.data.BUP_ID_SERVICE_ERP;
        }
        else
        {
            id = this.props.businessService.id_service_erp;
        }
        
        switch (id)
        {
            case 1:
                //FINANCING
                this.setState({ showDataVehicles: 1 });
                this.setState({ showDataCreditCard: 0 });
                this.setState({ showDataOverdraft: 0 });
                this.setState({ showDataInstitution: 1 });
                
                this.setState({ showFieldComarca: 1 });
                this.setState({ showFieldDateInstitution: 1 });
                break;

            case 5:
                //BANK LOAN
                this.setState({ showDataVehicles: 0 });
                this.setState({ showDataCreditCard: 0 });
                this.setState({ showDataOverdraft: 0 });
                this.setState({ showDataInstitution: 1 });
                
                this.setState({ showFieldComarca: 0 });
                this.setState({ showFieldDateInstitution: 0 });
                break;

            case 20:
                //CREDIT CARD
                this.setState({ showDataVehicles: 0 });
                this.setState({ showDataCreditCard: 1 });
                this.setState({ showDataOverdraft: 0 });
                this.setState({ showDataInstitution: 0 });
                
                this.setState({ showFieldComarca: 0 });
                this.setState({ showFieldDateInstitution: 0 });
                break;

            case 21:
                //OVERDRAFT
                this.setState({ showDataVehicles: 0 });
                this.setState({ showDataCreditCard: 0 });
                this.setState({ showDataOverdraft: 1 });
                this.setState({ showDataInstitution: 1 });
                
                this.setState({ showFieldComarca: 0 });
                this.setState({ showFieldDateInstitution: 0 });
                break;

            case 25:
                //REMAINING BALANCE
                this.setState({ showDataVehicles: 1 });
                this.setState({ showDataCreditCard: 0 });
                this.setState({ showDataOverdraft: 0 });
                this.setState({ showDataInstitution: 1 });
                
                this.setState({ showFieldComarca: 1 });
                this.setState({ showFieldDateInstitution: 1 });
                break;

            case 29:
                //NAME CLEARING
                this.setState({ showDataClearName: 1 });
                break;

            case 30:
                //FINANCING AUTO EQUITY
                this.setState({ showDataVehicles: 1 });
                this.setState({ showDataCreditCard: 0 });
                this.setState({ showDataOverdraft: 0 });
                this.setState({ showDataInstitution: 1 });
                
                this.setState({ showFieldComarca: 1 });
                this.setState({ showFieldDateInstitution: 1 });
                break;
            default:
                break;
        }
    }

    ifClientExists = async () => 
    {
        if (this.state.onlyFirstTimeIfClientExists) return;
        if (this.state.clientId !== null)
        {
            const target = document.getElementById("clientCPFCNPJ");
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ clientCPFCNPJ: maskCPF(target.value) }) : this.setState({ clientCPFCNPJ: maskCNPJ(target.value) });
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ clientNationalRegistration: maskCPF(target.value) }) : this.setState({ clientNationalRegistration: maskCNPJ(target.value) });
            await this.validateClientData(target);

            this.setState({ onlyFirstTimeIfClientExists: true})
        }
    }

    ifProcuratorExists = async () => 
    {
        if (this.state.onlyFirstTimeIfProcuratorExists) return;
        if (this.state.procuratorId !== null)
        {
            const target = document.getElementById("procuratorCPFCNPJ");
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ procuratorCPFCNPJ: maskCPF(target.value) }) : this.setState({ procuratorCPFCNPJ: maskCNPJ(target.value) });
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ procuratorNationalRegistration: maskCPF(target.value) }) : this.setState({ procuratorNationalRegistration: maskCNPJ(target.value) });
            await this.validateProcuratorData(target);

            this.setState({ onlyFirstTimeIfProcuratorExists: true})
        }
    }

    ifGuarantorExists = async () => 
    {
        if (this.state.onlyFirstTimeIfGuarantorExists) return;
        if (this.state.guarantorId !== null)
        {
            const target = document.getElementById("guarantorCPFCNPJ");
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ guarantorCPFCNPJ: maskCPF(target.value) }) : this.setState({ guarantorCPFCNPJ: maskCNPJ(target.value) });
            (formatedOnlyNumber(target.value).length <= 11) ? this.setState({ guarantorNationalRegistration: maskCPF(target.value) }) : this.setState({ guarantorNationalRegistration: maskCNPJ(target.value) });
            await this.validateGuarantorData(target);

            this.setState({ onlyFirstTimeIfGuarantorExists: true})
        }
    }
    
    tabDetailsService = () => 
    {
        return (
            <div className="py-1">
                <div className="row mb-3">
                    <div className="col-12">
                        <InputGeneral
                            classaditional="modernFieldInputGeneral"
                            label="Título"
                            placeholder="Ex: FINANCIMENTO - BANCO ITAÚ"
                            value={this.state.title}
                            onChange={e => this.setState({ title: e.target.value.toUpperCase() })}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-md-4">
                        <InputGeneral
                            label="Tipo de Serviço"
                            classaditional="modernFieldInputGeneral"
                            disabled
                            value={typeof this.props.businessService?.name === "string" ? this.props.businessService.name.toUpperCase() : ""}
                            maxLength={12}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <InputGeneral
                            label="Nome do Cliente"
                            classaditional="modernFieldInputGeneral"
                            disabled
                            value={this.state.clientName}
                            maxLength={12}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <Select
                            classaditional="modernSelectFieldGeneral" 
                            label="Instituição Financeira"
                            disabled={true}
                            default={this.state.choosenFinancialInstitution}
                            options={this.state.optionsFinancialInstitution}
                            onChange={(choosenFinancialInstitution) => this.setState({ choosenFinancialInstitution })} 
                            withFieldSearch
                        />
                    </div>
                </div>
                {/* UPLOAD DOCUMENT AUDIT */}
                {/* <div className="row mb-3">
                    <div className="col-12">
                        <div>
                            <input type="file" onChange={this.handleFileChange} />
                            <button onClick={this.uploadAudit}>Enviar Auditoria</button>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
    
    tabDetailsWitnesses = () => 
    {
        return (
            <Fragment>
                <MessageAttention
                    messageAttentionType={this.state.messageAttentionType}
                    messageAttentionText={this.state.messageAttentionText}
                    title={this.state.messageAttentionTitle}
                    onClose={() => this.setState({ messageAttentionShow: false })}
                    onConfirm={ async () => {
                        await this.setState({ isDisabledButtonCreateEnvelope: true });
                        this.setState({ messageAttentionShow: false, continueWithHavePendencies: true, thisClientHavePendencies: false }, async () => {
                            await this.sendSignEnvelope(this.state.categorySelected);
                        })
                        await this.setState({ isDisabledButtonCreateEnvelope: false });
                    }}
                    show={this.state.messageAttentionShow}
                />
                <div className="py-1 containerWitnessesWizard">
                    <MessageConfirmation
                        message_type={this.state.message_envelope_type_confirmation}
                        message={this.state.message_envelope_confirmation}
                        onClose={() => this.setState({message_envelope_show_confirmation: false})}
                        show={this.state.message_envelope_show_confirmation}
                        onClickYes={ async () => 
                        {
                            switch (this.state.message_envelope_type_functions)
                            {
                                case "create":
                                        await this.createEnvelope();
                                    break;
                                case "delete":
                                        await this.deleteEnvelope();
                                    break;
                                case "sign":
                                        await this.sendSignEnvelope();
                                    break;
                            
                                default:
                                    break;
                            }
                        }}
                        onClickNo={() => this.onClickNoConfirmation()}
                    >
                        {
                            this.state.bodyModalConfirmationOfEnvelope
                        }
                    </MessageConfirmation>

                    {
                        this.state.bondsType === 1 &&
                        <MessageConfirmation
                            message_type={this.state.message_type_confirmation}
                            message={this.state.message_confirmation}
                            onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                            show={this.state.message_show_confirmation}
                        >
                        {
                            this.state.showValidationButton &&
                            <div>
                                <Button
                                    style={{fontSize: 14, height: 32}}
                                    name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                    onClick={async (e) => {
                                        this.setState({ isDisabledButton: true });
                                        await this.validatePhone();
                                        this.setState({ isDisabledButton: false });
                                    }}
                                    disabled={this.state.isDisabledButton}
                                >
                                </Button>
                                <br />
                            </div>
                        }
                        {
                            this.state.showValidationCodeField &&
                            <div>
                                <div className="mb-3" style={{display: "flex"}}>
                                    <div style={{width: 165}}>
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral" 
                                            disabled={this.state.checkVerifyWhatsapp}
                                            label="Código de confirmação"
                                            value={this.state.validationCodeField}
                                            onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                        />
                                    </div>
                                    <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            onClick={async () => 
                                            {
                                                await this.setState({ isDisabledButton: true });
                                                const data = {
                                                    "token": this.state.validationCodeField,
                                                    "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1)
                                                }
                                        
                                                let result = await serviceController.verifyTokenValidationCode(data);
                                                await this.setState({ isDisabledButton: false });

                                                if (result.data.data.status === "200")
                                                {
                                                    await this.setState({
                                                        message_confirmation: "",
                                                        message_show_confirmation: false,
                                                        message_type_confirmation: ""
                                                    });
                                                    await this.queryValidationCode();
                                                    await this.queryPhoneWhatsapp();
                                                    await this.message("success", result.data.data.message);
                                                }

                                                if (result.data.data.status === "400")
                                                {
                                                    this.message("error", result.data.data.message);
                                                }

                                                this.queryPhoneWhatsapp();
                                            }}
                                            disabled={this.state.isDisabledButton}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                        >
                                        </Button>
                                    </div>
                                    <div className="d-flex align-items-end" style={{width: 165}}>
                                        <Button
                                            style={{fontSize: 14, height: 32}}
                                            name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                            onClick={async (e) => {
                                                this.setState({ isDisabledButton: true });
                                                await this.validatePhone(1);
                                                this.setState({ isDisabledButton: false });
                                            }}
                                            disabled={this.state.isDisabledButton}
                                        >
                                        </Button>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <Check 
                                        name="Impossivel verificar whatsapp" 
                                        onClick={() => 
                                        {
                                            this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                            
                                            const data = {
                                                phoneId: this.state.phoneId,
                                                verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                            }

                                            serviceController.releaseContractWithoutWhatsapp(data);
                                            this.queryPhoneWhatsapp();
                                        }} 
                                        checked={this.state.checkVerifyWhatsapp}
                                    />
                                </div>

                            </div>
                        }
                        </MessageConfirmation>
                    }

                    {
                        this.state.bondsType === 2 &&
                        <MessageConfirmation
                            message_type={this.state.message_type_confirmation}
                            message={this.state.message_confirmation}
                            onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                            show={this.state.message_show_confirmation}
                        >
                            {
                                this.state.showValidationButton &&
                                <div>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                        onClick={async (e) => {
                                            this.setState({ isDisabledButton: true });
                                            await this.validatePhone();
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    >
                                    </Button>
                                    <br />
                                </div>
                            }
                            {
                                this.state.showValidationCodeField &&
                                <div>
                                    <div className="mb-3" style={{display: "flex"}}>
                                        <div style={{width: 165}}>
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral" 
                                                disabled={this.state.checkVerifyWhatsapp}
                                                label="Código de confirmação"
                                                value={this.state.validationCodeField}
                                                onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                            />
                                        </div>
                                        <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                onClick={async () => 
                                                {
                                                    this.setState({ isDisabledButton: true });
                                                    const data = {
                                                        "token": this.state.validationCodeField,
                                                        "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1)
                                                    }
                                            
                                                    let result = await serviceController.verifyTokenValidationCode(data);
                                                    this.setState({ isDisabledButton: false });

                                                    if (result.data.data.status === "200")
                                                    {
                                                        await this.setState({
                                                            message_confirmation: "",
                                                            message_show_confirmation: false,
                                                            message_type_confirmation: ""
                                                        });

                                                        await this.message("success", result.data.data.message);
                                                    }

                                                    if (result.data.data.status === "400")
                                                    {
                                                        this.message("error", result.data.data.message);
                                                    }

                                                    this.queryPhoneWhatsapp();
                                                }}
                                                disabled={this.state.isDisabledButton}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                            >
                                            </Button>
                                        </div>
                                        <div className="d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                                onClick={async (e) => {
                                                    this.setState({ isDisabledButton: true });
                                                    await this.validatePhone(1);
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                disabled={this.state.isDisabledButton}
                                            >
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <Check 
                                            name="Impossivel verificar whatsapp" 
                                            onClick={() => 
                                            {
                                                this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                                
                                                const data = {
                                                    phoneId: this.state.phoneId,
                                                    verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                                }

                                                serviceController.releaseContractWithoutWhatsapp(data);
                                                this.queryPhoneWhatsapp();
                                            }} 
                                            checked={this.state.checkVerifyWhatsapp}
                                        />
                                    </div>

                                </div>
                            }
                        </MessageConfirmation>
                    }

                    {
                        this.state.bondsType === 3 &&
                        <MessageConfirmation
                            message_type={this.state.message_type_confirmation}
                            message={this.state.message_confirmation}
                            onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                            show={this.state.message_show_confirmation}
                        >
                            {
                                this.state.showValidationButton &&
                                <div>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                        onClick={async (e) => {
                                            this.setState({ isDisabledButton: true });
                                            await this.validatePhone();
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    >
                                    </Button>
                                    <br />
                                </div>
                            }
                            {
                                this.state.showValidationCodeField &&
                                <div>
                                    <div className="mb-3" style={{display: "flex"}}>
                                        <div style={{width: 165}}>
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral" 
                                                disabled={this.state.checkVerifyWhatsapp}
                                                label="Código de confirmação"
                                                value={this.state.validationCodeField}
                                                onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                            />
                                        </div>
                                        <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                onClick={async () => 
                                                {
                                                    this.setState({ isDisabledButton: true });
                                                    const data = {
                                                        "token": this.state.validationCodeField,
                                                        "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1)
                                                    }
                                            
                                                    let result = await serviceController.verifyTokenValidationCode(data);
                                                    this.setState({ isDisabledButton: false });

                                                    if (result.data.data.status === "200")
                                                    {
                                                        await this.setState({
                                                            message_confirmation: "",
                                                            message_show_confirmation: false,
                                                            message_type_confirmation: ""
                                                        });

                                                        await this.message("success", result.data.data.message);
                                                    }

                                                    if (result.data.data.status === "400")
                                                    {
                                                        this.message("error", result.data.data.message);
                                                    }

                                                    this.queryPhoneWhatsapp();
                                                }}
                                                disabled={this.state.isDisabledButton}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                            >
                                            </Button>
                                        </div>
                                        <div className="d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                                onClick={async (e) => {
                                                    this.setState({ isDisabledButton: true });
                                                    await this.validatePhone(1);
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                disabled={this.state.isDisabledButton}
                                            >
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <Check 
                                            name="Impossivel verificar whatsapp" 
                                            onClick={() => 
                                            {
                                                this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                                
                                                const data = {
                                                    phoneId: this.state.phoneId,
                                                    verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                                }

                                                serviceController.releaseContractWithoutWhatsapp(data);
                                                this.queryPhoneWhatsapp();
                                            }} 
                                            checked={this.state.checkVerifyWhatsapp}
                                        />
                                    </div>

                                </div>
                            }
                        </MessageConfirmation>
                    }

                    {
                        (this.state.buttonCreateEnvelope.show === "true" && this.state.buttonCreateEnvelope.color !== "red") &&
                        <Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <strong>Signatários</strong>
                                    <hr />
                                </div>
                            </div>
                            <div className="mb-3">
                                {this.state.tableSignatures}
                            </div>
                            <div className="mb-3">
                                <Accordion defaultActiveKey="0" alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Selecione testemunhas logo abaixo</Accordion.Header>
                                        <Accordion.Body>
                                            {this.state.availableWitnesses.length > 0 ? (
                                                <ul className="list-group witnessTableWizard">
                                                    <li className="witnessTableHeaderWizard list-group-item d-flex justify-content-between" style={{ border: "0px" }}>
                                                        <span style={{ width:"16em", textAlign: "left" }}>Nome</span>
                                                        <div className="w-50 d-flex justify-content-between">
                                                            <span style={{ width:"16em", textAlign: "center" }}>Status</span>
                                                            <span style={{ width:"16em", textAlign: "center" }}>Observação</span>
                                                            <span style={{ width:"18em", textAlign: "center" }}>Último Login</span>
                                                        </div>
                                                    </li>
                                                    {this.state.availableWitnesses.map((item, index) => (
                                                        <li 
                                                            key={index}
                                                            className="witnessWizard list-group-item d-flex justify-content-between mt-2"
                                                            onClick={() => this.selectWitness(index, 0)}
                                                        >
                                                            <span className="witnessTableNameWizard flex-grow-1 text-start" style={{ width:"16em", textAlign: "left" }}>{item.simpleName}</span>
                                                            <div className="w-50 d-flex justify-content-between witnessTableDetailsWizard">
                                                                <span className="witnessTableStatusWizard" style={{ width:"16em", textAlign: "center", color: item.online ? "green" : "red" }}>{item.online ? "Online" : "Offline"}</span>
                                                                <span style={{ width:"16em", textAlign: "center" }}>{item.obs}</span>
                                                                <span className="witnessTableTimestampWizard text-center" style={{ width:"20em" }}>{item.login ? formatedDateTime(item.login) : "Nunca logou"}</span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>Não há testemunhas disponíveis.</p>
                                            )}
                                            
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <hr />
                        </Fragment>
                    }
                    {
                        this.state.enableFieldsEnvelope
                        ?
                        <Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <strong>Envelopes</strong>
                                    <hr />
                                </div>
                            </div>
                            <div className="mb-3">
                                {
                                    this.state.envelopeAlertFirstCategory !== "" &&
                                    <Fragment>
                                        <div className="containerEnvelopeAlertWizard">
                                            <div className="alert alert-warning" role="alert">
                                                <h4 className="alert-heading">AVISO</h4>
                                                <p>{this.state.envelopeAlertFirstCategory}</p>
                                                <p className={this.state.envelopeAlertSecondCategory === "" ? "d-none" : ""} style={{fontSize: 12, fontStyle: "italic"}}>Categoria Contrato</p>
                                            </div>
                                        </div>
                                        <hr />
                                    </Fragment>
                                }
                                {
                                    this.state.envelopeAlertSecondCategory !== "" &&
                                    <Fragment>
                                        <div className="containerEnvelopeAlertWizard">
                                            <div className="alert alert-warning" role="alert">
                                                <h4 className="alert-heading">AVISO</h4>
                                                <p>{this.state.envelopeAlertSecondCategory}</p>
                                                <p style={{fontSize: 12, fontStyle: "italic"}}>Categoria Anexos</p>
                                            </div>
                                        </div>
                                        <hr />
                                    </Fragment>
                                }
                                {
                                    this.state.showFirstCategory &&
                                    <div style={{ background: this.state.colorEnvelopeFirstCategory?.fundo, borderLeft: `10px solid ${this.state.colorEnvelopeFirstCategory?.borda}`, position: "relative" }} className="alert d-flex align-items-center justify-content-between" role="alert">
                                        <div>
                                            <h4 className="alert-heading" style={{ margin: 0 }}>Contrato</h4>
                                            {
                                                this.state.labelsFirstCategory !== "" &&
                                                <div  className="containerEnvelopeLabelsWizard">
                                                    {this.state.labelsFirstCategory}
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <Button
                                                    classaditional={`${this.state.buttonCreateEnvelope?.color === "red" ? "buttonErrorAlertEnvelopeWizard" : ""} buttonCreateEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonCreateEnvelope.show === "false" ? "d-none" : "" }`}
                                                    name={this.state.isDisabledButtonCreateEnvelope ? "aguarde..." : `${this.state.buttonCreateEnvelope?.label}`} 
                                                    onClick={async () => {
                                                        if ((this.state.buttonCreateEnvelope?.color === "red") === false)
                                                        {
                                                            this.setState({ isDisabledButtonCreateEnvelope: true });
                                                        }
                                                        await this.createEnvelope(1);
                                                        this.setState({ isDisabledButtonCreateEnvelope: false });
                                                    }}
                                                    disabled={this.state.isDisabledButtonCreateEnvelope || this.state.buttonCreateEnvelope?.color === "red"}
                                                >
                                                </Button>
                                                <Button
                                                    name={this.state.isDisabledButtonCreateEnvelope ? "aguarde..." : "enviar para assinatura"}
                                                    classaditional={`buttonSendSignWizard buttonEnvelopeWizard ${this.state.buttonSendSign.show === "false" ? "d-none" : "" }`}
                                                    onClick={async () => {
                                                        this.setState({ isDisabledButtonCreateEnvelope: true });
                                                        await this.sendSignEnvelope(1);
                                                        this.setState({ isDisabledButtonCreateEnvelope: false });
                                                    }}
                                                    disabled={this.state.isDisabledButtonCreateEnvelope}
                                                >
                                                </Button>
                                                {
                                                    this.state.envelopeTimer?.show === "true" &&
                                                    <div
                                                        className={`timerEnvelopeWizard`}
                                                    >
                                                        <i className="fa fa-alarm-clock"></i>
                                                        {/* <Timer token={this.state.envelopeTimer?.token} initialMinutes={this.state.envelopeTimer?.totalMinutos} onTimeEnd={() => this.queryEnvelope()} /> */}
                                                    </div>
                                                }
                                                <Button
                                                    icon="fa fa-trash"
                                                    classaditional={ `buttonDeleteEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonDeleteEnvelope.show === "false" ? "d-none" : "" }` }
                                                    onClick={() => this.deleteEnvelope(1)}
                                                >
                                                </Button>
                                                <Button
                                                    name="status dos signatários"
                                                    classaditional={`buttonStatusSignatoriesWizard buttonEnvelopeWizard ${this.state.buttonDetailsEnvelope.show === "false" ? "d-none" : "" }`}
                                                    onClick={() => this.statusSignatoriesEnvelope(1)}
                                                >
                                                </Button>
                                                <Button
                                                    name="anexo(s) do envelope"
                                                    classaditional={`buttonDetailsEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonDetailsEnvelope.show === "false" ? "d-none" : "" }`}
                                                    onClick={() => this.detailsEnvelope(1)}
                                                >
                                                </Button>

                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="containerShowDetailsOrSignaturesWizard">
                                    {
                                        this.state.showDetailsEnvelopeFirstCategory && (
                                            <div className="containerDetailsEnvelopeWizard p-4 bg-light rounded shadow-sm">
                                                <h4 className="text-primary mb-4">
                                                    <i className="fa fa-envelope-open-text me-2" style={{ marginRight: 5 }} ></i>
                                                    Anexo(s) do Envelope
                                                </h4>

                                                {this.state.optionsServiceExtra.length > 0 && (
                                                    <div className="row align-items-center mb-4">
                                                        <div className="col-10">
                                                            <SelectMulti
                                                                label="Outros SERVIÇOS deste Cliente"
                                                                classaditional="modernSelectFieldGeneral"
                                                                default={this.state.choosenServiceExtra}
                                                                options={this.state.optionsServiceExtra}
                                                                onChange={(choosenServiceExtra) => {
                                                                    const checkedService = choosenServiceExtra.find(service => service.checked);
                                                                    const checkedServices = choosenServiceExtra.filter(service => service.checked);

                                                                    if (checkedService)
                                                                    {
                                                                        if (checkedService.name.includes("Necessita salvar Parcelas"))
                                                                        {
                                                                            this.message("error", "Esse serviço não será anexado a outro serviço até que as parcelas deve tenham sido salvas.");
                                                                        }
                                                                        else
                                                                        {
                                                                            this.setState({ choosenServiceExtra: checkedServices });
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div 
                                                            className="col-2 d-flex align-items-end"
                                                            style={{height: 72, padding: 0}}
                                                        >
                                                            <Button
                                                                classaditional={`buttonSendAttachmentEnvelopeWizard buttonEnvelopeWizard`}
                                                                name={this.state.isDisabledButtonAddServices ? "aguarde..." : "anexar ao envelope"} 
                                                                onClick={async (e) => {
                                                                    this.setState({ isDisabledButtonAddServices: true });
                                                                    await this.sendAttachmentToEnvelope(1);
                                                                    this.setState({ isDisabledButtonAddServices: false });
                                                                }}
                                                                disabled={this.state.isDisabledButtonAddServices}
                                                            >
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="mt-4">
                                                    <h5 className="text-secondary">
                                                    </h5>
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "right"}}>Serviço</th>
                                                                <th style={{ textAlign: "right"}}>Data de Envio</th>
                                                                <th>Enviado Por</th>
                                                                <th>Documento</th>
                                                                <th>Tamanho</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.attachmentDetails?.dados.length > 0 ? (
                                                                this.state.attachmentDetails.dados.map((anexo, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ textAlign: "right"}}>{anexo.servico}</td>
                                                                        <td style={{ textAlign: "right"}}>{anexo.data}</td>
                                                                        <td>{anexo.enviadoPor}</td>
                                                                        <td>
                                                                            <a href={anexo.doc.url} target="_blank" rel="noopener noreferrer">
                                                                                {anexo.doc.nome || " - "}
                                                                            </a>
                                                                        </td>
                                                                        <td>{anexo.tamanho || " - "}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="text-center text-muted">Nenhum anexo disponível.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.showSignaturesEnvelope && (
                                            <div className="containerDetailsEnvelopeWizard">
                                                <h4 className="text-primary mb-4">
                                                    <i className="fa fa-file-signature me-2" style={{ marginRight: 5 }} ></i>
                                                    Signatários
                                                </h4>
                                                <table className={`table table-bordered mt-3`}>
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th>Tipo</th>
                                                            <th>Nome</th>
                                                            <th>Status</th>
                                                            <th>Re-enviar</th>
                                                            <th>Link</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.signaturesEnvelope && this.state.signaturesEnvelope.length > 0 ? (
                                                                this.state.signaturesEnvelope.map((signature, index) => (
                                                                <tr key={index}>
                                                                    <td>{signature.qualification ? signature.qualification : " - "}</td>
                                                                    <td>{signature.nome ? signature.nome : " - "}</td>
                                                                    <td ><span className="spanInfoSignedWizard" style={{ background: signature.colorStatusAss }}>{signature.statusAss ? signature.statusAss : " - "}</span></td>
                                                                    <td> 
                                                                        {
                                                                            signature.exibirZap === "1" ?
                                                                                <Button
                                                                                    className="buttonUrlLinkSignaturesWizard"
                                                                                    style={{ backgroundColor: "#28a745"}}
                                                                                    icon="fab fa-whatsapp"
                                                                                    onClick={async () => {
                                                                                        const data = {
                                                                                            userId: this.props.user.PER_ID_USER_ERP,
                                                                                            serviceId: this.state.id,
                                                                                            clientId: this.state.clientId,
                                                                                            url: signature.sign_url
                                                                                        }

                                                                                        const result = await serviceController.resendSubscription(data);
                                                                                        if (!result.status) { return; }

                                                                                        this.message("success", "Mensagem enviada com sucesso!")
                                                                                    }}
                                                                                >
                                                                                </Button> 
                                                                            : 
                                                                                " - "
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (signature.qualification === "EMPRESA" && this.state.releaseSignToCreator) ?
                                                                                <Button
                                                                                    className="buttonUrlLinkSignaturesWizard"
                                                                                    icon="fa fa-external-link"
                                                                                    onClick={() => window.open(signature.sign_url, "_blank")}
                                                                                ></Button>
                                                                            :
                                                                                " - "
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="6">Este envelope não possui nenhum anexo atualmente</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    this.state.showSecondCategory &&
                                    <div style={{ background: this.state.colorEnvelopeSecondCategory?.fundo, borderLeft: `10px solid ${this.state.colorEnvelopeSecondCategory?.borda}`, position: "relative" }} className="alert d-flex align-items-center justify-content-between" role="alert">
                                        <div>
                                            <h4 className="alert-heading" style={{ margin: 0 }}>Anexos</h4>
                                            {
                                                this.state.labelsSecondCategory !== "" &&
                                                <div  className="containerEnvelopeLabelsWizard">
                                                    {this.state.labelsSecondCategory}
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <Button
                                                    classaditional={`${this.state.buttonCreateEnvelopeSecondCategory?.color === "red" ? "buttonErrorAlertEnvelopeWizard" : ""} buttonCreateEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonCreateEnvelopeSecondCategory.show === "false" ? "d-none" : "" }`}
                                                    name={this.state.isDisabledButtonCreateEnvelopeSecondCategory ? "aguarde..." : `${this.state.buttonCreateEnvelopeSecondCategory?.label}`} 
                                                    onClick={async (e) => {
                                                        if ((this.state.buttonCreateEnvelopeSecondCategory?.color === "red") === false)
                                                            {
                                                                this.setState({ isDisabledButtonCreateEnvelopeSecondCategory: true });
                                                            }
                                                        await this.createEnvelope(2);
                                                        this.setState({ isDisabledButtonCreateEnvelopeSecondCategory: false });
                                                    }}
                                                    disabled={this.state.isDisabledButtonCreateEnvelopeSecondCategory || this.state.buttonCreateEnvelopeSecondCategory?.color === "red"}
                                                >
                                                </Button>
                                                <Button
                                                    name={this.state.isDisabledButtonCreateEnvelope ? "aguarde..." : "enviar para assinatura"}
                                                    classaditional={`buttonSendSignWizard buttonEnvelopeWizard ${this.state.buttonSendSignSecondCategory.show === "false" ? "d-none" : "" }`}
                                                    onClick={async () => {
                                                        this.setState({ isDisabledButtonCreateEnvelope: true });
                                                        await this.sendSignEnvelope(2);
                                                        this.setState({ isDisabledButtonCreateEnvelope: false });
                                                    }}
                                                    disabled={this.state.isDisabledButtonCreateEnvelope}
                                                >
                                                </Button>
                                                {
                                                    this.state.envelopeTimerSecondCategory?.show === "true" &&
                                                    <div
                                                        className={`timerEnvelopeWizard`}
                                                    >
                                                        <i className="fa fa-alarm-clock"></i>
                                                        {/* <Timer token={this.state.envelopeTimerSecondCategory?.token} initialMinutes={this.state.envelopeTimerSecondCategory?.totalMinutos} onTimeEnd={() => this.queryEnvelope()} /> */}
                                                    </div>
                                                }
                                                <Button
                                                    icon="fa fa-trash"
                                                    classaditional={ `buttonDeleteEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonDeleteEnvelopeSecondCategory.show === "false" ? "d-none" : "" }` }
                                                    onClick={() => this.deleteEnvelope(2)}
                                                >
                                                </Button>
                                                <Button
                                                    name="status dos signatários"
                                                    classaditional={`buttonStatusSignatoriesWizard buttonEnvelopeWizard ${this.state.buttonDetailsEnvelopeSecondCategory.show === "false" ? "d-none" : "" }`}
                                                    onClick={() => this.statusSignatoriesEnvelope(2)}
                                                >
                                                </Button>
                                                <Button
                                                    name="anexo(s) do envelope"
                                                    classaditional={`buttonDetailsEnvelopeWizard buttonEnvelopeWizard ${this.state.buttonDetailsEnvelopeSecondCategory.show === "false" ? "d-none" : "" }`}
                                                    onClick={() => this.detailsEnvelope(2)}
                                                >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="containerShowDetailsOrSignaturesWizard">
                                    {
                                        this.state.showDetailsEnvelopeSecondCategory && (
                                            <div className="containerDetailsEnvelopeWizard p-4 bg-light rounded shadow-sm">
                                                <h4 className="text-primary mb-4">
                                                    <i className="fa fa-envelope-open-text me-2" style={{ marginRight: 5 }} ></i>
                                                    Anexo(s) do Envelope
                                                </h4>

                                                <div className="mt-4">
                                                    <table
                                                        className={`table table-bordered mt-3 ${
                                                            this.state.showAttachmentsDetailsSecondCategory === false
                                                                ? "d-none"
                                                                : ""
                                                        }`}
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "right"}}>Serviço</th>
                                                                <th style={{ textAlign: "right"}}>Data de Envio</th>
                                                                <th>Enviado Por</th>
                                                                <th>Documento</th>
                                                                <th>Tamanho</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.attachmentDetailsSecondCategory &&
                                                            this.state.attachmentDetailsSecondCategory.dados.length > 0 ? (
                                                                this.state.attachmentDetailsSecondCategory.dados.map((anexo, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ textAlign: "right"}}>{anexo.servico}</td>
                                                                        <td style={{ textAlign: "right"}}>{anexo.data}</td>
                                                                        <td>{anexo.enviadoPor}</td>
                                                                        <td>
                                                                            <a href={anexo.doc.url} target="_blank" rel="noopener noreferrer">
                                                                                {anexo.doc.nome ? anexo.doc.nome : " - "}
                                                                            </a>
                                                                        </td>
                                                                        <td>{anexo.tamanho ? anexo.tamanho : " - "}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        Este envelope não possui nenhum anexo atualmente.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.showSignaturesEnvelopeSecondCategory && (
                                            <div className="containerDetailsEnvelopeWizard">
                                                <h4 className="text-primary mb-4">
                                                    <i className="fa fa-file-signature me-2" style={{ marginRight: 5 }} ></i>
                                                    Signatários
                                                </h4>
                                                <table className={`table table-bordered mt-3`}>
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Nome</th>
                                                        <th>Status</th>
                                                        <th>Re-enviar</th>
                                                        <th>Link</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.signaturesEnvelopeSecondCategory &&
                                                    this.state.signaturesEnvelopeSecondCategory.length > 0 ? (
                                                    this.state.signaturesEnvelopeSecondCategory.map(
                                                        (signature, index) => (
                                                        <tr key={index}>
                                                            <td>{ signature.qualification ? signature.qualification : " - " }</td>
                                                            <td>{signature.nome ? signature.nome : " - "}</td>
                                                            <td>
                                                                <span
                                                                    className="spanInfoSignedWizard"
                                                                    style={{ background: signature.colorStatusAss }}
                                                                >
                                                                    {signature.statusAss ? signature.statusAss : " - "}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    signature.exibirZap === "1" ?
                                                                        <Button
                                                                            className="buttonUrlLinkSignaturesWizard"
                                                                            style={{ backgroundColor: "#28a745"}}
                                                                            icon="fab fa-whatsapp"
                                                                            onClick={async () => {
                                                                                const data = {
                                                                                    userId: this.props.user.PER_ID_USER_ERP,
                                                                                    serviceId: this.state.id,
                                                                                    clientId: this.state.clientId,
                                                                                    url: signature.sign_url
                                                                                }

                                                                                const result = await serviceController.resendSubscription(data);
                                                                                if (!result.status) { return; }

                                                                                this.message("success", "Mensagem enviada com sucesso!")
                                                                            }}
                                                                        >
                                                                        </Button> 
                                                                    : 
                                                                        " - "
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    signature.qualification === "EMPRESA" ?
                                                                        <Button
                                                                            className="buttonUrlLinkSignaturesWizard"
                                                                            icon="fa fa-external-link"
                                                                            onClick={() =>
                                                                                window.open(signature.sign_url, "_blank")
                                                                            }
                                                                        ></Button>
                                                                    :
                                                                        " - "
                                                                }
                                                            </td>
                                                        </tr>
                                                        )
                                                    )
                                                    ) : (
                                                    <tr>
                                                        <td colSpan="6">
                                                            Este envelope não possui nenhum anexo atualmente
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Fragment>
                        :
                        <div className="row">
                            <div className="col-12">
                                <hr />
                                <strong>Não é possível criar o envelope: <span className="colorTextErrorDisableEnvelope">{this.state.enableFieldsEnvelopeMessage}.</span></strong>
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }

    tabDetailsContract = () => 
    {
        return (
            <div className="py-1">
                {
                    (this.state.showDataClearName === 0) ? 
                        <div>
                            {
                                (this.state.showDataInstitution === 1) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <strong>Dados do Contrato com a Instituição</strong>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Número do Contrato Financiamento/Empréstimo"
                                                defaultValue={this.state.contractNumber}
                                                mandatory="true"
                                                onChange={e => this.setState({ contractNumber: e.target.value.toString() })}
                                            />
                                        </div>
                                        {(this.state.showFieldDateInstitution) ?
                                            <Fragment>
                                                <div className="col-12 col-lg-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Data de Assinatura Instituição Financeira/Banco"
                                                        mandatory="true"
                                                        type="date"
                                                        defaultValue={this.state.signatureDate}
                                                        onChange={e => this.setState({ signatureDate: e.target.value })}
                                                    />
                                                </div>
                                            </Fragment>
                                            : ""
                                        }
                                        {(this.state.showFieldComarca) ?
                                            <Fragment>
                                                <div className="col-12 col-lg-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Comarca (Cidade onde foi financiado o veículo)"
                                                        mandatory="true"
                                                        defaultValue={this.state.contractComarca}
                                                        onChange={e => this.setState({ contractComarca: e.target.value.toString() })}
                                                    />
                                                </div>
                                            </Fragment>
                                            : ""
                                        }
                                    </div>
                                </Fragment>
                            }

                            { 
                                (this.state.showAdress === 1) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <strong>Endereço do Carnê</strong><span> (Endereço que consta no carnê ou no contrato do financiamento do veículo).</span>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="CEP"
                                                mandatory="true"
                                                defaultValue={this.state.contractZipCode}
                                                onChange={async e => 
                                                {
                                                    await this.setState({ contractZipCode: e.target.value });
                                                    if (formatedOnlyNumber(this.state.contractZipCode).length === 8) 
                                                    {
                                                        await this.getContractAddressFromZipCode();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Select
                                                classaditional="modernSelectFieldGeneral" 
                                                label="Estado"
                                                mandatory="true"
                                                default={this.state.choosenContractState}
                                                options={this.state.optionsContractState} 
                                                onChange={async (choosenContractState) => 
                                                {
                                                    await this.setState({ choosenContractState });
                                                    const cities = await this.getCitiesFromState(this.state.choosenContractState.value);

                                                    this.setState({
                                                        optionsContractCity: cities,
                                                        choosenContractCity: null,
                                                        optionsContractNeighborhood: [],
                                                        choosenContractNeighborhood: null
                                                    });
                                                }}
                                                withFieldSearch
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Select
                                                classaditional="modernSelectFieldGeneral" 
                                                label="Cidade"
                                                mandatory="true"
                                                default={this.state.choosenContractCity}
                                                options={this.state.optionsContractCity} 
                                                onChange={async (choosenContractCity) => 
                                                {
                                                    await this.setState({ choosenContractCity });
                                                    const neighborhood = await this.getNeighborhoodFromCity(this.state.choosenContractCity.value);
                                                    this.setState({ optionsContractNeighborhood: neighborhood, choosenContractNeighborhood: null });
                                                }}
                                                withFieldSearch
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Endereço"
                                                mandatory="true"
                                                defaultValue={this.state.contractAddress}
                                                onChange={e => this.setState({ contractAddress: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Número"
                                                mandatory="true"
                                                defaultValue={this.state.contractAddressNumber}
                                                onChange={e => this.setState({ contractAddressNumber: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Select
                                                classaditional="modernSelectFieldGeneral" 
                                                label="Bairro"
                                                mandatory="true"
                                                default={this.state.choosenContractNeighborhood}
                                                options={this.state.optionsContractNeighborhood} 
                                                onChange={(choosenContractNeighborhood) => 
                                                {
                                                    this.setState({ choosenContractNeighborhood });
                                                }}
                                                withFieldSearch
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Complemento"
                                                defaultValue={this.state.contractAddressComplement}
                                                onChange={e => this.setState({ contractAddressComplement: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {
                                (this.state.showDataVehicles === 1) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <strong>Dados do Veículo</strong>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Tipo de Veículo (Ex: Pas/Automóvel)"
                                                value={this.state.vehicleType}
                                                onChange={e => this.setState({ vehicleType: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Marca/Modelo"
                                                value={this.state.vehicleBrand}
                                                onChange={e => this.setState({ vehicleBrand: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Ano/Modelo" 
                                                value={this.state.vehicleYear}
                                                onChange={e => this.setState({ vehicleYear: e.target.value.toString() })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Cor"
                                                value={this.state.vehicleColor}
                                                onChange={e => this.setState({ vehicleColor: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Placa"
                                                value={this.state.vehiclePlate}
                                                onChange={e => this.setState({ vehiclePlate: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Renavam"
                                                value={this.state.vehicleRenavam}
                                                onChange={e => this.setState({ vehicleRenavam: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                mandatory="true"
                                                classaditional="modernFieldInputGeneral"
                                                label="Chassi"
                                                value={this.state.vehicleChassis}
                                                onChange={e => this.setState({ vehicleChassis: e.target.value.toUpperCase() })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <Select
                                                classaditional="modernSelectFieldGeneral" 
                                                label="Detran"
                                                mandatory="true"
                                                default={this.state.choosenDetranState}
                                                options={this.state.optionsDetranState} 
                                                onChange={(choosenDetranState) => 
                                                {
                                                    this.setState({ choosenDetranState });
                                                }}
                                                withFieldSearch
                                            />
                                        </div>
                                    </div> 
                                </Fragment>
                            }
                            { 
                                (this.state.showDataCreditCard === 1) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <strong>Cartão de Crédito</strong>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Final Cartão de Crédito"
                                                type="number"
                                                mandatory="true"
                                                maxLength={4}
                                                defaultValue={this.state.creditCardFinal}
                                                onChange={e => {
                                                    if (e.target.value.length > 4)
                                                    {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }

                                                    if (e.target.value.length <= 4)
                                                    {
                                                        this.setState({ creditCardFinal: e.target.value.toString() })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            { 
                                (this.state.showDataOverdraft === 1) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <strong>Cheque Especial</strong>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Cheque Especial Agência"
                                                defaultValue={this.state.bankAgency}
                                                mandatory="true"
                                                onChange={e => this.setState({ bankAgency: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Cheque Especial Conta Corrente"
                                                mandatory="true"
                                                defaultValue={this.state.bankCheckingAccount}
                                                onChange={e => this.setState({ bankCheckingAccount: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <strong>Débitos</strong>
                                    <hr />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="alert alert-warning" role="alert">
                                        <b>ATENÇÃO</b>: Se os valores do débito não estiverem <b>CORRETOS</b>, feche o cadastro do serviço e refaça a <b>ANÁLISE</b>!
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Data do Atraso da Parcela"
                                        type="date"
                                        mandatory="true"
                                        value={this.state.contractLateDate}
                                        onChange={e => this.setState({ contractLateDate: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Número de Parcelas"
                                        type="number"
                                        value={this.state.contractNumberInstallments}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Valor das Parcelas"
                                        disabled={true}
                                        value={this.state.contractInstallmentValue}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Parcelas Pagas"
                                        type="number"
                                        disabled={true}
                                        defaultValue={this.state.contractNumberInstallmentsPaid}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Valor Financiado"
                                        disabled={true}
                                        value={this.state.contractAmountFinanced}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Total Pago"
                                        defaultValue={formatedMoneyBRAFromFloat(this.state.contractAmountTotalPaid)}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12 col-lg-4">
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Total Débito"
                                        defaultValue={this.state.contractAmountTotalDebit}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    :
                        <div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <InputGeneral
                                        label="Data do Atraso da Primeira Restrição"
                                        classaditional="modernFieldInputGeneral"
                                        type="date"
                                        defaultValue={this.state.contractLateDate}
                                        onChange={e => this.setState({ contractLateDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <InputGeneral
                                        label="Valor Total da Restrição"
                                        disabled
                                        classaditional="modernFieldInputGeneral"
                                        value={this.state.contractAmountTotalDebit}
                                        onChange={e => this.setState({ contractAmountTotalDebit: e.target.value })}
                                        mandatory="true"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <InputGeneral
                                        label="Total Débito"
                                        disabled
                                        classaditional="modernFieldInputGeneral"
                                        value={this.state.contractAmountTotalDebit}
                                        onChange={e => this.setState({ contractAmountTotalDebit: e.target.value })}
                                        mandatory="false"
                                    />
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }

    tabDetailsClient = () => 
    {
        return (
            <div className="containerClientWizard py-1">
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                    show={this.state.message_show_confirmation}
                >
                    {
                        this.state.showValidationButton &&
                        <div>
                            <Button
                                style={{fontSize: 14, height: 32}}
                                name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                onClick={async (e) => {
                                    this.setState({ isDisabledButton: true });
                                    await this.validatePhone();
                                    this.setState({ isDisabledButton: false });
                                }}
                                disabled={this.state.isDisabledButton}
                            >
                            </Button>
                            <br />
                        </div>
                    }
                    {
                        this.state.showValidationCodeField &&
                        <div>
                            <div className="mb-3" style={{display: "flex"}}>
                                <div style={{width: 165}}>
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral" 
                                        disabled={this.state.checkVerifyWhatsapp}
                                        label="Código de confirmação"
                                        value={this.state.validationCodeField}
                                        onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                    />
                                </div>
                                <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        onClick={async () => 
                                        {
                                            await this.setState({ isDisabledButton: true });
                                            const data = {
                                                "token": this.state.validationCodeField,
                                                "phone": 55 + formatedOnlyNumber(this.state.clientCellPhone1)
                                            }
                                    
                                            let result = await serviceController.verifyTokenValidationCode(data);
                                            await this.setState({ isDisabledButton: false });

                                            if (result.data.data.status === "200")
                                            {
                                                await this.setState({
                                                    message_confirmation: "",
                                                    message_show_confirmation: false,
                                                    message_type_confirmation: ""
                                                });
                                                await this.queryValidationCode();
                                                await this.queryPhoneWhatsapp();
                                                await this.message("success", result.data.data.message);
                                            }

                                            if (result.data.data.status === "400")
                                            {
                                                this.message("error", result.data.data.message);
                                            }

                                            this.queryPhoneWhatsapp();
                                        }}
                                        disabled={this.state.isDisabledButton}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                    >
                                    </Button>
                                </div>
                                <div className="d-flex align-items-end" style={{width: 165}}>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                        onClick={async (e) => {
                                            this.setState({ isDisabledButton: true });
                                            await this.validatePhone(1);
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    >
                                    </Button>
                                </div>
                            </div>

                            <div className="mb-3">
                                <Check 
                                    name="Impossivel verificar whatsapp" 
                                    onClick={() => 
                                    {
                                        this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                        
                                        const data = {
                                            phoneId: this.state.phoneId,
                                            verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                        }

                                        serviceController.releaseContractWithoutWhatsapp(data);
                                        this.queryPhoneWhatsapp();
                                    }} 
                                    checked={this.state.checkVerifyWhatsapp}
                                />
                            </div>

                        </div>
                    }
                </MessageConfirmation>
                <div className="row">
                    <div className="col-12">
                        <strong>Dados Pessoais</strong>
                        <hr />
                    </div>
                </div>
                
                <div className="row mb-3">
                    <div className="col-4">
                        <InputGeneral
                            classaditional="modernFieldInputGeneral"
                            label={"CPF/CNPJ"}
                            placeholder={"Informe o CPF ou CNPJ para continuar"}
                            id="clientCPFCNPJ"
                            place
                            disabled={this.state.saveService || this.state.hasEnvelopes}
                            value={this.state.clientCPFCNPJ}
                            onChange={async e => 
                            {
                                this.setState({ disableGuarantor: false, checkGuarantor: false});
                                
                                (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ clientCPFCNPJ: maskCPF(e.target.value) }) : this.setState({ clientCPFCNPJ: maskCNPJ(e.target.value) });
                                (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ clientNationalRegistration: maskCPF(e.target.value) }) : this.setState({ clientNationalRegistration: maskCNPJ(e.target.value) });

                                if (formatedOnlyNumber(e.target.value).length > 11) this.setState({ checkGuarantor: true, disableGuarantor: true });
                                if (formatedOnlyNumber(e.target.value).length >= 14) await this.clearFieldsClient("CLIENT");

                                await this.validateClientData(e.target);
                            }}
                            mandatory="true"
                        />
                        <span style={{ color: this.state.clientRegistered === true ? "green" : "white" }}>ESSE CPF/CNPJ JÁ POSSUI CADASTRO</span>
                    </div>
                    {
                        this.state.validateCpfCnpj === 1 &&
                        <Fragment>
                            <div className="col-4 d-flex align-items-center">
                                <div className="boxCheckDataClientServiceFormWizard">
                                    <Check 
                                        name="Serviço utiliza procurador" 
                                        onClick={async () => 
                                        {
                                            this.state.checkProcurator ? document.getElementById("checkGuarantor").removeAttribute("disabled") : document.getElementById("checkGuarantor").setAttribute("disabled", true);
                                            
                                            this.setState({checkProcurator: !this.state.checkProcurator, checkGuarantor: this.state.checkProcurator ? false : true})
                                            await this.clearFieldsClient("PROCURATOR");
                                        }} 
                                        disabled={this.state.saveService || this.state.hasEnvelopes}
                                        checked={this.state.checkProcurator}
                                    />
                                </div>
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <div className={"boxCheckDataClientServiceFormWizard"}>
                                    <Check
                                        id="checkGuarantor"
                                        name="Serviço utiliza responsável financeiro" 
                                        onClick={async () => 
                                        {
                                            await this.setState({checkGuarantor: !this.state.checkGuarantor, guarantorCPFCNPJ: ""});
                                            await this.clearFieldsClient("GUARANTOR");
                                        }} 
                                        disabled={this.state.saveService || this.state.hasEnvelopes || this.state.disableGuarantor}
                                        checked={this.state.checkGuarantor}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }

                </div>
                { 
                    this.state.validateCpfCnpj === 1 &&
                    <Fragment>
                        <div className="row mb-3">
                            <div className="col-12 col-md-8">
                                <InputGeneral
                                    ref={this.serviceRefs.clientName}
                                    classaditional="modernFieldInputGeneral"
                                    placeholder="Digite o nome completo ou razão social do cliente (Ex: João da Silva / Empresa XYZ LTDA)."
                                    label="Nome/Razão Social"
                                    value={this.state.clientName}
                                    onChange={e => this.setState({ clientName: e.target.value.toUpperCase() })}
                                    mandatory="true"
                                />
                            </div>
                            {
                                this.state.choosenClientIdentificationType === 1 &&
                                <Fragment>
                                    <div className="col-12 col-md-2">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="RG"
                                            value={this.state.clientRG}
                                            onChange={e => this.setState({ clientRG: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Orgão emissor"
                                            value={this.state.clientIssuingInstitution }
                                            onChange={e => this.setState({ clientIssuingInstitution : e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                </Fragment>
                            }
                        </div>
                        { 
                            this.state.choosenClientIdentificationType === 1 &&
                            <Fragment>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="Sexo"
                                            default={this.state.choosenClientGender}
                                            options={this.state.optionsClientGender} 
                                            onChange={(choosenClientGender) => 
                                            {
                                                this.setState({ choosenClientGender });
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Data de Nascimento"
                                            type="date"
                                            mandatory="true"
                                            value={this.state.clientBirthday}
                                            onChange={e => this.setState({ clientBirthday: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            mandatory="true"
                                            label="Profissão"
                                            value={this.state.clientJob}
                                            onChange={e => this.setState({ clientJob: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="Estado Civil"
                                            mandatory="true"
                                            default={this.state.choosenClientMaritalStatus}
                                            options={this.state.optionsClientMaritalStatus}
                                            onChange={(choosenClientMaritalStatus) => 
                                            {
                                                this.setState({ choosenClientMaritalStatus });

                                                if (this.state.choosenClientMaritalStatus && this.state.choosenClientMaritalStatus?.value === "2")
                                                {
                                                    this.setState({ clientSpouse: "", clientCpfSpouse: null });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            mandatory="true"
                                            label="Nacionalidade"
                                            placeholder="Ex: BRASILEIRO(A)."
                                            value={this.state.clientNationality}
                                            onChange={e => this.setState({ clientNationality: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Naturalidade"
                                            placeholder="Ex: RIO DE JANEIRO/RJ."
                                            value={this.state.clientNaturalness}
                                            onChange={e => this.setState({ clientNaturalness: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Nome do Pai"
                                            value={this.state.clientFatherName}
                                            onChange={e => this.setState({ clientFatherName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Nome da Mãe"
                                            value={this.state.clientMotherName}
                                            onChange={e => this.setState({ clientMotherName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="E-mail"
                                            value={this.state.clientEmail}
                                            onChange={e => 
                                            {
                                                this.setState({ clientEmail: e.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    (this.state.choosenClientMaritalStatus && this.state.choosenClientMaritalStatus.value === "2") &&
                                    <Fragment>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome do Cônjuge"
                                                    value={this.state.clientSpouse}
                                                    onChange={e => this.setState({ clientSpouse: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="CPF do Cônjuge"
                                                    value={this.state.clientCpfSpouse}
                                                    onChange={e => this.setState({ clientCpfSpouse: maskCPF(e.target.value) })}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        <div className={this.state.choosenClientIdentificationType === 1 ? "d-none" : "row mb-4"}>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="E-mail"
                                    value={this.state.clientEmail}
                                    onChange={e => 
                                    {
                                        this.setState({ clientEmail: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <strong>Endereço</strong>
                                <hr />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="CEP"
                                    value={this.state.clientZipCode}
                                    onChange={async e => 
                                    {
                                        await this.setState({ clientZipCode: e.target.value });
                                        if (formatedOnlyNumber(this.state.clientZipCode).length === 8) { await this.getClientAddressFromZipCode(); }
                                    }}
                                    mandatory="true"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="Estado"
                                    default={this.state.choosenClientState}
                                    options={this.state.optionsClientState} 
                                    mandatory="true"
                                    onChange={async (choosenClientState) => 
                                    {
                                        await this.setState({ choosenClientState });
                                        const cities = await this.getCitiesFromState(this.state.choosenClientState.value);
                                        this.setState({
                                            optionsClientCity: cities,
                                            choosenClientCity: null,
                                            optionsClientNeighborhood: [],
                                            choosenClientNeighborhood: null
                                        });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="Cidade"
                                    default={this.state.choosenClientCity}
                                    options={this.state.optionsClientCity} 
                                    mandatory="true"
                                    onChange={async (choosenClientCity) => 
                                    {
                                        await this.setState({ choosenClientCity });
                                        const neighborhood = await this.getNeighborhoodFromCity(this.state.choosenClientCity.value);
                                        this.setState({ optionsClientNeighborhood: neighborhood, choosenClientNeighborhood: null });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Endereço"
                                    value={this.state.clientAddress}
                                    onChange={e => this.setState({ clientAddress: e.target.value.toUpperCase() })}
                                    mandatory="true"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Número"
                                    value={this.state.clientAddressNumber}
                                    onChange={e => this.setState({ clientAddressNumber: e.target.value })}
                                    mandatory="true"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="Bairro"
                                    default={this.state.choosenClientNeighborhood}
                                    options={this.state.optionsClientNeighborhood} 
                                    mandatory="true"
                                    onChange={(choosenClientNeighborhood) => 
                                    {
                                        this.setState({ choosenClientNeighborhood });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Complemento"
                                    value={this.state.clientAddressComplement}
                                    onChange={e => this.setState({ clientAddressComplement: e.target.value.toUpperCase() })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <strong>Informações de Contato</strong>
                                <hr />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                {
                                this.state.isPhoneValidated 
                                    ?
                                    <InputGroup
                                        label="Celular 1"
                                        mandatory="true"
                                        valid="true"
                                        colorIcon={this.state.validatedContactClientStatus === "1" ? "#21af41" : "#ff7800"}
                                        icon={this.state.validatedContactClientIcon}
                                        onChange={async (e) => 
                                        {
                                            this.setState({ clientCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) });

                                            if (formatedOnlyNumber(e.target.value).length >= 10)
                                            {
                                                await this.queryPhoneWhatsapp();
                                            }
                                        }}
                                        value={formatedPhoneAndCellphoneWithSpace(this.state.clientCellPhone1)}
                                        onButtonClick={async() => 
                                        {
                                            await this.queryPhoneWhatsapp();
                                            if (this.state.validatedContactClientStatus === "1") return;

                                            this.setState({
                                                message_confirmation: "",
                                                message_show_confirmation: true,
                                                message_type_confirmation: ""
                                            })
                                        }}
                                    /> 
                                    :
                                    <InputGeneral
                                        classaditional="modernFieldInputGeneral"
                                        label="Celular 1"
                                        value={formatedPhoneAndCellphoneWithSpace(this.state.clientCellPhone1)}
                                        onChange={e => this.setState({ clientCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                        mandatory="true"
                                    />
                                }

                            </div>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    mandatory="true"
                                    value={this.state.clientCellPhone1ContactName}
                                    onChange={e => this.setState({ clientCellPhone1ContactName: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="De Quem é?"
                                    mandatory="true"
                                    default={this.state.clientCellPhone1ChoosenKinship}
                                    options={this.state.optionsClientKinship} 
                                    onChange={(clientCellPhone1ChoosenKinship) => 
                                    {
                                        this.setState({ clientCellPhone1ChoosenKinship });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Celular 2"
                                    value={formatedPhoneAndCellphoneWithSpace(this.state.clientCellPhone2)}
                                    onChange={e => this.setState({ clientCellPhone2: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                    mandatory="true"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    mandatory="true"
                                    value={this.state.clientCellPhone2ContactName}
                                    onChange={e => this.setState({ clientCellPhone2ContactName: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="De Quem é?"
                                    mandatory="true"
                                    default={this.state.clientCellPhone2ChoosenKinship}
                                    options={this.state.optionsClientKinship} 
                                    onChange={(clientCellPhone2ChoosenKinship) => 
                                    {
                                        this.setState({ clientCellPhone2ChoosenKinship });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Celular 3"
                                    value={formatedPhoneAndCellphoneWithSpace(this.state.clientCellPhone3)}
                                    onChange={e => this.setState({ clientCellPhone3: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                    mandatory="true"
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Nome do Contato"
                                    mandatory="true"
                                    value={this.state.clientCellPhone3ContactName}
                                    onChange={e => this.setState({ clientCellPhone3ContactName: e.target.value.toUpperCase() })}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    classaditional="modernSelectFieldGeneral" 
                                    label="De Quem é?"
                                    mandatory="true"
                                    default={this.state.clientCellPhone3ChoosenKinship}
                                    options={this.state.optionsClientKinship} 
                                    onChange={(clientCellPhone3ChoosenKinship) => 
                                    {
                                        this.setState({ clientCellPhone3ChoosenKinship });
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        {this.state.fieldsContactElementsClient}
                        <div className="row mb-3 justify-content-center">
                            <Button 
                                icon="fa fa-user-plus m-2"
                                classaditional="buttonAddContactWizard"
                                name="adicionar novo contato" 
                                onClick={() => this.addFieldContact("CLIENT")} 
                            />
                        </div>
                    </Fragment>
                }
            </div>
        )
    }

    tabDetailsGuarantor = () => 
    {
        return (
            <div className="py-1">
                {
                    this.state.checkGuarantor && 
                    <Fragment>
                        <MessageConfirmation
                            message_type={this.state.message_type_confirmation}
                            message={this.state.message_confirmation}
                            onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                            show={this.state.message_show_confirmation}
                        >
                            {
                                this.state.showValidationButton &&
                                <div>
                                    <Button
                                        style={{fontSize: 14, height: 32}}
                                        name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                        onClick={async (e) => {
                                            this.setState({ isDisabledButton: true });
                                            await this.validatePhone();
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    >
                                    </Button>
                                    <br />
                                </div>
                            }
                            {
                                this.state.showValidationCodeField &&
                                <div>
                                    <div className="mb-3" style={{display: "flex"}}>
                                        <div style={{width: 165}}>
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral" 
                                                disabled={this.state.checkVerifyWhatsapp}
                                                label="Código de confirmação"
                                                value={this.state.validationCodeField}
                                                onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                            />
                                        </div>
                                        <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                onClick={async () => 
                                                {
                                                    this.setState({ isDisabledButton: true });
                                                    const data = {
                                                        "token": this.state.validationCodeField,
                                                        "phone": 55 + formatedOnlyNumber(this.state.guarantorCellPhone1)
                                                    }
                                            
                                                    let result = await serviceController.verifyTokenValidationCode(data);
                                                    this.setState({ isDisabledButton: false });

                                                    if (result.data.data.status === "200")
                                                    {
                                                        await this.setState({
                                                            message_confirmation: "",
                                                            message_show_confirmation: false,
                                                            message_type_confirmation: ""
                                                        });

                                                        await this.message("success", result.data.data.message);
                                                    }

                                                    if (result.data.data.status === "400")
                                                    {
                                                        this.message("error", result.data.data.message);
                                                    }

                                                    this.queryPhoneWhatsapp();
                                                }}
                                                disabled={this.state.isDisabledButton}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                            >
                                            </Button>
                                        </div>
                                        <div className="d-flex align-items-end" style={{width: 165}}>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                                onClick={async (e) => {
                                                    this.setState({ isDisabledButton: true });
                                                    await this.validatePhone(1);
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                disabled={this.state.isDisabledButton}
                                            >
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <Check 
                                            name="Impossivel verificar whatsapp" 
                                            onClick={() => 
                                            {
                                                this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                                
                                                const data = {
                                                    phoneId: this.state.phoneId,
                                                    verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                                }

                                                serviceController.releaseContractWithoutWhatsapp(data);
                                                this.queryPhoneWhatsapp();
                                            }} 
                                            checked={this.state.checkVerifyWhatsapp}
                                        />
                                    </div>

                                </div>
                            }
                        </MessageConfirmation>
                        <div className="row">
                            <div className="col-12">
                                <strong>Dados Pessoais</strong>
                                <hr />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4">
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label={"CPF"}
                                    placeholder={"Informe o CPF para continuar"}
                                    id="guarantorCPFCNPJ"
                                    disabled={this.state.saveService || this.state.hasEnvelopes}
                                    value={this.state.guarantorCPFCNPJ}
                                    onChange={async e => 
                                    {
                                        (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ guarantorCPFCNPJ: maskCPF(e.target.value) }) : this.setState({ guarantorCPFCNPJ: maskCNPJ(e.target.value) });
                                        (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ guarantorNationalRegistration: maskCPF(e.target.value) }) : this.setState({ guarantorNationalRegistration: maskCNPJ(e.target.value) });

                                        if (formatedOnlyNumber(e.target.value).length > 11) this.setState({ checkGuarantor: true });
                                        if (formatedOnlyNumber(e.target.value).length >= 14) await this.clearFieldsClient("GUARANTOR");

                                        await this.validateGuarantorData(e.target);
                                    }}
                                    mandatory="true"
                                />
                                <span className={this.state.guarantorIsClient === true ? "" : "d-none"} style={{color:"red", display: "block"}}>ESSE CPF/CNPJ ESTÁ COMO CLIENTE DESSE SERVIÇO</span>
                                <span className={this.state.guarantorIsProcurator === true ? "" : "d-none"} style={{color:"red", display: "block"}}>ESSE CPF/CNPJ ESTÁ COMO PROCURADOR DESSE SERVIÇO</span>
                                <span style={{ color: this.state.guarantorRegistered === true ? "green" : "white" }}>ESSE CPF/CNPJ JÁ POSSUI CADASTRO</span>
                            </div>
                        </div>
                        {this.state.validateGuarantorCpfCnpj === 1 ? 
                            <Fragment>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-8">
                                        <InputGeneral
                                            id="guarantorName"
                                            classaditional="modernFieldInputGeneral"
                                            placeholder="Digite o nome completo ou razão social do cliente (Ex: João da Silva / Empresa XYZ LTDA)."
                                            label="Nome/Razão Social"
                                            defaultValue={this.state.guarantorName}
                                            onChange={e => this.setState({ guarantorName: e.target.value.toUpperCase() })}
                                            mandatory="true"
                                        />
                                    </div>
                                    {
                                        this.state.choosenGuarantorIdentificationType === 1 &&
                                        <Fragment>
                                            <div className="col-12 col-md-2">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="RG"
                                                    defaultValue={this.state.guarantorRG}
                                                    onChange={e => this.setState({ guarantorRG: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Orgão emissor"
                                                    defaultValue={this.state.guarantorIssuingInstitution }
                                                    onChange={e => this.setState({ guarantorIssuingInstitution : e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                {this.state.choosenGuarantorIdentificationType === 1 ?
                                    <Fragment>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="Sexo"
                                                    default={this.state.choosenGuarantorGender}
                                                    options={this.state.optionsGuarantorGender} 
                                                    onChange={(choosenGuarantorGender) => 
                                                    {
                                                        this.setState({ choosenGuarantorGender });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Data de Nascimento"
                                                    type="date"
                                                    mandatory="true"
                                                    defaultValue={this.state.guarantorBirthday}
                                                    onChange={e => this.setState({ guarantorBirthday: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    mandatory="true"
                                                    label="Profissão"
                                                    defaultValue={this.state.guarantorJob}
                                                    onChange={e => this.setState({ guarantorJob: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="Estado Civil"
                                                    mandatory="true"
                                                    default={this.state.choosenGuarantorMaritalStatus}
                                                    options={this.state.optionsClientMaritalStatus}
                                                    onChange={(choosenGuarantorMaritalStatus) => 
                                                    {
                                                        this.setState({ choosenGuarantorMaritalStatus });

                                                        if (this.state.choosenGuarantorMaritalStatus && this.state.choosenGuarantorMaritalStatus?.value === "2")
                                                        {
                                                            this.setState({ guarantorSpouse: "", guarantorCpfSpouse: null });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    mandatory="true"
                                                    label="Nacionalidade"
                                                    placeholder="Ex: BRASILEIRO(A)."
                                                    defaultValue={this.state.guarantorNationality}
                                                    onChange={e => this.setState({ guarantorNationality: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Naturalidade"
                                                    placeholder="Ex: RIO DE JANEIRO/RJ."
                                                    defaultValue={this.state.guarantorNaturalness}
                                                    onChange={e => this.setState({ guarantorNaturalness: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome do Pai"
                                                    defaultValue={this.state.guarantorFatherName}
                                                    onChange={e => this.setState({ guarantorFatherName: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome da Mãe"
                                                    defaultValue={this.state.guarantorMotherName}
                                                    onChange={e => this.setState({ guarantorMotherName: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="E-mail"
                                                    defaultValue={this.state.guarantorEmail}
                                                    onChange={e => this.setState({ guarantorEmail: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        {
                                            (this.state.choosenGuarantorMaritalStatus && this.state.choosenGuarantorMaritalStatus.value === "2") &&
                                            <Fragment>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-4">
                                                        <InputGeneral
                                                            classaditional="modernFieldInputGeneral"
                                                            label="Nome do Cônjuge"
                                                            value={this.state.guarantorSpouse}
                                                            onChange={e => this.setState({ guarantorSpouse: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <InputGeneral
                                                            classaditional="modernFieldInputGeneral"
                                                            label="CPF do Cônjuge"
                                                            value={this.state.guarantorCpfSpouse}
                                                            onChange={e => this.setState({ guarantorCpfSpouse: maskCPF(e.target.value) })}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                    </Fragment>
                                :
                                ""
                                }
                                <div className={this.state.choosenGuarantorIdentificationType === 1 ? "d-none" : "row mb-4"}>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="E-mail"
                                            value={this.state.guarantorEmail}
                                            onChange={e => 
                                            {
                                                this.setState({ guarantorEmail: e.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Endereço</strong>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="CEP"
                                            defaultValue={this.state.guarantorZipCode}
                                            onChange={async e => 
                                            {
                                                await this.setState({ guarantorZipCode: e.target.value });
                                                if (formatedOnlyNumber(this.state.guarantorZipCode).length === 8) { await this.getGuarantorAddressFromZipCode(); }
                                            }}
                                            mandatory="true"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="Estado"
                                            default={this.state.choosenGuarantorState}
                                            options={this.state.optionsGuarantorState} 
                                            mandatory="true"
                                            onChange={async (choosenGuarantorState) => 
                                            {
                                                await this.setState({ choosenGuarantorState });
                                                const cities = await this.getCitiesFromState(this.state.choosenGuarantorState.value);
                                                this.setState({
                                                    optionsGuarantorCity: cities,
                                                    choosenGuarantorCity: null,
                                                    optionsGuarantorNeighborhood: [],
                                                    choosenGuarantorNeighborhood: null
                                                });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="Cidade"
                                            default={this.state.choosenGuarantorCity}
                                            options={this.state.optionsGuarantorCity} 
                                            mandatory="true"
                                            onChange={async (choosenGuarantorCity) => 
                                            {
                                                await this.setState({ choosenGuarantorCity });
                                                const neighborhood = await this.getNeighborhoodFromCity(this.state.choosenGuarantorCity.value);
                                                this.setState({ optionsGuarantorNeighborhood: neighborhood, choosenGuarantorNeighborhood: null });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Endereço"
                                            defaultValue={this.state.guarantorAddress}
                                            onChange={e => this.setState({ guarantorAddress: e.target.value.toUpperCase() })}
                                            mandatory="true"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Número"
                                            defaultValue={this.state.guarantorAddressNumber}
                                            onChange={e => this.setState({ guarantorAddressNumber: e.target.value })}
                                            mandatory="true"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="Bairro"
                                            default={this.state.choosenGuarantorNeighborhood}
                                            options={this.state.optionsGuarantorNeighborhood} 
                                            mandatory="true"
                                            onChange={(choosenGuarantorNeighborhood) => 
                                            {
                                                this.setState({ choosenGuarantorNeighborhood });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Complemento"
                                            defaultValue={this.state.guarantorAddressComplement}
                                            onChange={e => this.setState({ guarantorAddressComplement: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Informações de Contato</strong>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        {
                                            this.state.isPhoneValidated 
                                            ?
                                            <InputGroup
                                                label="Celular 1"
                                                mandatory="true"
                                                valid="true"
                                                colorIcon={this.state.validatedContactGuarantorStatus === "1" ? "#21af41" : "#ff7800"}
                                                icon={this.state.validatedContactGuarantorIcon}
                                                onChange={async (e) => 
                                                {
                                                    this.setState({ guarantorCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) });
                                                    if (formatedOnlyNumber(e.target.value).length >= 10)
                                                    {
                                                        await this.queryPhoneWhatsapp();
                                                    }
                                                }}
                                                value={this.state.guarantorCellPhone1}
                                                onButtonClick={async () => 
                                                {
                                                    await this.queryPhoneWhatsapp();
                                                    if (this.state.validatedContactGuarantorStatus === "1") return;
        
                                                    this.setState({
                                                        message_confirmation: "",
                                                        message_show_confirmation: true,
                                                        message_type_confirmation: ""
                                                    })
                                                }}
                                            /> 
                                            :
                                            <InputGeneral
                                                classaditional="modernFieldInputGeneral"
                                                label="Celular 1"
                                                value={this.state.guarantorCellPhone1}
                                                onChange={e => this.setState({ guarantorCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                                mandatory="true"
                                            />
                                        }
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Nome do Contato"
                                            mandatory="true"
                                            defaultValue={this.state.guarantorCellPhone1ContactName}
                                            onChange={e => this.setState({ guarantorCellPhone1ContactName: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="De Quem é?"
                                            mandatory="true"
                                            default={this.state.guarantorCellPhone1ChoosenKinship}
                                            options={this.state.optionsClientKinship} 
                                            onChange={(guarantorCellPhone1ChoosenKinship) => 
                                            {
                                                this.setState({ guarantorCellPhone1ChoosenKinship });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Celular 2"
                                            value={this.state.guarantorCellPhone2}
                                            onChange={e => this.setState({ guarantorCellPhone2: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                            mandatory="true"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Nome do Contato"
                                            mandatory="true"
                                            value={this.state.guarantorCellPhone2ContactName}
                                            onChange={e => this.setState({ guarantorCellPhone2ContactName: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="De Quem é?"
                                            mandatory="true"
                                            default={this.state.guarantorCellPhone2ChoosenKinship}
                                            options={this.state.optionsClientKinship} 
                                            onChange={(guarantorCellPhone2ChoosenKinship) => 
                                            {
                                                this.setState({ guarantorCellPhone2ChoosenKinship });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Celular 3"
                                            value={this.state.guarantorCellPhone3}
                                            onChange={e => this.setState({ guarantorCellPhone3: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                            mandatory="true"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label="Nome do Contato"
                                            mandatory="true"
                                            value={this.state.guarantorCellPhone3ContactName}
                                            onChange={e => this.setState({ guarantorCellPhone3ContactName: e.target.value.toUpperCase() })}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <Select
                                            classaditional="modernSelectFieldGeneral" 
                                            label="De Quem é?"
                                            mandatory="true"
                                            default={this.state.guarantorCellPhone3ChoosenKinship}
                                            options={this.state.optionsClientKinship} 
                                            onChange={(guarantorCellPhone3ChoosenKinship) => 
                                            {
                                                this.setState({ guarantorCellPhone3ChoosenKinship });
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                {this.state.fieldsContactElementsGuarantor}
                                <div className="row mb-3 justify-content-center">
                                    <Button 
                                        icon="fa fa-user-plus m-2"
                                        classaditional="buttonAddContactWizard"
                                        name="adicionar novo contato" 
                                        onClick={() => this.addFieldContact("GUARANTOR")} 
                                    />
                                </div>
                            </Fragment>
                        :
                        ""
                        }
                    </Fragment> 
                }
                </div>
        )
    }

    tabDetailsProcurator = () => 
    {
        return (
            <div className="py-1">
                {
                    this.state.checkProcurator && 
                    <Fragment>
                        {
                            this.state.hasProcuratorDocument ?
                            <Fragment>
                                <MessageConfirmation
                                    message_type={this.state.message_type_confirmation}
                                    message={this.state.message_confirmation}
                                    onClose={() => {this.setState({message_show_confirmation: false}); this.queryPhoneWhatsapp();} }
                                    show={this.state.message_show_confirmation}
                                >
                                    {
                                        this.state.showValidationButton &&
                                        <div>
                                            <Button
                                                style={{fontSize: 14, height: 32}}
                                                name={this.state.isDisabledButton ? "aguarde..." : "Verificar"} 
                                                onClick={async (e) => {
                                                    this.setState({ isDisabledButton: true });
                                                    await this.validatePhone();
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                disabled={this.state.isDisabledButton}
                                            >
                                            </Button>
                                            <br />
                                        </div>
                                    }
                                    {
                                        this.state.showValidationCodeField &&
                                        <div>
                                            <div className="mb-3" style={{display: "flex"}}>
                                                <div style={{width: 165}}>
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral" 
                                                        disabled={this.state.checkVerifyWhatsapp}
                                                        label="Código de confirmação"
                                                        value={this.state.validationCodeField}
                                                        onChange={(e) => this.setState({validationCodeField: e.target.value})}
                                                    />
                                                </div>
                                                <div className="pl-2 pr-2 d-flex align-items-end" style={{width: 165}}>
                                                    <Button
                                                        style={{fontSize: 14, height: 32}}
                                                        onClick={async () => 
                                                        {
                                                            this.setState({ isDisabledButton: true });
                                                            const data = {
                                                                "token": this.state.validationCodeField,
                                                                "phone": 55 + formatedOnlyNumber(this.state.procuratorCellPhone1)
                                                            }
                                                    
                                                            let result = await serviceController.verifyTokenValidationCode(data);
                                                            this.setState({ isDisabledButton: false });

                                                            if (result.data.data.status === "200")
                                                            {
                                                                await this.setState({
                                                                    message_confirmation: "",
                                                                    message_show_confirmation: false,
                                                                    message_type_confirmation: ""
                                                                });

                                                                await this.message("success", result.data.data.message);
                                                            }

                                                            if (result.data.data.status === "400")
                                                            {
                                                                this.message("error", result.data.data.message);
                                                            }

                                                            this.queryPhoneWhatsapp();
                                                        }}
                                                        disabled={this.state.isDisabledButton}
                                                        name={this.state.isDisabledButton ? "aguarde..." : "Validar Código"} 
                                                    >
                                                    </Button>
                                                </div>
                                                <div className="d-flex align-items-end" style={{width: 165}}>
                                                    <Button
                                                        style={{fontSize: 14, height: 32}}
                                                        name={this.state.isDisabledButton ? "aguarde..." : "Reenviar Código"} 
                                                        onClick={async (e) => {
                                                            this.setState({ isDisabledButton: true });
                                                            await this.validatePhone(1);
                                                            this.setState({ isDisabledButton: false });
                                                        }}
                                                        disabled={this.state.isDisabledButton}
                                                    >
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <Check 
                                                    name="Impossivel verificar whatsapp" 
                                                    onClick={() => 
                                                    {
                                                        this.setState({checkVerifyWhatsapp: !this.state.checkVerifyWhatsapp})
                                                        
                                                        const data = {
                                                            phoneId: this.state.phoneId,
                                                            verify: this.state.checkVerifyWhatsapp === true ? null : 3
                                                        }

                                                        serviceController.releaseContractWithoutWhatsapp(data);
                                                        this.queryPhoneWhatsapp();
                                                    }} 
                                                    checked={this.state.checkVerifyWhatsapp}
                                                />
                                            </div>

                                        </div>
                                    }
                                </MessageConfirmation>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Dados Pessoais</strong>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4">
                                        <InputGeneral
                                            classaditional="modernFieldInputGeneral"
                                            label={"CPF/CNPJ"}
                                            placeholder={"Informe o CPF ou CNPJ para continuar"}
                                            id="procuratorCPFCNPJ"
                                            disabled={this.state.saveService || this.state.hasEnvelopes}
                                            value={this.state.procuratorCPFCNPJ}
                                            onChange={async e => 
                                            {
                                                (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ procuratorCPFCNPJ: maskCPF(e.target.value) }) : this.setState({ procuratorCPFCNPJ: maskCNPJ(e.target.value) });
                                                (formatedOnlyNumber(e.target.value).length <= 11) ? this.setState({ procuratorNationalRegistration: maskCPF(e.target.value) }) : this.setState({ procuratorNationalRegistration: maskCNPJ(e.target.value) });

                                                if (formatedOnlyNumber(e.target.value).length > 11) this.setState({ checkGuarantor: true });
                                                if (formatedOnlyNumber(e.target.value).length >= 14) await this.clearFieldsClient("PROCURATOR");

                                                await this.validateProcuratorData(e.target);
                                            }}
                                            mandatory="true"
                                        />
                                        <span className={this.state.procuratorIsClient === true ? "" : "d-none"} style={{color:"red", display: "block"}}>ESSE CPF/CNPJ ESTÁ COMO CLIENTE DESSE SERVIÇO</span>
                                        <span style={{ color: this.state.procuratorRegistered === true ? "green" : "white" }}>ESSE CPF/CNPJ JÁ POSSUI CADASTRO</span>
                                    </div>
                                </div>
                                {this.state.validateProcuratorCpfCnpj === 1 ? 
                                    <Fragment>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-8">
                                                <InputGeneral
                                                    id="procuratorName"
                                                    classaditional="modernFieldInputGeneral"
                                                    placeholder="Digite o nome completo ou razão social do cliente (Ex: João da Silva / Empresa XYZ LTDA)."
                                                    label="Nome/Razão Social"
                                                    defaultValue={this.state.procuratorName}
                                                    onChange={e => this.setState({ procuratorName: e.target.value.toUpperCase() })}
                                                    mandatory="true"
                                                />
                                            </div>
                                            {
                                                this.state.choosenProcuratorIdentificationType === 1 &&
                                                <Fragment>
                                                    <div className="col-12 col-md-2">
                                                        <InputGeneral
                                                            classaditional="modernFieldInputGeneral"
                                                            label="RG"
                                                            defaultValue={this.state.procuratorRG}
                                                            onChange={e => this.setState({ procuratorRG: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <InputGeneral
                                                            classaditional="modernFieldInputGeneral"
                                                            label="Orgão emissor"
                                                            defaultValue={this.state.procuratorIssuingInstitution }
                                                            onChange={e => this.setState({ procuratorIssuingInstitution : e.target.value.toUpperCase() })}
                                                        />
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                        {this.state.choosenProcuratorIdentificationType === 1 ?
                                        <Fragment>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-4">
                                                    <Select
                                                        classaditional="modernSelectFieldGeneral" 
                                                        label="Sexo"
                                                        placeholder={"teste"}
                                                        default={this.state.choosenProcuratorGender}
                                                        options={this.state.optionsProcuratorGender}
                                                        onChange={(choosenProcuratorGender) => 
                                                        {
                                                            this.setState({ choosenProcuratorGender });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Data de Nascimento"
                                                        type="date"
                                                        mandatory="true"
                                                        defaultValue={this.state.procuratorBirthday}
                                                        onChange={e => this.setState({ procuratorBirthday: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        mandatory="true"
                                                        label="Profissão"
                                                        defaultValue={this.state.procuratorJob}
                                                        onChange={e => this.setState({ procuratorJob: e.target.value.toUpperCase() })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-4">
                                                    <Select
                                                        classaditional="modernSelectFieldGeneral" 
                                                        label="Estado Civil"
                                                        mandatory="true"
                                                        default={this.state.choosenProcuratorMaritalStatus}
                                                        options={this.state.optionsClientMaritalStatus}
                                                        onChange={(choosenProcuratorMaritalStatus) => 
                                                        {
                                                            this.setState({ choosenProcuratorMaritalStatus });

                                                            if (this.state.choosenProcuratorMaritalStatus && this.state.choosenProcuratorMaritalStatus?.value === "2")
                                                            {
                                                                this.setState({ procuratorSpouse: "", procuratorCpfSpouse: null });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        mandatory="true"
                                                        label="Nacionalidade"
                                                        placeholder="Ex: BRASILEIRO(A)."
                                                        defaultValue={this.state.procuratorNationality}
                                                        onChange={async (e) => {
                                                            await this.setState({ procuratorNationality: e.target.value.toUpperCase() });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Naturalidade"
                                                        placeholder="Ex: RIO DE JANEIRO/RJ."
                                                        defaultValue={this.state.procuratorNaturalness}
                                                        onChange={e => this.setState({ procuratorNaturalness: e.target.value.toUpperCase() })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Nome do Pai"
                                                        defaultValue={this.state.procuratorFatherName}
                                                        onChange={e => this.setState({ procuratorFatherName: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Nome da Mãe"
                                                        defaultValue={this.state.procuratorMotherName}
                                                        onChange={e => this.setState({ procuratorMotherName: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="E-mail"
                                                        defaultValue={this.state.procuratorEmail}
                                                        onChange={e => this.setState({ procuratorEmail: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                (this.state.choosenProcuratorMaritalStatus && this.state.choosenProcuratorMaritalStatus.value === "2") &&
                                                <Fragment>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-4">
                                                            <InputGeneral
                                                                classaditional="modernFieldInputGeneral"
                                                                label="Nome do Cônjuge"
                                                                value={this.state.procuratorSpouse}
                                                                onChange={e => this.setState({ procuratorSpouse: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <InputGeneral
                                                                classaditional="modernFieldInputGeneral"
                                                                label="CPF do Cônjuge"
                                                                value={this.state.procuratorCpfSpouse}
                                                                onChange={e => this.setState({ procuratorCpfSpouse: maskCPF(e.target.value) })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        ""
                                        }
                                        <div className={this.state.choosenProcuratorIdentificationType === 1 ? "d-none" : "row mb-4"}>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="E-mail"
                                                    value={this.state.procuratorEmail}
                                                    onChange={e => 
                                                    {
                                                        this.setState({ procuratorEmail: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <strong>Endereço</strong>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="CEP"
                                                    defaultValue={this.state.procuratorZipCode}
                                                    onChange={async e => 
                                                    {
                                                        await this.setState({ procuratorZipCode: e.target.value });
                                                        if (formatedOnlyNumber(this.state.procuratorZipCode).length === 8) { await this.getProcuratorAddressFromZipCode(); }
                                                    }}
                                                    mandatory="true"
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="Estado"
                                                    default={this.state.choosenProcuratorState}
                                                    options={this.state.optionsProcuratorState} 
                                                    mandatory="true"
                                                    onChange={async (choosenProcuratorState) => 
                                                    {
                                                        await this.setState({ choosenProcuratorState });
                                                        const cities = await this.getCitiesFromState(this.state.choosenProcuratorState.value);
                                                        this.setState({
                                                            optionsProcuratorCity: cities,
                                                            choosenProcuratorCity: null,
                                                            optionsProcuratorNeighborhood: [],
                                                            choosenProcuratorNeighborhood: null
                                                        });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="Cidade"
                                                    default={this.state.choosenProcuratorCity}
                                                    options={this.state.optionsProcuratorCity} 
                                                    mandatory="true"
                                                    onChange={async (choosenProcuratorCity) => 
                                                    {
                                                        await this.setState({ choosenProcuratorCity });
                                                        const neighborhood = await this.getNeighborhoodFromCity(this.state.choosenProcuratorCity.value);
                                                        this.setState({ optionsProcuratorNeighborhood: neighborhood, choosenProcuratorNeighborhood: null });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Endereço"
                                                    defaultValue={this.state.procuratorAddress}
                                                    onChange={e => this.setState({ procuratorAddress: e.target.value.toUpperCase() })}
                                                    mandatory="true"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Número"
                                                    defaultValue={this.state.procuratorAddressNumber}
                                                    onChange={e => this.setState({ procuratorAddressNumber: e.target.value })}
                                                    mandatory="true"
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="Bairro"
                                                    default={this.state.choosenProcuratorNeighborhood}
                                                    options={this.state.optionsProcuratorNeighborhood} 
                                                    mandatory="true"
                                                    onChange={(choosenProcuratorNeighborhood) => 
                                                    {
                                                        this.setState({ choosenProcuratorNeighborhood });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Complemento"
                                                    defaultValue={this.state.procuratorAddressComplement}
                                                    onChange={e => this.setState({ procuratorAddressComplement: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <strong>Informações de Contato</strong>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                {
                                                    this.state.isPhoneValidated 
                                                    ?
                                                    <InputGroup
                                                        label="Celular 1"
                                                        mandatory="true"
                                                        valid="true"
                                                        colorIcon={this.state.validatedContactProcuratorStatus === "1" ? "#21af41" : "#ff7800"}
                                                        icon={this.state.validatedContactProcuratorIcon}
                                                        onChange={async (e) => 
                                                        {
                                                            this.setState({ procuratorCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) });
                                                            if (formatedOnlyNumber(e.target.value).length >= 10)
                                                            {
                                                                await this.queryPhoneWhatsapp();
                                                            }
                                                        }}
                                                        value={this.state.procuratorCellPhone1}
                                                        onButtonClick={async () => 
                                                        {
                                                            await this.queryPhoneWhatsapp();
                                                            if (this.state.validatedContactProcuratorStatus === "1") return;
                
                                                            this.setState({
                                                                message_confirmation: "",
                                                                message_show_confirmation: true,
                                                                message_type_confirmation: ""
                                                            })
                                                        }}
                                                    /> 
                                                    :
                                                    <InputGeneral
                                                        classaditional="modernFieldInputGeneral"
                                                        label="Celular 1"
                                                        value={this.state.procuratorCellPhone1}
                                                        onChange={e => this.setState({ procuratorCellPhone1: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                                        mandatory="true"
                                                    />
                                                }
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome do Contato"
                                                    mandatory="true"
                                                    defaultValue={this.state.procuratorCellPhone1ContactName}
                                                    onChange={e => this.setState({ procuratorCellPhone1ContactName: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="De Quem é?"
                                                    mandatory="true"
                                                    default={this.state.procuratorCellPhone1ChoosenKinship}
                                                    options={this.state.optionsClientKinship} 
                                                    onChange={(procuratorCellPhone1ChoosenKinship) => 
                                                    {
                                                        this.setState({ procuratorCellPhone1ChoosenKinship });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Celular 2"
                                                    value={this.state.procuratorCellPhone2}
                                                    onChange={e => this.setState({ procuratorCellPhone2: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                                    mandatory="true"
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome do Contato"
                                                    mandatory="true"
                                                    defaultValue={this.state.procuratorCellPhone2ContactName}
                                                    onChange={e => this.setState({ procuratorCellPhone2ContactName: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="De Quem é?"
                                                    mandatory="true"
                                                    default={this.state.procuratorCellPhone2ChoosenKinship}
                                                    options={this.state.optionsClientKinship} 
                                                    onChange={(procuratorCellPhone2ChoosenKinship) => 
                                                    {
                                                        this.setState({ procuratorCellPhone2ChoosenKinship });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Celular 3"
                                                    value={this.state.procuratorCellPhone3}
                                                    onChange={e => this.setState({ procuratorCellPhone3: formatedPhoneAndCellphoneWithSpace(e.target.value) })}
                                                    mandatory="true"
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <InputGeneral
                                                    classaditional="modernFieldInputGeneral"
                                                    label="Nome do Contato"
                                                    mandatory="true"
                                                    defaultValue={this.state.procuratorCellPhone3ContactName}
                                                    onChange={e => this.setState({ procuratorCellPhone3ContactName: e.target.value.toUpperCase() })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Select
                                                    classaditional="modernSelectFieldGeneral" 
                                                    label="De Quem é?"
                                                    mandatory="true"
                                                    default={this.state.procuratorCellPhone3ChoosenKinship}
                                                    options={this.state.optionsClientKinship} 
                                                    onChange={(procuratorCellPhone3ChoosenKinship) => 
                                                    {
                                                        this.setState({ procuratorCellPhone3ChoosenKinship });
                                                    }}
                                                    withFieldSearch
                                                />
                                            </div>
                                        </div>
                                        {this.state.fieldsContactElementsProcurator}
                                        <div className="row mb-3 justify-content-center">
                                            <Button 
                                                icon="fa fa-user-plus m-2"
                                                classaditional="buttonAddContactWizard"
                                                name="adicionar novo contato" 
                                                onClick={() => this.addFieldContact("PROCURATOR")} 
                                            />
                                        </div>
                                    </Fragment>
                                :
                                ""
                                }
                            </Fragment>
                            :
                            <Fragment>
                                <div className="containerEnvelopeAlertWizard">
                                    <div className="alert alert-warning" role="alert">
                                        <h4 className="alert-heading">AVISO</h4>
                                        <p>{this.state.procuratorDocumentStatusMessage} Adicione em anexo o Documento de Procuração Pública logo abaixo.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                       <FileUpload onFileUpload={this.handleFileUpload} />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment> 
                }
            </div>
        )
    }

    tabDetailsFinance = () => 
    {
        if (!this.state.initializedTabDetailsFinance && this.props.continueRegistrationActive) 
        {
            if (this.state.hasInstallments === false)
            {
                this.setState({ numInstallments: this.state.installmentSelected, initializedTabDetailsFinance: true, choosenInstallment: this.state.optionsInstallments.find((e) => e.value === this.state.installmentSelected)}, () => {
                    this.setState({ numInstallments: this.state.installmentSelected, initializedTabDetailsFinance: true, choosenInstallment: this.state.optionsInstallments.find((e) => e.value === this.state.installmentSelected)});
                });
            }
            else
            {
                this.setState({ initializedTabDetailsFinance: true });
            }
        }

        return (
            <div className="containerDetailsFinanceWizard py-1" style={{minHeight: "26em"}}>
                <div className="row mb-3 d-flex align-items-end">
                    <div className="col-6 col-md-2">
                        <InputGeneral
                            classaditional="modernFieldInputGeneral"
                            label="Valor do Serviço"
                            disabled={true}
                            value={this.state.serviceValue}
                        />
                    </div>
                    <div className={`col-6 col-md-2`}>
                        {
                            this.state.hasInstallments ?
                                <InputGeneral
                                    classaditional="modernFieldInputGeneral"
                                    label="Número de Parcelas"
                                    disabled={true}
                                    defaultValue={this.state.choosenInstallment !== undefined ? this.state.choosenInstallment.name : ""}
                                />
                            :
                                <Select
                                    label="Número de Parcelas"
                                    default={this.state.choosenInstallment}
                                    classaditional={"modernSelectFieldGeneral"}
                                    onChange={async (choosenInstallment) => 
                                    {
                                        localStorage.setItem("installment", JSON.stringify(choosenInstallment));
                                        this.setState({ choosenInstallment: choosenInstallment }, () => 
                                        { 
                                            this.setState({ setInstallmentsBySelectInstallments: true });
                                            this.setState({ numInstallments: choosenInstallment.value, setInstallmentsBySelectInstallments: true }, async () => 
                                            {
                                                await this.setInstallments();
                                                this.setState({ setInstallmentsBySelectInstallments: false });
                                            });
                                        });
                                    }}
                                    options={this.state.optionsInstallments}
                                />
                        }
                    </div>
                    <div className={`col-6 col-md-2 ${this.state.linkPrint === "" ? "d-none" : ""}`}>
                        <Button
                            name="imprimir todo carnê"
                            classaditional="buttonPrintCarne"
                            onClick={() => window.open(this.state.linkPrint, "_blank")}
                        >
                        </Button>
                    </div>
                </div>
                <hr />
                <div className="mb-3">
                    <span className="titleInstallmentsWizard">Parcelas:</span>
                </div>
                <div id="installments" className="row no-gutters containerInstallmentsWizard">
                    {this.state.installmentElements}
                </div>
                {
                    !(this.state.hasInstallments && this.state.hasEnvelopes) &&
                        !this.state.installmentsWithRegistration && (
                            <div className={`mt-3 d-flex ${this.state.hasInstallments ? "justify-content-start" : "justify-content-end"}`}>
                                <Button
                                    classaditional={this.state.hasInstallments ? "buttonCancelDebtsWizard buttonDebtsWizard" : "buttonSaveDebtsWizard buttonDebtsWizard"}
                                    name={this.state.disableInstallmentButton ? "aguarde..." : (this.state.hasInstallments ? "cancelar parcelas" : "salvar parcelas")}
                                    disabled={this.state.disableInstallmentButton}
                                    onClick={async () => {
                                        await this.setState({disableInstallmentButton: true});
                                        this.state.hasInstallments ? await this.deleteInstallments() : await this.createInstallments();
                                        await this.setState({disableInstallmentButton: false});
                                    }}
                                />
                            </div>
                        )
                }
            </div>
        )
    }

    onSubmitFormService = async() =>
    {
        await this.setState({choosenServiceType: { value: this.props.businessService.id_service_erp, name: this.props.businessService.name }});
        
        this.setState({ serviceValue: this.state.amountService });
        
        const data = {...this.state};
        const dataService = {
            clientId: data.clientId,
            procuratorId: this.state.checkProcurator ? (data.procuratorId === null ? 0 : data.procuratorId) : 0,
            guarantorId: this.state.checkGuarantor ? (data.guarantorId === null ? 0 : data.guarantorId) : 0,
            choosenServiceType: data.choosenServiceType.value,
            choosenFinancialInstitution: data.choosenFinancialInstitution?.value,
            creatorId: data.creatorId || this.props.user.PER_ID_USER_ERP,
            officeId: data.officeId,
            businessId: data.businessId,
            title: data.title,
            contractNumber: data.contractNumber,
            contractComarca: data.contractComarca,
            choosenTypeContract: data.choosenTypeContract?.value || 0,
            dueDate: data.dueDate,
            contractNumberInstallments: data.contractNumberInstallments,
            contractInstallmentValue: formatedMoneyBRAToFloat(data.contractInstallmentValue),
            contractNumberInstallmentsPaid: data.contractNumberInstallmentsPaid,
            contractAmountFinanced: formatedMoneyBRAToFloat(data.contractAmountFinanced),
            contractAmountTotalPaid: formatedMoneyBRAToFloat(data.contractAmountTotalPaid),
            contractAmountTotalDebit: formatedMoneyBRAToFloat(data.contractAmountTotalDebit),
            contractLateDate: data.contractLateDate,
            choosenPaymentMethod: data.choosenPaymentMethod?.value || 0,
            paymentMethodDescription: data.paymentMethodDescription,
            serviceValue: formatedMoneyBRAToFloat(data.serviceValue),
            numberInstallments: data.installmentSelected,
            choosenServiceStatusNegociation1: data.choosenServiceStatusNegociation1.value,
            choosenServiceStatusNegociation2: data.choosenServiceStatusNegociation2.value,
            processNumber: data.processNumber,
            signatureDate: data.signatureDate,
            vehicleType: data.vehicleType,
            vehicleColor: data.vehicleColor,
            vehiclePlate: data.vehiclePlate,
            vehicleChassis: data.vehicleChassis,
            vehicleRenavam: data.vehicleRenavam,
            choosenDetranState: data.choosenDetranState?.value || 0,
            vehicleBrand: data.vehicleBrand,
            vehicleYear: data.vehicleYear,
            bankAgency: data.bankAgency,
            bankCheckingAccount: data.bankCheckingAccount,
            creditCardFinal: data.creditCardFinal,
            contractAddress: data.contractAddress,
            contractAddressNumber: data.contractAddressNumber,
            contractAddressComplement: data.contractAddressComplement,
            contractZipCode: formatedOnlyNumber(data.contractZipCode),
            choosenContractState: data.choosenContractState?.value || 0,
            choosenContractCity: data.choosenContractCity?.value || 0,
            choosenContractNeighborhood: data.choosenContractNeighborhood?.value || 0
        }
        
        if (!this.state.clientId)
        {
            return this.message("error", "É necessário ter um cliente informado!");
        }

        if (this.state.procuratorId === this.state.clientId)
        {
            return this.message("error", "O Procurador selecionado não pode ser igual ao Cliente");
        }

        this.setState({ disabledButton: true });

        let result = null;

        if (this.state.id)
        {
            result = await serviceController.update(this.state.id, { ...dataService });

            if (result.data.statusCode === 400)
            {
                return this.message("error", result.data.message)
            }

            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", result.message);
            }
        }
        else
        {
            const resultBusiness = await businessController.getById(this.state.businessId);
            const business = resultBusiness.data;
            
            if (business.BUS_ID_SERVICE_ERP || this.state.id)
            {
                this.setState({ disabledButton: false });
                this.message("error", `Já existe um serviço para este negócio! Serviço código ${business.BUS_ID_SERVICE_ERP}`);
                return;
            }

            result = await serviceController.create({ ...dataService });

            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", "Ocorreu um erro ao criar esse serviço, reinicie a página e tente novamente.");
            }
    
            await businessController.update(this.state.businessId, {
                BUS_ID_SERVICE_ERP: result.data.id
            });
    
            this.setState({
                id: result.data.id
            });

            this.setState({ dataEnvelope: {
                ...this.state.dataEnvelope,
                serviceId: this.state.id,
                clientCellPhone1: this.state.clientCellPhone1,
                hasInstallments: this.state.hasInstallments,
                procuratorCellPhone1: this.state.procuratorCellPhone1,
                guarantorCellPhone1: this.state.guarantorCellPhone1,
                witnessNumberOne: this.state.witnessNumberOne,
                witnessNumberTwo: this.state.witnessNumberTwo,
                procuratorId: this.state.procuratorId,
                guarantorId: this.state.guarantorId,
                clientId: this.state.clientId,
                checkGuarantor: this.state.checkGuarantor,
                checkProcurator: this.state.checkProcurator,
                officeId: this.state.officeId,
                userId: this.props.user.PER_ID_USER_ERP,
                creatorName: this.state.creatorName,
                clientName: this.state.clientName,
                procuratorName: this.state.procuratorName,
                guarantorName: this.state.guarantorName,
                businessClientId: this.props.business.clientId
            }})
        }

        // this.queryEnvelope();
        this.setState({
            disabledButton: false
        });
    }

    onSubmitFormClient = async() =>
    {
        this.setState({ disabledButton: true });
        await this.setState({
            clientCellPhone1: formatedOnlyNumber(this.state.clientCellPhone1),
            clientCellPhone2: formatedOnlyNumber(this.state.clientCellPhone2),
            clientCellPhone3: formatedOnlyNumber(this.state.clientCellPhone3),
            clientCellPhone4: formatedOnlyNumber(this.state.clientCellPhone4),
            clientCellPhone5: formatedOnlyNumber(this.state.clientCellPhone5),
            clientCellPhone6: formatedOnlyNumber(this.state.clientCellPhone6),
            clientCellPhone7: formatedOnlyNumber(this.state.clientCellPhone7),
            clientCellPhone8: formatedOnlyNumber(this.state.clientCellPhone8),
            guarantorCellPhone1: formatedOnlyNumber(this.state.guarantorCellPhone1),
            guarantorCellPhone2: formatedOnlyNumber(this.state.guarantorCellPhone2),
            guarantorCellPhone3: formatedOnlyNumber(this.state.guarantorCellPhone3),
            guarantorCellPhone4: formatedOnlyNumber(this.state.guarantorCellPhone4),
            guarantorCellPhone5: formatedOnlyNumber(this.state.guarantorCellPhone5),
            guarantorCellPhone6: formatedOnlyNumber(this.state.guarantorCellPhone6),
            guarantorCellPhone7: formatedOnlyNumber(this.state.guarantorCellPhone7),
            guarantorCellPhone8: formatedOnlyNumber(this.state.guarantorCellPhone8),
            procuratorCellPhone1: formatedOnlyNumber(this.state.procuratorCellPhone1),
            procuratorCellPhone2: formatedOnlyNumber(this.state.procuratorCellPhone2),
            procuratorCellPhone3: formatedOnlyNumber(this.state.procuratorCellPhone3),
            procuratorCellPhone4: formatedOnlyNumber(this.state.procuratorCellPhone4),
            procuratorCellPhone5: formatedOnlyNumber(this.state.procuratorCellPhone5),
            procuratorCellPhone6: formatedOnlyNumber(this.state.procuratorCellPhone6),
            procuratorCellPhone7: formatedOnlyNumber(this.state.procuratorCellPhone7),
            procuratorCellPhone8: formatedOnlyNumber(this.state.procuratorCellPhone8),
        })

        const data = {
            ...this.state,
            type: "CLIENT",
            creatorId: this.props.user.PER_ID_USER_ERP
        };

        if (this.state.clientId !== null)
        {
            const result = await clientController.update(this.state.clientId, data);
        
            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", result.message);
            }
    
            this.props.toggleShowNextButton(true);
    
            this.setState({clientId: result.data.clientId, disabledButton: false, clientRegistrationSent: true });
            return;
        }

        const result = await clientController.create(data);
        
        if (!result.status) 
        {
            this.setState({ disabledButton: false });
            return this.message("error", result.message);
        }
 
        this.props.toggleShowNextButton(true);

        this.setState({clientId: result.data.id, disabledButton: false, clientRegistrationSent: true });
    }

    onSubmitFormProcurator = async() =>
    {
        this.setState({ disabledButton: true });

        const data = {
            ...this.state,
            type: "PROCURATOR",
            creatorId: this.props.user.PER_ID_USER_ERP
        };

        if (this.state.procuratorId !== null)
        {
            const result = await clientController.update(this.state.procuratorId, data);
        
            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", result.message);
            }
    
            this.props.toggleShowNextButton(true);
    
            this.setState({disabledButton: false });
            return;
        }

        const result = await clientController.create(data);
        
        if (!result.status) 
        {
            this.setState({ disabledButton: false });
            return this.message("error", result.message);
        }

        this.setState({procuratorId: result.data.id, disabledButton: false});
        this.setState({ dataEnvelope: {
            serviceId: this.state.id,
            hasInstallments: this.state.hasInstallments,
            clientCellPhone1: this.state.clientCellPhone1,
            guarantorCellPhone1: this.state.guarantorCellPhone1,
            procuratorCellPhone1: this.state.procuratorCellPhone1,
            clientId: this.state.clientId,
            guarantorId: this.state.guarantorId,
            procuratorId: this.state.procuratorId,
            checkGuarantor: this.state.checkGuarantor,
            checkProcurator: this.state.checkProcurator,
            creatorName: this.state.creatorName,
            clientName: this.state.clientName,
            procuratorName: this.state.procuratorName,
            guarantorName: this.state.guarantorName,
        }})
    }

    onSubmitFormGuarantor = async() =>
    {
        this.setState({ disabledButton: true });

        const data = {
            ...this.state,
            type: "GUARANTOR",
            creatorId: this.props.user.PER_ID_USER_ERP
        };

        if (this.state.guarantorId !== null)
        {
            const result = await clientController.update(this.state.guarantorId, data);
        
            if (!result.status) 
            {
                this.setState({ disabledButton: false });
                return this.message("error", result.message);
            }
    
            this.props.toggleShowNextButton(true);
    
            this.setState({disabledButton: false });
            return;
        }

        const result = await clientController.create(data);
        
        if (!result.status) 
        {
            this.setState({ disabledButton: false });
            return this.message("error", result.message);
        }

        this.setState({guarantorId: result.data.id, disabledButton: false});
        this.setState({ dataEnvelope: {
            serviceId: this.state.id,
            hasInstallments: this.state.hasInstallments,
            clientCellPhone1: this.state.clientCellPhone1,
            guarantorCellPhone1: this.state.guarantorCellPhone1,
            procuratorCellPhone1: this.state.procuratorCellPhone1,
            clientId: this.state.clientId,
            guarantorId: this.state.guarantorId,
            procuratorId: this.state.procuratorId,
            checkGuarantor: this.state.checkGuarantor,
            checkProcurator: this.state.checkProcurator,
            creatorName: this.state.creatorName,
            clientName: this.state.clientName,
            procuratorName: this.state.procuratorName,
            guarantorName: this.state.guarantorName,
        }})
    }

    getWitnesses = async () => {
        const result = await serviceController.getWitnesses(this.state.officeId, this.state.id);
    
        if (!result.status || result.data.data.length === 0)
        {
            return;
        }
    
        const availableWitnesses = result.data.data.filter(item => item.userId !== this.props.user.PER_ID_USER_ERP);
    
        const witnesses = [];
        if (this.state.witnessNumberOne)
        {
            const indexOne = availableWitnesses.findIndex(witness => witness.userId === this.state.witnessNumberOne);
            if (indexOne !== -1)
            {
                witnesses.push(availableWitnesses[indexOne]);
                availableWitnesses.splice(indexOne, 1);
            }
        }
    
        if (this.state.witnessNumberTwo)
        {
            const indexTwo = availableWitnesses.findIndex(witness => witness.userId === this.state.witnessNumberTwo);
            if (indexTwo !== -1)
            {
                witnesses.push(availableWitnesses[indexTwo]);
                availableWitnesses.splice(indexTwo, 1);
            }
        }
    
        await this.setState({ availableWitnesses, witnesses });
    
        this.createTableSignatures();
    };
    
    selectWitness = async(index, selectType) =>
    {
        let witnesses = this.state.witnesses;
        let availableWitnesses = this.state.availableWitnesses;
        let currentWitness = null;

        switch (selectType)
        {
            case 0:
                    if (witnesses.length >= 2) { return; }

                    currentWitness = this.state.availableWitnesses[index];
                    witnesses.push(currentWitness);
                    availableWitnesses = availableWitnesses.filter((_, i) => i !== index);
                break;

            case 1:
                    currentWitness = this.state.witnesses[index];
                    availableWitnesses.push(currentWitness);
                    witnesses = witnesses.filter((_, i) => i !== index);
                break;

            default:
                break;
        }

        await this.setState({ availableWitnesses, witnesses });

        await this.onSubmitFormService();

        if (this.state.witnesses[1])
        {
            await this.getService();
            await document.querySelector(".accordion-button").click();
        }

        this.createTableSignatures();
    }

    next = () => 
    { 
        this.wizardRef.current.actions.goNextPage() 
    }

    previus = () => 
    { 
        this.wizardRef.current.actions.goPrevPage() 
    }

    createTableSignatures = () =>
    {
        let signatures = [
            {
                show: true,
                typeName: "CLIENTE",
                type: 1,
                index: null,
                name: this.state.clientName,
            },
            {
                show: this.state.checkProcurator,
                typeName: "PROCURADOR",
                type: 4,
                index: null,
                name: this.state.procuratorName,
            },
            {
                show: this.state.checkGuarantor,
                typeName: "RESPONSÁVEL FINANCEIRO",
                type: 5,
                index: null,
                name: this.state.guarantorName,
            },
            ...this.state.witnesses.map((item, index) => ({
                show: true,
                typeName: `TESTEMUNHA ${index + 1}`,
                type: 2,
                index: index,
                name: item.simpleName,
            })),
            {
                show: true,
                typeName: "EMPRESA",
                type: 3,
                index: null,
                name: this.state.creatorName,
            },
        ];
        
        let tableSignatures = (
            <table className="table table-hover table-striped align-middle">
                <thead className="table-dark">
                    <tr>
                        <th>Tipo</th>
                        <th>Nome</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {signatures.map((signature, index) => {
                        if (!signature.show) return null;
    
                        let validatedStatus =
                            signature.type === 1
                                ? this.state.validatedContactClientStatus
                                : signature.type === 4
                                ? this.state.validatedContactProcuratorStatus
                                : this.state.validatedContactGuarantorStatus;

                        let validatedIcon;
                        if (signature.type === 1)
                        {
                            validatedIcon = this.state.validatedContactClientIcon;
                        }
                        
                        if (signature.type === 4)
                        {
                            validatedIcon = this.state.validatedContactProcuratorIcon;
                        }
                        
                        if (signature.type === 5)
                        {
                            validatedIcon = this.state.validatedContactGuarantorIcon;
                        }
    
                        let bondsType =
                            signature.type === 1 ? 1 : signature.type === 4 ? 2 : 3;
    
                        return (
                            <tr key={index}>
                                <td>{signature.typeName}</td>
                                <td>{signature.name}</td>
                                <td className="d-flex justify-content-center">
                                    {signature.type === 2 ? (
                                        <Button
                                            classaditional="buttonRemoveWitness"
                                            icon="fa fa-trash"
                                            onClick={() =>
                                                this.selectWitness(signature.index, 1)
                                            }
                                        />
                                    ) : signature.type === 1 ||
                                      signature.type === 4 ||
                                      signature.type === 5 ? (
                                        <Button
                                            classaditional="buttonValidationWitnessWizard"
                                            style={{
                                                color:
                                                    validatedStatus === "1"
                                                        ? "#21af41"
                                                        : "#ff7800",
                                            }}
                                            icon={validatedIcon}
                                            onClick={async () => {
                                                this.setState({
                                                    onClickValidationContactOnWitness: true,
                                                    bondsType,
                                                });
                                                await this.queryPhoneWhatsapp();
    
                                                if (validatedStatus === "1") return;
    
                                                this.setState({
                                                    message_confirmation: "",
                                                    message_show_confirmation: true,
                                                    message_type_confirmation: "",
                                                });
                                            }}
                                        />
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    
        this.setState({ tableSignatures });
    };
    

    arePhonesEqual = (phones) => {
        return new Set(phones).size !== phones.length;
    };

    checkSteps = async (step) => {
        let result = false;
        let missingFields = [];
        await this.message("", "", false);

        let numbersInvalid = [
            '000000000', 
            '0000000000', 
            '111111111',
            '1111111111',
            '222222222',
            '2222222222',
            '333333333',
            '3333333333',
            '444444444',
            '4444444444',
            '555555555',
            '5555555555',
            '666666666',
            '6666666666',
            '777777777',
            '7777777777',
            '888888888',
            '8888888888',
            '999999999',
            '9999999999',
            '0000000000',
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999'
        ];
    
        const fieldLabels = {
            choosenClientMaritalStatus: "Estado Civil",
            clientName: "Nome do Cliente",
            clientCPFCNPJ: "CPF/CNPJ do Cliente",
            clientBirthday: "Data de Nascimento do Cliente",
            clientJob: "Profissão do Cliente",
            clientNationality: "Nacionalidade do Cliente",
            clientEmail: "Email do Cliente",
            clientZipCode: "CEP do Cliente",
            clientAddress: "Endereço do Cliente",
            choosenClientState: "Estado do Endereço",
            choosenClientCity: "Cidade do Endereço",
            choosenClientNeighborhood: "Bairro do Endereço",
            clientAddressNumber: "Número do Endereço",
            clientCellPhone1: "Celular 1 do Cliente",
            clientCellPhone2: "Celular 2 do Cliente",
            clientCellPhone3: "Celular 3 do Cliente",
            choosenGuarantorMaritalStatus: "Estado Civil",
            guarantorName: "Nome do Responsável Financeiro",
            guarantorCPFCNPJ: "CPF/CNPJ do Responsável Financeiro",
            guarantorBirthday: "Data de Nascimento do Responsável Financeiro",
            guarantorJob: "Profissão do Responsável Financeiro",
            guarantorNationality: "Nacionalidade do Responsável Financeiro",
            guarantorZipCode: "CEP do Responsável Financeiro",
            guarantorAddress: "Endereço",
            choosenGuarantorState: "Estado do Endereço",
            choosenGuarantorCity: "Cidade do Endereço",
            choosenGuarantorNeighborhood: "Bairro do Endereço",
            guarantorAddressNumber: "Número do Endereço",
            guarantorCellPhone1: "Celular 1 do Responsável Financeiro",
            guarantorCellPhone2: "Celular 2 do Responsável Financeiro",
            guarantorCellPhone3: "Celular 3 do Responsável Financeiro",
            choosenProcuratorMaritalStatus: "Estado Civil",
            procuratorName: "Nome do Procurador",
            procuratorCPFCNPJ: "CPF/CNPJ do Procurador",
            procuratorBirthday: "Data de Nascimento do Procurador",
            procuratorJob: "Profissão do Procurador",
            procuratorNationality: "Nacionalidade do Procurador",
            procuratorZipCode: "CEP do Procurador",
            procuratorAddress: "Endereço",
            choosenProcuratorState: "Estado do Endereço",
            choosenProcuratorCity: "Cidade do Endereço",
            choosenProcuratorNeighborhood: "Bairro do Endereço",
            procuratorAddressNumber: "Número do Endereço",
            procuratorCellPhone1: "Celular 1 do Procurador",
            procuratorCellPhone2: "Celular 2 do Procurador",
            procuratorCellPhone3: "Celular 3 do Procurador",

            clientCellPhone1ContactName: "Nome do Contato 1 do Cliente",
            clientCellPhone1ChoosenKinship: "Tipo de Contato 1 Cliente",
            clientCellPhone2ContactName: "Nome do Contato 2 do Cliente",
            clientCellPhone2ChoosenKinship: "Tipo de Contato 2 Cliente",
            clientCellPhone3ContactName: "Nome do Contato 3 do Cliente",
            clientCellPhone3ChoosenKinship: "Tipo de Contato 3 Cliente",

            guarantorCellPhone1ContactName: "Nome do Contato 1 do Responsável Financeiro",
            guarantorCellPhone1ChoosenKinship: "Tipo de Contato 1 Responsável Financeiro",
            guarantorCellPhone2ContactName: "Nome do Contato 2 do Responsável Financeiro",
            guarantorCellPhone2ChoosenKinship: "Tipo de Contato 2 Responsável Financeiro",
            guarantorCellPhone3ContactName: "Nome do Contato 3 do Responsável Financeiro",
            guarantorCellPhone3ChoosenKinship: "Tipo de Contato 3 Responsável Financeiro",

            procuratorCellPhone1ContactName: "Nome do Contato 1 do Procurador",
            procuratorCellPhone1ChoosenKinship: "Tipo de Contato 1 Procurador",
            procuratorCellPhone2ContactName: "Nome do Contato 2 do Procurador",
            procuratorCellPhone2ChoosenKinship: "Tipo de Contato 2 Procurador",
            procuratorCellPhone3ContactName: "Nome do Contato 3 do Procurador",
            procuratorCellPhone3ChoosenKinship: "Tipo de Contato 3 Procurador",

            choosenFinancialInstitution: "Instituição Financeira",
            title: "Titulo do Serviço",
            contractLateDate: "Data do Atraso da Parcela",
            contractNumberInstallments: "Número de Parcelas",
            contractInstallmentValue: "Valor das Parcelas",
            vehicleType: "Tipo de Veículo (Ex: Pas/Automóvel)",
            vehicleBrand: "Marca/Modelo",
            vehicleYear: "Ano/Modelo" ,
            vehicleColor: "Cor",
            vehiclePlate: "Placa",
            vehicleRenavam: "Renavam",
            vehicleChassis: "Chassi",
            choosenDetranState: "Detran",
            creditCardFinal: "Final Cartão de Crédito",
            contractAddress: "Endereço do Carnê",
            contractAddressNumber: "Número do Endereço do Carnê",
            contractZipCode: "CEP do Endereço do Carnê",
            choosenContractState: "Estado do Endereço do Carnê",
            choosenContractCity: "Cidade do Endereço do Carnê",
            contractNumber: "Número do Contrato Financiamento/Empréstimo ",
            signatureDate: "Data de Assinatura Instituição Financeira/Banco",
            contractComarca: "Comarca (Cidade onde foi financiado o veículo)",
        };
    
        const checkFields = (fields) => {
            fields.forEach((field) => {
                const value = this.state[field] || this.serviceRefs[field]?.current?.value;
    
                if (!value)
                {
                    missingFields.push(fieldLabels[field] || field);
                }
            });
        };
    
        switch (step)
        {
            case "client":
                if (formatedOnlyNumber(this.state.clientCPFCNPJ).length === 11 && this.state.choosenClientMaritalStatus === null)
                {
                    this.message("error", "Por favor, selecione um Estado Civil.");
                    return false;
                }

                if (formatedOnlyNumber(this.state.clientCPFCNPJ).length === 11 && this.state.clientNationality === "BRASIL")
                {
                    this.message("error", `A nacionalidade correta seria "Brasileiro".`);
                    return false; 
                }
                
                if (formatedOnlyNumber(this.state.clientCPFCNPJ).length === 11 &&  !hasSecondName(this.state.clientName))
                {
                    this.message("error", "Por favor, insira nome e sobrenome.");
                    return false;
                }
        
                if (formatedOnlyNumber(this.state.clientCPFCNPJ).length === 11 && isUnderage(this.state.clientBirthday))
                {
                    this.message("error", "A data de nascimento inserida confere para uma pessoa menor de 18 anos.");
                    return false;
                }
        
                if (this.state.clientEmail !== "" && isEmail(this.state.clientEmail) === false)
                {
                    this.message("error", "O email adicionando a esse cliente não está com a formatação correta.");
                    return false;
                }

                if (formatedOnlyNumber(this.state.clientCPFCNPJ).length >= 14)
                {
                    checkFields([
                        "clientName",
                        "clientZipCode",
                        "clientAddress",
                        "choosenClientState",
                        "choosenClientCity",
                        "choosenClientNeighborhood",
                        "clientAddressNumber",
                        "clientCellPhone1",
                        "clientCellPhone2",
                        "clientCellPhone3",
                        "clientCellPhone1ContactName",
                        "clientCellPhone2ContactName",
                        "clientCellPhone3ContactName",
                        "clientCellPhone1ChoosenKinship",
                        "clientCellPhone2ChoosenKinship",
                        "clientCellPhone3ChoosenKinship",
                    ]);

                    if (!this.state.checkGuarantor)
                    {
                        this.message("error", "Campo responsável financeiro é obrigatório para cliente pessoa judídica.");
                        this.setState({ disableGuarantor: false });
                        return false;
                    }

                    if (this.arePhonesEqual([this.state.clientCellPhone1, this.state.clientCellPhone2, this.state.clientCellPhone3]))
                    {
                        this.message("error", "Os números de celular do cliente não podem ser iguais.");
                        return false;
                    }
                }
                else
                {
                    checkFields([
                        "clientName",
                        "clientCPFCNPJ",
                        "clientBirthday",
                        "clientJob",
                        "clientNationality",
                        "clientZipCode",
                        "clientAddress",
                        "choosenClientState",
                        "choosenClientCity",
                        "choosenClientNeighborhood",
                        "clientAddressNumber",
                        "clientCellPhone1",
                        "clientCellPhone2",
                        "clientCellPhone3",
                        "clientCellPhone1ContactName",
                        "clientCellPhone2ContactName",
                        "clientCellPhone3ContactName",
                        "clientCellPhone1ChoosenKinship",
                        "clientCellPhone2ChoosenKinship",
                        "clientCellPhone3ChoosenKinship",
                    ]);

                    if (this.arePhonesEqual([this.state.clientCellPhone1, this.state.clientCellPhone2, this.state.clientCellPhone3]))
                    {
                        this.message("error", "Os números de celular do cliente não podem ser iguais.");
                        return false;
                    }
                }
        
                if (formatedOnlyNumber(this.state.clientCellPhone1) < 9 || formatedOnlyNumber(this.state.clientCellPhone2) < 9 || formatedOnlyNumber(this.state.clientCellPhone3) < 9)
                {
                    this.message("error", "Não é permitido um número de celular com menos de 9 digitos.");
                    return false;
                }

                if (numbersInvalid.includes(formatedOnlyNumber(this.state.clientCellPhone1)))
                {
                    this.message("error", "O número de Celular 1 não é válido.");
                    return false;
                }

                break;
        
            case "guarantor":
                if (this.state.checkProcurator === false && this.state.guarantorId === this.state.clientId)
                {
                    return this.message("error", "O Responsável Financeiro selecionado não pode ser igual ao Cliente");
                }

                if (this.state.guarantorNationality === "BRASIL")
                {
                    this.message("error", `A nacionalidade correta seria "Brasileiro".`);
                    return false; 
                }

                if (this.state.choosenGuarantorMaritalStatus === null)
                {
                    this.message("error", "Por favor, selecione um Estado Civil.");
                    return false;
                }

                if (!hasSecondName(this.state.guarantorName))
                {
                    this.message("error", "Por favor, insira nome e sobrenome.");
                    return false;
                }
        
                if (isUnderage(this.state.guarantorBirthday))
                {
                    this.message("error", "A data de nascimento inserida confere para uma pessoa menor de 18 anos.");
                    return false;
                }
        
                if (this.state.guarantorEmail !== "" && isEmail(this.state.guarantorEmail) === false)
                {
                    this.message("error", "O email adicionando a esse responsável financeiro não está com a formatação correta.");
                    return false;
                }
        
                if (formatedOnlyNumber(this.state.guarantorCPFCNPJ).length >= 14)
                {
                    checkFields([
                        "guarantorName",
                        "guarantorZipCode",
                        "guarantorAddress",
                        "choosenGuarantorState",
                        "choosenGuarantorCity",
                        "choosenGuarantorNeighborhood",
                        "guarantorAddressNumber",
                        "guarantorCellPhone1",
                        "guarantorCellPhone2",
                        "guarantorCellPhone3",
                        "guarantorCellPhone1ContactName",
                        "guarantorCellPhone2ContactName",
                        "guarantorCellPhone3ContactName",
                        "guarantorCellPhone1ChoosenKinship",
                        "guarantorCellPhone2ChoosenKinship",
                        "guarantorCellPhone3ChoosenKinship",
                    ]);

                    if (this.arePhonesEqual([this.state.guarantorCellPhone1, this.state.guarantorCellPhone2, this.state.guarantorCellPhone3]))
                    {
                        this.message("error", "Os números de celular do responsável financeiro não podem ser iguais.");
                        return false;
                    }
                }
                else
                {
                    checkFields([
                        "guarantorName",
                        "guarantorCPFCNPJ",
                        "guarantorBirthday",
                        "guarantorJob",
                        "guarantorNationality",
                        "guarantorZipCode",
                        "guarantorAddress",
                        "choosenGuarantorState",
                        "choosenGuarantorCity",
                        "choosenGuarantorNeighborhood",
                        "guarantorAddressNumber",
                        "guarantorCellPhone1",
                        "guarantorCellPhone2",
                        "guarantorCellPhone3",
                        "guarantorCellPhone1ContactName",
                        "guarantorCellPhone2ContactName",
                        "guarantorCellPhone3ContactName",
                        "guarantorCellPhone1ChoosenKinship",
                        "guarantorCellPhone2ChoosenKinship",
                        "guarantorCellPhone3ChoosenKinship",
                    ]);

                    if (this.arePhonesEqual([this.state.guarantorCellPhone1, this.state.guarantorCellPhone2, this.state.guarantorCellPhone3]))
                    {
                        this.message("error", "Os números de celular do responsável financeiro não podem ser iguais.");
                        return false;
                    }
                }

                if (formatedOnlyNumber(this.state.guarantorCellPhone1) < 9 || formatedOnlyNumber(this.state.guarantorCellPhone2) < 9 || formatedOnlyNumber(this.state.guarantorCellPhone3) < 9)
                {
                    this.message("error", "Não é permitido um número de celular com menos de 9 digitos.");
                    return false;
                }

                if (numbersInvalid.includes(formatedOnlyNumber(this.state.guarantorCellPhone1)))
                {
                    this.message("error", "O número de Celular 1 não é válido.");
                    return false;
                }
                break;
        
            case "procurator":
                if (this.state.choosenProcuratorMaritalStatus === null)
                {
                    this.message("error", "Por favor, selecione um Estado Civil.");
                    return false;
                }

                if (this.state.guarantorNationality === "BRASIL")
                {
                    this.message("error", `A nacionalidade correta seria "Brasileiro".`);
                    return false; 
                }

                if (!hasSecondName(this.state.procuratorName))
                {
                    this.message("error", "Por favor, insira nome e sobrenome.");
                    return false;
                }
        
                if (isUnderage(this.state.procuratorBirthday))
                {
                    this.message("error", "A data de nascimento inserida confere para uma pessoa menor de 18 anos.");
                    return false;
                }
        
                if (this.state.procuratorEmail !== "" && isEmail(this.state.procuratorEmail) === false)
                {
                    this.message("error", "O email adicionando a esse procurador não está com a formatação correta.");
                    return false;
                }
        
                if (formatedOnlyNumber(this.state.procuratorCPFCNPJ).length >= 14)
                {
                    checkFields([
                        "procuratorName",
                        "procuratorZipCode",
                        "procuratorAddress",
                        "choosenProcuratorState",
                        "choosenProcuratorCity",
                        "choosenProcuratorNeighborhood",
                        "procuratorAddressNumber",
                        "procuratorCellPhone1",
                        "procuratorCellPhone2",
                        "procuratorCellPhone3",
                        "procuratorCellPhone1ContactName",
                        "procuratorCellPhone2ContactName",
                        "procuratorCellPhone3ContactName",
                        "procuratorCellPhone1ChoosenKinship",
                        "procuratorCellPhone2ChoosenKinship",
                        "procuratorCellPhone3ChoosenKinship",
                    ]);

                    if (this.arePhonesEqual([this.state.procuratorCellPhone1, this.state.procuratorCellPhone2, this.state.procuratorCellPhone3]))
                    {
                        this.message("error", "Os números de celular do procurador não podem ser iguais.");
                        return false;
                    }
                }
                else
                {
                    checkFields([
                        "procuratorName",
                        "procuratorCPFCNPJ",
                        "procuratorBirthday",
                        "procuratorJob",
                        "procuratorNationality",
                        "procuratorZipCode",
                        "procuratorAddress",
                        "choosenProcuratorState",
                        "choosenProcuratorCity",
                        "choosenProcuratorNeighborhood",
                        "procuratorAddressNumber",
                        "procuratorCellPhone1",
                        "procuratorCellPhone2",
                        "procuratorCellPhone3",
                        "procuratorCellPhone1ContactName",
                        "procuratorCellPhone2ContactName",
                        "procuratorCellPhone3ContactName",
                        "procuratorCellPhone1ChoosenKinship",
                        "procuratorCellPhone2ChoosenKinship",
                        "procuratorCellPhone3ChoosenKinship",
                    ]);

                    if (this.arePhonesEqual([this.state.procuratorCellPhone1, this.state.procuratorCellPhone2, this.state.procuratorCellPhone3]))
                    {
                        this.message("error", "Os números de celular do procurador não podem ser iguais.");
                        return false;
                    }
                }

                if (formatedOnlyNumber(this.state.procuratorCellPhone1) < 9 || formatedOnlyNumber(this.state.procuratorCellPhone2) < 9 || formatedOnlyNumber(this.state.procuratorCellPhone3) < 9)
                {
                    this.message("error", "Não é permitido um número de celular com menos de 9 digitos.");
                    return false;
                }

                if (numbersInvalid.includes(formatedOnlyNumber(this.state.procuratorCellPhone1)))
                {
                    this.message("error", "O número de Celular 1 não é válido.");
                    return false;
                }
                break;

            case "service":
                checkFields([
                    "choosenFinancialInstitution",
                    "title",
                ]);
                break;

            case "finance":
                let amountTotal = 0;

                if (!this.state.clientAddress || this.state.clientAddress === null || this.state.clientAddress === "") { this.message("error", "Não é possível salvar parcelas enquanto os dados do endereço do cliente estiverem incompletos."); return false;}
                if (!this.state.choosenClientCity || this.state.choosenClientCity === null || this.state.choosenClientCity === "") { this.message("error", "Não é possível salvar parcelas enquanto os dados do endereço do cliente estiverem incompletos."); return false;}
                if (!this.state.choosenClientState || this.state.choosenClientState === null || this.state.choosenClientState === "") { this.message("error", "Não é possível salvar parcelas enquanto os dados do endereço do cliente estiverem incompletos."); return false;}
                if (!this.state.clientAddressNumber || this.state.clientAddressNumber === null || this.state.clientAddressNumber === "") { this.message("error", "Não é possível salvar parcelas enquanto os dados do endereço do cliente estiverem incompletos."); return false;}
                if (!this.state.choosenClientNeighborhood || this.state.choosenClientNeighborhood === null || this.state.choosenClientNeighborhood === "") { this.message("error", "Não é possível salvar parcelas enquanto os dados do endereço do cliente estiverem incompletos."); return false;}
            
                for (const debt of this.state.debts)
                {
                    if (debt.FormaPgto === 0)
                    {
                        await this.message("error", "É necessário que todas as formas de pagamento estejam selecionadas");
                        return false;
                    }

                    if (debt.FormaPgto !== 1)
                    {
                        const today = new Date();
                        const todayFormatted = today.toISOString().split("T")[0];
                    
                        const [year, month, day] = debt.vencimento.split("-");
                        const dueDate = new Date(year, month - 1, day);
                        const dueDateFormatted = dueDate.toISOString().split("T")[0];
                    
                        if (dueDateFormatted !== todayFormatted && debt.status !== 1) {
                            await this.message("error", "A opção de pagamento CARTÃO DE CRÉDITO deve ter como data de vencimento o dia de hoje!");
                            return false;
                        }
                    }
            
                    if (isNaN(debt.valor) || debt.valor === "" || debt.valor === 0)
                    {
                        await this.message("error", "É necessário que todos os valores das parcelas estejam preenchidos.");
                        return false;
                    }
            
                    if (debt.valor < 0)
                    {
                        await this.message("error", "É necessário que todos os valores das parcelas estejam positivos.");
                        return false;
                    }
            
                    amountTotal += Math.round(debt.valor * 100) / 100;
                }

                amountTotal = Math.round(amountTotal * 100) / 100;
            
                if (
                    (!this.state.hasInstallments && formatedMoneyBRAToFloat(this.state.serviceValue) !== amountTotal)
                    || amountTotal < formatedMoneyBRAToFloat(this.state.serviceValue) 
                    || formatedMoneyBRAToFloat(this.state.serviceValue) > amountTotal
                )
                {
                    await this.message("error", "A soma total das parcelas deve ser igual ao valor de serviço.");
                    return false;
                }
                break;
            
            case "contract":
                let fields = [];
                if (this.state.showDataClearName === 0)
                {
                    if (this.state.showDataCreditCard === 1)
                    {
                        fields.push(
                            "creditCardFinal",
                        )
                    }
                    if (this.state.showDataVehicles === 1)
                    {
                        fields.push(
                            "vehicleType",
                            "vehicleYear",
                            "vehicleColor",
                            "vehiclePlate",
                            "vehicleBrand",
                            "vehicleRenavam",
                            "vehicleChassis",
                            "choosenDetranState",
                        )
                    }

                    if (this.state.showDataInstitution === 1)
                    {
                        fields.push(
                            "contractNumber",
                        )

                        if (this.state.showFieldDateInstitution === 1)
                        {
                            fields.push(
                                "signatureDate",
                            )
                        }

                        if (this.state.showFieldComarca === 1)
                        {
                            fields.push(
                                "contractComarca",
                            )
                        }
                    }

                    if (this.state.showDataOverdraft === 1)
                    {
                        fields.push(
                            "bankAgency",
                            "bankCheckingAccount"
                        )
                    }

                    fields.push(
                        "contractLateDate",
                        "contractAddress",
                        "contractAddressNumber",
                        "contractZipCode",
                        "choosenContractState",
                        "choosenContractCity",
                    )
                }
                checkFields(fields);
                break;
    
            default:
                break;
        }
    
        if (missingFields.length > 0)
        {
            this.message(
                "error",
                `Preencha os seguintes campos:\n${missingFields.join(",\n")}`
            );
        }
        else
        {
            result = true;
        }
    
        return result;
    };

    detailsEnvelope = async (category) => 
    {
        // await this.queryEnvelope();
        if (category === 1 && this.state.buttonDetailsEnvelope?.show === "false") { return };
        if (category === 2 && this.state.buttonDetailsEnvelopeSecondCategory?.show === "false") { return };

        await this.setState({ categoryDetailsEnvelope:  category === undefined ? this.state.categoryDetailsEnvelope : category });
        
        if (this.state.categoryDetailsEnvelope === 1)
        {
            this.setState({ showDetailsEnvelopeFirstCategory: this.state.showDetailsEnvelopeFirstCategory ? false : true });
            this.setState({ showSignaturesEnvelope: false,  showDetailsEnvelopeSecondCategory: false, showSignaturesEnvelopeSecondCategory: false });
        }
        else
        {
            this.setState({ showDetailsEnvelopeSecondCategory: this.state.showDetailsEnvelopeSecondCategory ? false : true });
            this.setState({ showDetailsEnvelopeFirstCategory: false,  showSignaturesEnvelopeSecondCategory: false, showSignaturesEnvelope: false });
        }

        const data = {
            token: this.state.categoryDetailsEnvelope === 1 ? this.state.buttonDetailsEnvelope.token : this.state.buttonDetailsEnvelopeSecondCategory.token
        }

        const result = await serviceController.detailsEnvelope(data);
        if (!result.status) return;

        const details = result.data?.data?.dados;
        if (!details)
        {
            console.error("Details is undefined or null", result);
            return;
        }
        
        const { envelope, anexos, servicosExtra } = details || {};

        this.setState({ optionsServiceExtra: [] });

        if (servicosExtra?.show === "true")
        {
            let optionsServiceExtra = this.state.optionsServiceExtra;
            await servicosExtra.servicos.forEach(servico => {
                optionsServiceExtra.push({
                    value: servico.id,
                    name: servico.nome
                })
            });
            this.setState({ optionsServiceExtra });
        }

        if (this.state.categoryDetailsEnvelope === 1)
        {
            this.setState({ 
                detailsEnvelopeData: envelope.data,
                detailsEnvelopeSender: envelope.enviadoPor,
                detailsEnvelopeSign: envelope.statusEnvio.label,
                envelopeTimer: envelope.botaoTempo,
                attachmentDetails: anexos
            });
        }
        else
        {
            this.setState({ 
                detailsEnvelopeDataSecondCategory: envelope.data,
                detailsEnvelopeSenderSecondCategory: envelope.enviadoPor,
                detailsEnvelopeSignSecondCategory: envelope.statusEnvio.label,
                envelopeTimerSecondCategory: envelope.botaoTempo,
                attachmentDetailsSecondCategory: anexos
            });
        }
        
        // await this.queryEnvelope();
    }

    createEnvelope =  async (category) => 
    {
        await this.setState({ categoryCreateEnvelope:  category === undefined ? this.state.categoryCreateEnvelope : category });

        await this.switchBodyModalConfirmationOfEnvelope("create");

        const data = {
            type: 1,
            envelopeServiceId: 0,
            annexOnly: 1,
            category: this.state.categoryCreateEnvelope,
            userId: this.props.user.PER_ID_USER_ERP,
            serviceId: this.state.id
        }
        
        const result = await serviceController.createEnvelope(data);
        if (!result.status) { return; }
        
        this.setState({ envelopeToken: result.data.data.token, message_envelope_type_functions: "" });
        // await this.queryEnvelope();

        return;
    }

    deleteEnvelope = async (category) => 
    {
        // await this.queryEnvelope();
        await this.setState({ categoryDeleteEnvelope:  category === undefined ? this.state.categoryDeleteEnvelope : category });

        await this.switchBodyModalConfirmationOfEnvelope("delete");

        if ( this.state.message_envelope_show_confirmation === true )
        {
            const data = {
                token: this.state.categoryDeleteEnvelope === 1 ? this.state.buttonDeleteEnvelope.token : this.state.buttonDeleteEnvelopeSecondCategory.token
            }
            
            const result = await serviceController.deleteEnvelope(data);
            if (!result.status) { return; }
            
            this.setState({ envelopeTimer: null, envelopeTimerSecondCategory: null});
            this.setState({ message_envelope_show_confirmation: false, message_envelope_type_functions: "", hasEnvelopeContract: false });
            // await this.queryEnvelope();

            return;
        }

        await this.setState({ message_envelope_show_confirmation: true, message_envelope_type_functions: "delete" });

        this.setState({
            showDetailsEnvelopeFirstCategory: false,
            showSignaturesEnvelope: false,
            showDetailsEnvelopeSecondCategory: false,
            showSignaturesEnvelopeSecondCategory: false 
        })

        // await this.queryEnvelope();
    }

    sendSignEnvelope = async (category) =>
    {
        this.setState({ categorySelected: category })
        if (this.state.continueWithHavePendencies === false)
        {
            await this.checkAllbusinessAndServicesOfClient();
        }

        if (this.state.thisClientHavePendencies) { return; }

        // await this.queryEnvelope();
        await this.setState({ categorySendEnvelope:  category === undefined ? this.state.categorySendEnvelope : category });

        const data = {
            token: this.state.categorySendEnvelope === 1 
            ? this.state.buttonSendSign.token 
            : this.state.buttonSendSignSecondCategory.token
        }

        const result = await serviceController.sendSignEnvelope(data);
        if (!result.status) { return; }

        // await this.queryEnvelope();
    }

    sendAttachmentToEnvelope = async (category) => 
    {
        if (this.state.choosenServiceExtra !== null)
        {
            const servicesIds = this.state.choosenServiceExtra.map(service => service.value).join(',');
            const data = {
                userId: this.props.user.PER_ID_USER_ERP,
                type: 2,
                serviceId: servicesIds,
                envelopeServiceId: this.state.id,
                token: category === 1 ? this.state.buttonSendSign.token : this.state.buttonSendSignSecondCategory.token,
                annexOnly: 2,
                category: category
            }
    
            const result = await serviceController.addServicesEnvelope(data);
            if (!result.status) { return; }
            
            // await this.queryEnvelope();
            await this.detailsEnvelope(1);
            return this.message("success", "Serviços anexados com sucesso ao envelope")
        }
    }

    statusSignatoriesEnvelope = async (category) => 
    {
        await this.setState({ categoryStatusSignatoriesEnvelope: category === undefined ? this.state.categoryStatusSignatoriesEnvelope : category });

        if (this.state.categoryStatusSignatoriesEnvelope === 1)
        {
            this.setState({ showDetailsEnvelopeFirstCategory: false });
        }
        else
        {
            this.setState({ showDetailsEnvelopeSecondCategory: false });
        }
        
        const data = {
            token: this.state.categoryStatusSignatoriesEnvelope === 1 ? this.state.buttonDetailsEnvelope.token : this.state.buttonDetailsEnvelopeSecondCategory.token,
            userId: this.props.user.PER_ID_USER_ERP,
        }

        const result = await serviceController.statusSignatures(data);
        if (!result.status) { return; }
        
        const { assinantes } = result.data.data !== null ? result.data.data : null;
        const signers = assinantes;

        if (signers === null) { return; }
        let counter = 0;
        await signers.forEach(signature => 
        {
            if (signature.qualification !== "EMPRESA")
            {
                if (signature.statusAss === "signed")
                {
                    counter++;
                }
            }

            switch (signature.statusAss)
            {
                case "new":
                    signature.statusAss = "NÃO ASSINADO";
                    signature.colorStatusAss = "#dc3545"; 
                    break;

                case "link-opened":
                    signature.statusAss = "LINK ABERTO";
                    signature.colorStatusAss = "#ef9602";
                    break;

                case "signed":
                    signature.statusAss = "ASSINADO";
                    signature.colorStatusAss = "#28a745";
                    break;

                default:
                    break;
            }
        });

        if (counter === 3)
        {
            this.setState({ releaseSignToCreator: true });
        }

        if (this.state.categoryStatusSignatoriesEnvelope === 1)
        {
            
            await this.setState({ showSignaturesEnvelope: this.state.showSignaturesEnvelope ? false : true });
            await this.setState({ signaturesEnvelopeSecondCategory: false, showSignaturesEnvelopeSecondCategory: false, showDetailsEnvelopeSecondCategory: false, showDetailsEnvelopeFirstCategory: false })
            await this.setState({ signaturesEnvelope: signers})
        }
        else
        {
            await this.setState({ showSignaturesEnvelopeSecondCategory: this.state.showSignaturesEnvelopeSecondCategory ? false : true });
            await this.setState({ signaturesEnvelope: false, showSignaturesEnvelope: false, showDetailsEnvelopeSecondCategory: false, showDetailsEnvelopeFirstCategory: false })
            await this.setState({ signaturesEnvelopeSecondCategory: signers})
        }

        // this.queryEnvelope();
    }

    queryEnvelopesService = async () =>
    {
        if (this.state.id === null) { return;}
        const url = `${process.env.REACT_APP_ERP_API_URL}/ajax/consultarEnvelopesServicoWebhook.php`;

        const config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg="
            },
            data: JSON.stringify({ 
                serviceId: this.state.id,
                userId: this.props.user.PER_ID_USER_ERP,
            })
        };

        try 
        {
            const response = await axios.request(config);
            let envelopesSigned = 0;
            let envelopesCount = 0;
            response.data.dados?.categorias.forEach(envelope => {
                if(envelope.assinatura === "signed")
                {
                    envelopesSigned++
                }
                
                if(envelope.assinatura === "signed" || envelope.assinatura === "pending")
                {
                    envelopesCount++
                }
            });

            this.setState({ hasEnvelopes: envelopesCount >= 1 ? true : false });
            this.setState({ saveService: envelopesSigned === 2 ? true : false })
        } 
        catch (error)
        {
            console.error(
                "Erro ao consultar envelopes:",
                error.response?.data || error.message
            );
            throw error;
        }
    };

    insertDriveWebhook = (googleDriveId, googleDriveTitle) =>
    {
        let data = JSON.stringify({
            userId: this.props.user.PER_ID_USER_ERP,
            serviceId: this.state.id,
            frmGoogleDriveTitulo: googleDriveTitle,
            frmGoogleDriveId: googleDriveId,
            type: 1
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ERP_API_URL}/ajax/inserirDriveWebhook.php`,
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer Sqk5DpabwdzTpGTDWs5qxljRniFPPdG+k5MIR/r8hWg=",
            },
            data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleFileChange = (event) =>
    {
        this.setState({ fileAudit: event.target.files[0] });
    };

    uploadAudit = async () => {
        if (!this.state.fileAudit) {
            alert('Selecione um arquivo antes de enviar.');
            return;
        }

        try {
            const fileName = this.state.fileAudit.name.replace(/\.[^/.]+$/, "");
            const formData = new FormData();
            formData.append('idService', this.state.id);
            formData.append('name', fileName);
            formData.append('file', this.state.fileAudit);

            const response = await axios.post(
                'https://google-workspace.spnet.cc/upload/google_drive',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.id === "" || response.data.id === null)
            {
                return this.message("error", "Erro ao fazer upload. Tente novamente.");
            }

            let googleDriveId = response.data.id;
            let googleDriveTitle = response.data.name;

            await this.insertDriveWebhook(googleDriveId, googleDriveTitle);
        } catch (error) {
            return this.message("error", "Erro ao fazer upload. Tente novamente.");
        }
    };

    queryEnvelope = async () => 
    {
        const data = {
            userId: this.props.user.PER_ID_USER_ERP,
            serviceId: this.state.id
        }

        const result = await serviceController.queryEnvelope(data);
        if (!result.status) { return; };
        
        await this.updateFieldsEnvelope(result.data.data);
    }

    switchBodyModalConfirmationOfEnvelope = async (type) => 
    {
        let body;

        switch (type)
        {
            case "delete":
                body =
                    <div className="d-flex flex-column mb-3">
                        <span><strong>Serviço:</strong> {this.state.id }</span>
                        <span><strong>Cliente:</strong> {this.state.clientName }</span>
                    </div>
                ;

                this.setState({ message_envelope_confirmation: `Tem certeza que quer excluir o envelope abaixo?` });

                break;

            case "sign":
                this.setState({ message_envelope_confirmation: "Deseja enviar envelope para assinatura?" });

                break;

            case "create":
                this.setState({ message_envelope_confirmation: "Deseja criar envelope para assinatura?" });

                break;

            default:
                break;
        }

        this.setState({ bodyModalConfirmationOfEnvelope: body });
    }

    updateFieldsEnvelope = async (guide) => 
    {
        this.setState({ saveService: guide.liberarServico === "true" ? true : false });
        guide = guide.dados;
        let whatsappValidationCount = 0;
        let whatsappValidation = {
            validatedContactClient: this.state.validatedContactClient === true
        };

        if (this.state.checkGuarantor)
        {
            whatsappValidation.validatedContactGuarantor = this.state.validatedContactGuarantor === true;
        }

        if (this.state.checkProcurator)
        {
            whatsappValidation.validatedContactProcurator = this.state.validatedContactProcurator === true;
        }

        Object.values(whatsappValidation).forEach((prop) => {
            if (prop === false)
            {
                whatsappValidationCount = 1;
            }
        })

        let checkValidation;

        if (guide.alerta?.show === "false")
        {
            checkValidation = {
                witnesses: this.state.witnesses.length === 2,
                isPhoneValidated: whatsappValidationCount !== 1,
                user: this.props.user.PER_ID_USER_ERP !== null,
                hasInstallments: this.state.hasInstallments !== false,
            };
        }
        else
        {
            checkValidation = {
                witnesses: true,
                isPhoneValidated: true,
                user: true,
                hasInstallments: true,
            };
        }
        
        let checkValidationMessage = {
            witnesses: "Adicionar testemunhas",
            isPhoneValidated: "Existem números de WhatsApp sem VERIFICAÇÃO!",
            hasInstallments: "Adicione e salve as parcelas",
            user: "Vincular usuario do ERP ao usuario atual"
        };
        
        let validationMessages = [];

        Object.entries(checkValidation).forEach(([key, isValid]) =>
        {
            if (!isValid)
            {
                validationMessages.push(checkValidationMessage[key]);
            }
        });
        
        let formattedMessage = validationMessages.join('; ');
        let checkValidationCount = 0;

        Object.values(checkValidation).forEach((prop) => {
            if (prop === false)
            {
                checkValidationCount = 1;
            }
        })
        
        this.setState({ enableFieldsEnvelope: checkValidationCount === 1 ? false : true});

        this.setState({ 
            enableFieldsEnvelopeMessage: formattedMessage 
        });
        
        if (guide === undefined || guide === null) return;
        guide.forEach(category => 
        {
            const { botoes, categoria, labels, corEnvelope, alerta } = category;

            this.setState({ showSecondCategory: categoria === "2" ? true : false})

            if (categoria === "1") 
            {
                this.setState({showFirstCategory: true});

                if (botoes.botaoTempo?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoTempo.token, envelopeTimer: botoes.botaoTempo, controlTimer: true});
                }
                
                if (botoes.botaoStatus?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoStatus.token });
                }
    
                if (botoes.botaoEnviar?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoEnviar.token });
                }
    
                if (botoes.botaoExcluir?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoExcluir.token });
                }
    
                if (botoes.botaoDetalhes?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoDetalhes.token });
                }
    
                if (botoes.botaoCadastrar?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoCadastrar.token, buttonCreateEnvelope: botoes.botaoCadastrar });
                }

                if (
                    botoes.botaoTempo?.show === "false" &&
                    botoes.botaoStatus?.show === "false" &&
                    botoes.botaoEnviar?.show === "false" &&
                    botoes.botaoExcluir?.show === "false" &&
                    botoes.botaoDetalhes?.show === "false" &&
                    botoes.botaoCadastrar?.show === "false"
                )
                {
                    this.setState({showFirstCategory: false});
                }

                if (corEnvelope)
                {
                    this.setState({ colorEnvelopeFirstCategory: corEnvelope });
                }

                if (labels)
                {
                    if (labels.dataCriacao !== "" && labels.nomeDocs !== "" && labels.servicosAdicionados !== "")
                    {
                        let elementsServicesIds = [];

                        if (labels.servicosAdicionados !== "" && labels.servicosAdicionados !== undefined)
                        {
                            labels.servicosAdicionados.forEach(service => {
                                elementsServicesIds.push(<span style={{color: service.color, marginLeft: 5}}>{service.servico}</span>)
                            });
                        }

                        this.setState({
                            labelsDateEnvelope: labels.dataCriacao,
                            labelsEnvEnvelope: labels.labelEnv,
                            labelsNameDocsEnvelope: labels.nomeDocs,
                            labelsServicesEnvelope: labels.servicosAdicionados
                        })

                        let labelsFirstCategory = 
                            <span className="d-flex flex-column">
                                <span className="labelsEnvelopeWizard">
                                    <b>DOCUMENTOS:</b> {this.state.labelsNameDocsEnvelope} | <b>SERVIÇOS: </b>{elementsServicesIds}
                                </span>
                                <p className="labelsDateEnvelopeWizard">{this.state.labelsDateEnvelope}</p>
                            </span>
                        ;

                        this.setState({ labelsFirstCategory })
                    }
                    else
                    {
                        this.setState({ labelsFirstCategory: "" })
                    }
                }

                if (alerta)
                {
                    if (alerta?.show === "true")
                    {
                        this.setState({ envelopeAlertFirstCategory: alerta?.label });
                    }
                }

                this.setState({ 
                    envelopeTimer: botoes.botaoTempo,
                    buttonSendSign: botoes.botaoEnviar, 
                    buttonTimerEnvelope: botoes.botaoTempo, 
                    buttonDeleteEnvelope: botoes.botaoExcluir, 
                    buttonCreateEnvelope: botoes.botaoCadastrar, 
                    buttonStatusSignatories: botoes.botaoStatus, 
                    buttonDetailsEnvelope: botoes.botaoDetalhes,
                    hasEnvelopeContract: botoes.botaoCadastrar.show === "true" ? false : true
                })
            }
            else if (categoria === "2")
            {
                this.setState({showSecondCategory: true});

                if (botoes.botaoTempo?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoTempo.token, envelopeTimerSecondCategory: botoes.botaoTempo, controlTimer: true});
                }
                
                if (botoes.botaoStatus?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoStatus.token });
                }
    
                if (botoes.botaoEnviar?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoEnviar.token });
                }
    
                if (botoes.botaoExcluir?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoExcluir.token });
                }
    
                if (botoes.botaoDetalhes?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoDetalhes.token });
                }
    
                if (botoes.botaoCadastrar?.show === "true")
                {
                    this.setState({ envelopeToken: botoes.botaoCadastrar.token });
                }

                if (
                    botoes.botaoTempo?.show === "false" &&
                    botoes.botaoStatus?.show === "false" &&
                    botoes.botaoEnviar?.show === "false" &&
                    botoes.botaoExcluir?.show === "false" &&
                    botoes.botaoDetalhes?.show === "false" &&
                    botoes.botaoCadastrar?.show === "false"
                )
                {
                    this.setState({showSecondCategory: false});
                }

                if (corEnvelope)
                {
                    this.setState({ colorEnvelopeSecondCategory: corEnvelope });
                }

                if (labels)
                {
                    if (labels.dataCriacao !== "" && labels.nomeDocs !== "" && labels.servicosAdicionados !== "")
                    {
                        let elementsServicesIds = [];
    
                        labels.servicosAdicionados.forEach(service => {
                            elementsServicesIds.push(<span style={{color: service.color, marginLeft: 5}}>{service.servico}</span>)
                        });
    
                        this.setState({
                            labelsDateEnvelope: labels.dataCriacao,
                            labelsEnvEnvelope: labels.labelEnv,
                            labelsNameDocsEnvelope: labels.nomeDocs,
                            labelsServicesEnvelope: labels.servicosAdicionados
                        })
    
                        let labelsSecondCategory = 
                            <span className="d-flex flex-column">
                                <span className="labelsEnvelopeWizard">
                                    <b>DOCUMENTOS: </b> {this.state.labelsNameDocsEnvelope} | <b>SERVIÇOS: </b> {elementsServicesIds}
                                </span>
                                <p className="labelsDateEnvelopeWizard">{this.state.labelsDateEnvelope}</p>
                            </span>
                        ;
    
                        this.setState({ labelsSecondCategory })
                    }
                    else
                    {
                        this.setState({ labelsSecondCategory: "" })
                    }
                }

                if (alerta)
                {
                    if (alerta?.show === "true")
                    {
                        if (this.state.envelopeAlertFirstCategory !== "")
                        {
                            if (this.state.envelopeAlertFirstCategory !== alerta?.label )
                            {
                                this.setState({ envelopeAlertSecondCategory: alerta?.label });
                            }
                        }
                        else
                        {
                            this.setState({ envelopeAlertSecondCategory: alerta?.label });
                        }
                    }
                }

                this.setState({ 
                    envelopeTimerSecondCategory: botoes.botaoTempo, 
                    buttonSendSignSecondCategory: botoes.botaoEnviar, 
                    buttonDeleteEnvelopeSecondCategory: botoes.botaoExcluir, 
                    buttonCreateEnvelopeSecondCategory: botoes.botaoCadastrar, 
                    buttonStatusSignatoriesSecondCategory: botoes.botaoStatus, 
                    buttonDetailsEnvelopeSecondCategory: botoes.botaoDetalhes,
                    hasEnvelopeContractSecondCategory: botoes.botaoCadastrar.show === "true" ? false : true
                })
            }
        });
    };

    render()
    {
        return (
            <div className="serviceForm">
                <LargeModal
                    signaturesOpen={this.state.signaturesOpen}
                    onClose={async () => {
                        await this.props.closeServiceModal(this.state.id);
                        this.setState({ large_modal_show_confirmation: false }); 
                    }}
                    info={"Caso tenha mais serviços deste mesmo cliente feche aqui e cadastre os outros."}
                    show={this.state.large_modal_show_confirmation}
                    titleService={{
                        id: this.state.id,
                        date: new Date(this.state.dateCreated ? this.state.dateCreated + 'T12:00:00' : new Date()).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo'}), 
                        typeService: typeof this.props.businessService?.name === "string" ? this.props.businessService.name.toUpperCase() : ""
                    }}
                >
                    {
                        <Wizard ref={this.wizardRef} allVisited={this.state.onlyFirstTimeIfServiceExists} id={this.state.id}>
                            <div title="Dados do Cliente" mandatory={true} onCurrent={() => { this.ifClientExists(); this.setState({bondsType: 1}); this.queryPhoneWhatsapp(); this.queryEnvelopesService(); }}>
                                {this.tabDetailsClient()}
                                <div className="navigationWizard">
                                    <Button
                                        onClick={this.previus}
                                        name="voltar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                    <Button
                                        onClick={async() => 
                                        {
                                            if ( await this.checkSteps("client"))
                                            { 
                                                this.onSubmitFormClient();
                                                this.wizardRef.current.actions.goNextPage();
                                            }
                                        }}
                                        name="salvar e continuar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                </div>
                            </div>
                            <div title="Dados de Serviço" mandatory={true}>
                                {this.tabDetailsService()}
                                <div className="navigationWizard">
                                    <Button
                                        onClick={this.previus}
                                        name="voltar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                    <Button
                                        onClick={async () => 
                                        {
                                            if (await this.checkSteps("service"))
                                            { 
                                                this.wizardRef.current.actions.goNextPage();
                                                this.onSubmitFormService();
                                                await this.queryProcuratorDocument();
                                            }
                                        }}
                                        name="salvar e continuar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                </div>
                            </div>
                            {
                                this.state.checkProcurator &&
                                    <div title="Procurador" mandatory={true} onCurrent={async () => { 
                                        await this.queryProcuratorDocument();

                                        if (this.state.hasProcuratorDocument === true)
                                        {
                                            this.ifProcuratorExists();
                                            this.setState({bondsType: 2});
                                            this.queryPhoneWhatsapp();
                                        }
                                    }}
                                    >
                                        {this.tabDetailsProcurator() }
                                        <div className="navigationWizard">
                                            <Button
                                                onClick={this.previus}
                                                name="voltar"
                                                classaditional={`me-2 buttonWizard`}
                                            />
                                            <Button
                                                onClick={async() => 
                                                {
                                                    if (this.state.hasProcuratorDocument === true)
                                                    {
                                                        if ( await this.checkSteps("procurator"))
                                                        { 
                                                            await this.onSubmitFormProcurator();
                                                            await this.onSubmitFormService();
                                                            this.wizardRef.current.actions.goNextPage();
                                                        }
                                                    }
                                                }}
                                                disabled={this.state.checkTab}
                                                name="salvar e continuar"
                                                classaditional={`me-2 buttonWizard`}
                                            />
                                        </div>
                                    </div>
                            }
                            {
                                this.state.checkGuarantor &&
                                    <div title="Responsável Financeiro" mandatory={true} onCurrent={() => { this.ifGuarantorExists(); this.setState({bondsType: 3}); this.queryPhoneWhatsapp(); }}>
                                        {this.tabDetailsGuarantor() }
                                        <div className="navigationWizard">
                                            <Button
                                                onClick={this.previus}
                                                name="voltar"
                                                classaditional={`me-2 buttonWizard`}
                                            />
                                            <Button
                                                onClick={async() => 
                                                {
                                                    if (await this.checkSteps("guarantor"))
                                                    { 
                                                        this.onSubmitFormGuarantor();
                                                        this.onSubmitFormService();
                                                        this.wizardRef.current.actions.goNextPage();
                                                    }
                                                }}
                                                disabled={this.state.checkTab}
                                                name="salvar e continuar"
                                                classaditional={`me-2 buttonWizard`}
                                            />
                                        </div>
                                    </div>
                            }
                            <div title="Dados de Contrato"  mandatory={true} onCurrent={() => {this.switchServices(); }}>
                                {this.tabDetailsContract()}
                                <div className="navigationWizard">
                                    <Button
                                        onClick={this.previus}
                                        name="voltar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                    <Button
                                        onClick={async () => 
                                        {
                                            if (await this.checkSteps("contract"))
                                            { 
                                                this.wizardRef.current.actions.goNextPage();
                                                this.onSubmitFormService();
                                            }
                                        }}
                                        name="salvar e continuar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                </div>
                            </div>
                            <div title="Dados Financeiros" onCurrent={ async () => 
                                {
                                    this.setState({ numInstallments: this.state.installmentSelected }, () =>
                                    {
                                        this.hasInstallments();
                                        this.getInstallmentsOnStorage();

                                        localStorage.setItem("installment", JSON.stringify(this.state.choosenInstallment));
                                    });
                                }}>
                                {
                                    this.tabDetailsFinance()
                                }
                                <div className="navigationWizard">
                                    <Button
                                        onClick={this.previus}
                                        name="voltar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                    <Button
                                        onClick={async () => 
                                        {
                                            if (!this.state.hasInstallments) { return this.message("warning", "Só é permitido avançar após salvar as parcelas") }
                                            if (await this.checkSteps("finance"))
                                            { 
                                                await this.wizardRef.current.actions.goNextPage();
                                            }
                                        }}
                                        name="salvar e continuar"
                                        classaditional={`me-2 buttonWizard`}
                                    />
                                </div>
                            </div>
                            {
                                this.state.onlyFirstTimeIfServiceExists &&
                                    <div 
                                        title="Signatários" 
                                        onCurrent={ async () =>
                                        { 
                                            await this.setState({ signaturesOpen: true, dataEnvelope: {
                                                ...this.state.dataEnvelope,
                                                clientCellPhone1: this.state.clientCellPhone1,
                                                hasInstallments: this.state.hasInstallments,
                                                serviceId: this.state.id,
                                                guarantorId:this.state.guarantorId,
                                                procuratorId:this.state.procuratorId,
                                                guarantorName:this.state.guarantorName,
                                                creatorName: this.state.creatorName,
                                                procuratorName:this.state.procuratorName,
                                                checkGuarantor:this.state.checkGuarantor,
                                                checkProcurator:this.state.checkProcurator,
                                                guarantorCellPhone1:this.state.guarantorCellPhone1,
                                                procuratorCellPhone1:this.state.procuratorCellPhone1,
                                            }});
                                        }}
                                    >
                                        <DetailsEnvelope data={this.state.dataEnvelope} />
                                        <div className="navigationWizard">
                                            <Button
                                                onClick={this.previus}
                                                name="voltar"
                                                classaditional={`me-2 buttonWizard`}
                                            />
                                            <Button
                                                onClick={async () =>
                                                {
                                                    if (await this.checkSteps("contract"))
                                                    {
                                                        await this.onSubmitFormService();
                                                    }
                                                    await this.props.closeServiceModal(this.state.id);
                                                    this.setState({ large_modal_show_confirmation: false });
                                                    await this.message("success", "Serviço foi salvo com sucesso!");
                                                }}
                                                name="salvar"
                                                classaditional={`${this.state.saveService ? "" : "d-none"} me-2 buttonWizard`}
                                            />
                                        </div>
                                    </div>
                            }
                        </Wizard>
                    }
                </LargeModal>
                <div className={(this.props.activeTab === 4 && this.props.continueRegistrationActive) ? "d-flex justify-content-end" : "d-none"}>
                    <div className="col-3 d-flex align-items-end">
                        <Button
                            name={this.state.id === null ? (this.state.isDisabledButton ? "aguarde..." : "Adicionar serviço") : (this.state.isDisabledButton ? "aguarde..." : "Editar serviço")}
                            classaditional="buttonContinueRegistrationServiceForm d-none"
                            onClick={ async () => 
                            {
                                await this.setState({ isDisabledButton: true });
                                await this.getService();
                                await this.getBusinessFromURL();
                                await this.hasInstallments();
                                await this.setState({ isDisabledButton: false });
                                this.setState({ large_modal_show_confirmation: true });
                                setTimeout(() => this.setState({ clientRegisteredOnService: false }), 10000);
                            }}
                            disabled={this.state.isDisabledButton}
                        >
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state) 
{
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const permissions = state.permissions;
    const { user } = state.auth;
    
    return {
        hasPermissionsLoaded,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceForm));
